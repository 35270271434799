import React, { useEffect, useState, useRef } from 'react';
import { InboxOutlined } from '@ant-design/icons';
import { message, Upload, Modal } from 'antd';

const { Dragger } = Upload;

const EditDepartmentModal = (props) => {

    const { visible, setVisible, setData, setIsUpload, uploadFileList, setUploadFileList, dataUploaded } = props

    const [uploadLoading, setUploadLoading] = useState(false)
    const departments = useRef([])

    const onRemove = (file) => {
        setUploadFileList([])
        departments.current = []
    }

    const onChange = (info) => {
        console.log(info)

        setUploadLoading(true)
        const { status, response } = info.file;

        setUploadFileList(info.fileList)

        if (status === 'done' && info.fileList.length > 0) {
            if (response.code === -1) {
                message.error(`用户未登录`);
                window.location = '/login'
                return
            }
            setUploadFileList(info.fileList)
            // console.log(response.data)
            message.success(`${info.file.name} 文件上传成功.`);
            parseRows(response.rows)
        } else if (status === 'error') {
            message.error(`${info.file.name} 文件上传失败.`);
            setUploadFileList([])
        }
        setTimeout(() => {
            setUploadLoading(false)
        }, 500)
    }

    const parseRows = (data) => {
        console.log(data)
        const sheet1 = data[0]
        const depts = sheet1.slice(1).map(d => ({
            key: d[0],
            name: d[1],
            code: d[2],
            comment: d[3]
        }))

        console.log(data.slice(1), depts)
        departments.current = depts
    }

    const okClick = () =>{
        console.log(departments.current)
        setData(departments.current)
        dataUploaded.current = departments.current
        setVisible(false)
        setIsUpload(true)
    }

    const cancelClick = () => {
        departments.current = []
        setVisible(false)
        setUploadFileList([])
        setIsUpload(false)
    }

    return (
        <Modal title='上传部门文件' visible={visible}
            onOk={okClick}
            onCancel={cancelClick}

         >
            <Dragger
                accept='.xlsx'
                name='department'
                multiple={false}
                action={'/api/department/upload/'}
                onDrop={(e) => {
                    console.log('Dropped files', e.dataTransfer.files);
                }}
                onChange={onChange}
                onRemove={onRemove}
                maxCount={1}
                fileList={uploadFileList}
                disabled={uploadLoading}
            >
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                    点击此处或将文件拖拽至此处
                    <br />
                    上传部门信息表
                </p>
                <p className="ant-upload-hint">
                    仅上传.xlsx文件
                </p>
            </Dragger>
        </Modal>
        
    )
};

export default EditDepartmentModal
