import { Modal, Input, InputNumber, DatePicker, Space, message, Button } from 'antd';
import { Dropdown, Menu, Typography } from 'antd';
import React, { useState, useEffect, useRef } from 'react';
import EmployeeController from '../../../utils/employee';
import { DownOutlined } from '@ant-design/icons';
import moment from 'moment'
import { withNotification } from '../../../utils/notification';

import MessageModal from '../../component/MessageModal'

const { TextArea } = Input;

const EmployeeEditModal = (props) => {

    const {visible, setVisible, newRecord, setNewRecord, handleSave, isAddingRecord} = props

    const [showDelete, setShowDelete] = useState(false)
    const [deleteMessage, setDeleteMessage] = useState('')

    const [confirmLoading, setConfirmLoading] = useState(false)

    const [error, setError] = useState({
        name: false,
    })

    const record = useRef('')

    useEffect(() => {
        if (visible){
            console.log(newRecord)
            record.current = newRecord
        }
    }, [visible])


    useEffect(() => {

        if (visible){
            checkError()
        }

    }, [newRecord])

    const checkError = () => {
        // validate newRecords
        let hasError = false
        const newError = {...error}
        Object.keys(error).forEach(k => {
            if (newRecord[k] && newRecord[k].length > 0){
                newError[k] = false
            }else{
                newError[k] = true
                hasError = true
            }  
        })
        setError(newError)
        return(hasError)
    }

    const handleOk = () => {
        // console.log(newRecord)
        // return
        if (checkError()){
            message.error('有遗漏项目，请检查')
            return
        }
        setConfirmLoading(true);
        setTimeout(async () => {

            console.log(isAddingRecord)

            try{
                if (isAddingRecord){
                    await withNotification(EmployeeController.insertEmployee(newRecord), '', false)
                    message.success('添加员工成功: ' + newRecord.name)
                }else{
                    await withNotification(EmployeeController.updateEmployee(newRecord), '', false)
                    message.success('更新员工成功: ' + newRecord.name)
                }
            }catch(e){
                message.error(e.message)
            }
            setConfirmLoading(false)
            setVisible(false)
            handleSave()
        }, 0)
    };

    const handleCancel = () => {
        // console.log('Clicked cancel button');
        setVisible(false);
    };


    const resetClick = () => {
        setNewRecord({...record.current})
    }

    const handleDelete = () => {
        return new Promise((resolve, reject) => {
            setTimeout(async () => {
                try{
                    await EmployeeController.removeEmployee(record.current.code)
                    message.success('删除员工成功：' + record.current.name)
                }catch(e){
                    console.log(e)
                }
                setShowDelete(false)
                setVisible(false)
                handleSave()
                resolve(1)
            }, 0);
        })
    }

    const deleteClick = () => {
        setShowDelete(true)
        setDeleteMessage('确认要删除员工：' + record.current.name + ' 吗？')
    }

    const title = (
        <div style={{display:'flex', alignItems:'center'}}>
            <div style={{marginRight:'1rem'}}>修改信息</div> 
            <div style={{margin:'auto 1rem'}}>
                <Dropdown
                    overlay={
                        <Menu
                            selectable
                            selectedKeys={[newRecord.status]}
                            onSelect={(e) => {setNewRecord({...newRecord, status:e.key})}}
                            items={[
                            {
                                key: 'Y',
                                label: '在职',
                            },
                            {
                                key: 'N',
                                label: '离职',
                            },
                            ]}
                        />}>
                    <Typography.Link>
                    <Space>
                        {newRecord.status === 'Y'? '在职':'离职'}
                        <DownOutlined />
                    </Space>
                    </Typography.Link>
                </Dropdown>
            </div>
            {
                !isAddingRecord && 
                <Button style={{margin:'auto 0rem', color:'red', }} type='ghost' onClick={deleteClick}>
                    删除员工
                </Button>
            }
            

            <Button style={{margin:'auto 1rem'}} type='primary' onClick={resetClick}>
                重置
            </Button>

        </div>
    )

    return (
        <>
            <Modal
                title={title}
                visible={visible}
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
                okText="保存"
                cancelText="取消"
                width={600}
                destroyOnClose
            >

                {
                    <div onKeyUp={e=>{if(e.key==='Enter') handleOk()}}>
                        <div style={{display:'flex'}}>
                            <div>
                                <div style={{display:'flex', marginBottom:'0.6rem'}}>
                                    <p style={{margin:'auto 1rem'}}>姓名:</p> 
                                    <Input status={error.name && 'error'}
                                        style={{width:'6rem'}} value={newRecord.name} onChange={(e) => setNewRecord({...newRecord, name:e.target.value})}/>
                                </div>
                                <div style={{display:'flex', marginBottom:'0.6rem'}}>
                                    <p style={{margin:'auto 1rem'}}>工位号:</p>
                                    <InputNumber style={{width:'5rem'}} min={0} step={1} formatter={(v) => v? parseInt(v):0}
                                                value={newRecord.position_code} onChange={(v) => setNewRecord({...newRecord, position_code:v})}/>
                                </div>

                            </div>
                            
                            <div>
                                <p style={{margin:'auto 2rem', marginBottom:'1rem'}}>企业工龄: {newRecord.workingAge}</p>
                                <p style={{margin:'auto 2rem'}}>工龄: {newRecord.gradAge}</p>
                            </div>
                            
                            <div style={{display:'flex', height:'2rem', margin:'auto 1rem'}}>
                                <p style={{margin: 'auto 0'}}>技能系数：</p>
                                <InputNumber style={{margin:'auto 0.5rem'}} min={0} max={1} step={0.01}
                                                value={newRecord.x3} onChange={(v) => setNewRecord({...newRecord, x3:v})}/>
                            </div>
                        </div>

                        <div style={{display:'flex', alignItems:'center'}}>
                            <p style={{margin:'auto 1rem', marginRight:'.3rem'}}> 部门名称：</p>
                            <Input style={{width:'27rem', height:'2rem'}} value={newRecord.department} onChange={(e) => setNewRecord({...newRecord, department:e.target.value})} />
                        </div>

                        <div style={{display:'flex', marginTop:'0.5rem'}}>
                            <div style={{display:'flex'}}>
                                <p style={{margin:'auto 1rem'}}>入职日期:</p>
                                <DatePicker placeholder={newRecord.entry_date} 
                                            value={moment(newRecord.entry_date)} 
                                            style={{width:'8rem'}}  
                                            onChange={(date, dateString) => {
                                                // console.log(dateString)
                                                if (date){
                                                    // 更新企业工龄
                                                    const now = new Date()
                                                    const entryTime = new Date(dateString)
                                                    let workingAge = now.getFullYear() - entryTime.getFullYear() + (now.getMonth() < entryTime.getMonth()? 1:0)
                                                    setNewRecord({
                                                        ...newRecord,
                                                        entry_date: dateString,
                                                        workingAge
                                                    })
                                                }else{
                                                    setNewRecord({
                                                        ...newRecord,
                                                        entry_date: record.current.entry_date,
                                                        mometnEntryDate: '',
                                                        workingAge: record.current.workingAge
                                                    })
                                                    
                                                }
                                    }}/>
                            </div>
                            <div style={{display:'flex'}}>
                                <p style={{margin:'auto 1rem'}}>毕业日期:</p>
                                <DatePicker placeholder={newRecord.graduation_date}
                                            value={moment(newRecord.graduation_date)}
                                            style={{width:'8rem'}}
                                            onChange={(date, dateString) => {
                                                if (date){
                                                    // 更新工龄
                                                    const now = new Date()
                                                    const entryTime = new Date(dateString)
                                                    let gradAge = now.getFullYear() - entryTime.getFullYear() + (now.getMonth() < entryTime.getMonth()? 1:0)
                                                    setNewRecord({
                                                        ...newRecord,
                                                        graduation_date: dateString,
                                                        gradAge
                                                    })
                                                }else{
                                                    setNewRecord({
                                                        ...newRecord,
                                                        graduation_date: record.current.graduation_date,
                                                        gradAge: record.current.gradAge
                                                    })
                                                }
                                    }}/>
                            </div>
                        </div>

                        <div style={{margin:'1rem'}}>
                            <p style={{}}>备注:</p>
                            <TextArea placeholder="添加备注" allowClear value={newRecord.comment} onChange={(e) => setNewRecord({...newRecord, comment:e.target.value})} />
                        </div>
                    </div>
                }
                
            </Modal>

            <MessageModal visible={showDelete} setVisible={setShowDelete} title='删除员工' message={deleteMessage} 
                        okText='确认删除' okType='danger' handleConfirm={handleDelete} />

        </>
    );
};

export default EmployeeEditModal;