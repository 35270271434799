import axios from 'axios'


const getAllAccess = () => {
    return axios.get('/api/access/')
}

const updateLoginAccessCode = (code, accessCode) => {
    return axios.post('/api/access/update-login-access-code', {code, accessCode})
}

const updateAccessInfo = (access) => {
    return axios.post('/api/access/update', {access})
}

const addNewAccess = (access) => {
    return axios.post('/api/access/new', {access})
}

const deleteAccess = (uid) => {
    return axios.delete('/api/access/' + uid)
}

const AccessController = {
    getAllAccess, updateLoginAccessCode, updateAccessInfo,
    addNewAccess, deleteAccess
}

export default AccessController
