import { message, Table, Button, Typography } from 'antd';
import React, { useEffect, useState, useRef, useContext } from 'react';
import { UserContext } from "../../AppLayout"
import DepartmentController from '../../utils/department';
import { withNotification } from '../../utils/notification';
import {
    DownloadOutlined, ReloadOutlined, QuestionCircleOutlined 
} from '@ant-design/icons';

const { Link } = Typography;


const DEPARTMENT_INFO_BG_COLOR = 'rgb(245, 245, 245)'

const Department = (props) => {

    const user = useContext(UserContext)
    const [newDepartment, setNewDepartment] = useState({key:'1'})
    const [departmentSummary, setDepartmentSummary] = useState([])

    const [inEdit, setInEdit] = useState(false)
    const [saveLoading, setSaveLoading] = useState(false)
    const [infoTableLoading, setInfoTableLoading] = useState(false)
    const [detailTableLoading, setDetailTableLoading] = useState(false)
    const [v3total, setV3total] = useState(0)

    const department = useRef('')

    const loadDepartmentDetails = () => {

        const department_code = window.location.pathname.split('/')[2]
        setInfoTableLoading(true)
        setDetailTableLoading(true)

        // Access control:
        if (!user.access.department_page_edit){
            message.error('用户没有权限')
            setTimeout(() => {
                window.location = '/'
            }, 3000)
            return
        }

        setTimeout(async () => {
            try{
                const res = await withNotification(DepartmentController.getDeparmentDetailsByCode(department_code), '', false)
                console.log(res)
                const data = res.data
                const dept = {...data.department[0], key: department_code}
                department.current = dept
                setNewDepartment(dept)

                const departments = []

                let v3total = 0

                const projectValues = (data.project_values_archived || [])
                projectValues.map((p,idx) => {
                    const res = {...p, key: idx}
                    v3total += p.v3
                    if (!departments.map(d => d.name).includes(p.project_name)){
                        const d = {
                            key: departments.length,
                            code: p.project_code,
                            name: p.project_name,
                            type: p.project_type,
                            start_date: p.project_start_date,
                            end_date: p.project_end_date,
                            comment: p.project_comment,
                            owner: p.project_owner,
                            data: [res]
                        }

                        departments.push(d)
                    }else{
                        const index = departments.map(d => d.name).indexOf(p.project_name)
                        departments[index].data.push(res)
                    }
                    return res
                })
                console.log(departments)
                setDepartmentSummary(departments)
                setV3total((v3total * 0.1).toFixed(0))

            }catch(e){
                console.log(e)
                message.error(e.message)
            }finally{
                setInfoTableLoading(false)
                setDetailTableLoading(false)
            }
        })

    }

    useEffect(() => {
        // console.log(user)
        loadDepartmentDetails()
    }, [])




    const reloadClick = () => {
        loadDepartmentDetails()
    }

    const saveClick = () => {
        
    }

    const editClick = () => {
        setInEdit(true)
    }

    const cancelEditClick = () => {
        setNewDepartment(department.current)
        setInEdit(false)
    }

    const downloadClick = () => {
        
    }

    const departmentColumn = [
        {
            title: '部门名称',
            dataIndex: 'name',
            width: 100
        },
        {
            title: '部门编号',
            dataIndex: 'code',
            width: 100
        },
        {
            title: '主管激励',
            dataIndex: 'fee',
            width: 100,
            render: () => v3total
        },
        {
            title: '备注',
            dataIndex: 'comment',

        },
    ]

    const departmentProjectSummaryColumns = [
        {
            title: '项目名称',
            dataIndex: 'name',
            width: 131,
            ellipsis: true,
            onCell: (record, index) =>  {return {style:{backgroundColor: DEPARTMENT_INFO_BG_COLOR}}},
            render: (text, record) => <Link href={'/project/' + record.code} target="_blank"> {text} </Link>,
        },
        {
            title: '咨询类别',
            dataIndex: 'type',
            width: 120,
            ellipsis: true,
            onCell: (record, index) =>  {return {style:{backgroundColor: DEPARTMENT_INFO_BG_COLOR}}},
        },
        {
            title: '负责人',
            dataIndex: 'owner',
            width: 80,
            ellipsis: true,
            onCell: (record, index) =>  {return {style:{backgroundColor: DEPARTMENT_INFO_BG_COLOR}}},
        },
        {
            title: '总完成造价',
            dataIndex: 'Asum',
            width: 120,
            onCell: (record, index) =>  {return {style:{backgroundColor: DEPARTMENT_INFO_BG_COLOR}}},
            render: (_, record) => {
                let sum = 0
                if (record && record.data){
                    record.data.forEach(d => {
                        sum += d.A
                    })
                }
                return (sum / 10000).toFixed(4) + '(万)'
            }
        },
        {
            title: '总审减金额',
            dataIndex: 'bsum',
            width: 120,
            ellipsis: true,
            onCell: (record, index) =>  {return {style:{backgroundColor: DEPARTMENT_INFO_BG_COLOR}}},
            render: (_, record) => {
                let sum = 0
                if (record && record.data){
                    record.data.forEach(d => {
                        sum += d.b
                    })
                }
                return (sum / 10000).toFixed(4) + '(万)'
            }
        },
        {
            title: '总原始产值0',
            dataIndex: 'v0sum',
            width: 100,
            ellipsis: true,
            onCell: (record, index) =>  {return {style:{backgroundColor: DEPARTMENT_INFO_BG_COLOR}}},
            render: (_, record) => {
                let sum = 0
                if (record && record.data){
                    record.data.forEach(d => {
                        sum += d.v0
                    })
                }
                return sum
            }
        },
        {
            title: '总项目产值V',
            dataIndex: 'vsum',
            width: 100,
            ellipsis: true,
            onCell: (record, index) =>  {return {style:{backgroundColor: DEPARTMENT_INFO_BG_COLOR}}},
            render: (_, record) => {
                let sum = 0
                if (record && record.data){
                    record.data.forEach(d => {
                        sum += d.v
                    })
                }
                return sum
            }
        },
        {
            title: '总员工产值1',
            dataIndex: 'v1sum',
            width: 100,
            ellipsis: true,
            onCell: (record, index) =>  {return {style:{backgroundColor: DEPARTMENT_INFO_BG_COLOR}}},
            render: (_, record) => {
                let sum = 0
                if (record && record.data){
                    record.data.forEach(d => {
                        sum += d.v1
                    })
                }
                return sum
            }
        },
        {
            title: '总员工产值2',
            dataIndex: 'v2sum',
            width: 100,
            ellipsis: true,
            onCell: (record, index) =>  {return {style:{backgroundColor: DEPARTMENT_INFO_BG_COLOR}}},
            render: (_, record) => {
                let sum = 0
                if (record && record.data){
                    record.data.forEach(d => {
                        sum += d.v2
                    })
                }
                return sum
            }
        },
        {
            title: '总附加产值3',
            dataIndex: 'v3sum',
            width: 100,
            ellipsis: true,
            onCell: (record, index) =>  {return {style:{backgroundColor: DEPARTMENT_INFO_BG_COLOR}}},
            render: (_, record) => {
                let sum = 0
                if (record && record.data){
                    record.data.forEach(d => {
                        sum += d.v3
                    })
                }
                return sum
            }
        },
        {
            title: '总员工产值4',
            dataIndex: 'v4sum',
            width: 100,
            ellipsis: true,
            onCell: (record, index) =>  {return {style:{backgroundColor: DEPARTMENT_INFO_BG_COLOR}}},
            render: (_, record) => {
                let sum = 0
                if (record && record.data){
                    record.data.forEach(d => {
                        sum += d.v4
                    })
                }
                return sum
            }
        },
        {
            title: '总质量扣除产值5',
            dataIndex: 'v5sum',
            width: 130,
            ellipsis: true,
            onCell: (record, index) =>  {return {style:{backgroundColor: DEPARTMENT_INFO_BG_COLOR}}},
            render: (_, record) => {
                let sum = 0
                if (record && record.data){
                    record.data.forEach(d => {
                        sum += d.v5
                    })
                }
                return sum
            }
        },
        {
            title: '开始日期',
            dataIndex: 'start_date',
            width: 120,
            onCell: (record, index) =>  {return {style:{backgroundColor: DEPARTMENT_INFO_BG_COLOR}}},
        },
        {
            title: '结束日期',
            dataIndex: 'end_date',
            width: 120,
            onCell: (record, index) =>  {return {style:{backgroundColor: DEPARTMENT_INFO_BG_COLOR}}},
        },
        {
            title: '备注',
            dataIndex: 'comment',
            ellipsis: true,
            onCell: (record, index) =>  {return {style:{backgroundColor: DEPARTMENT_INFO_BG_COLOR}}},
        },
    ]

    const departmentProjectValuesColumns = [
        {
            title: '专业',
            dataIndex: 'major',
            width: 50,
            ellipsis: true,
            onCell: (record, index) => {
                const cell = {}
                if (record.major){
                    cell.colSpan = 1
                }else{
                    cell.colSpan = 0
                }
                return(cell)
            }
        },
        {
            title: '分工',
            dataIndex: 'position',
            width: 80,
            ellipsis: true,
            onCell: (record, index) => {
                const cell = {}
                if (record.major){
                    cell.colSpan = 1
                }else{
                    cell.colSpan = 2
                }
                return(cell)
            }
        },
        {
            title: '单位工程',
            dataIndex: 'work',
            width: 120,
            ellipsis: true,
        },
        {
            title: '姓名',
            dataIndex: 'name',
            width: 80,
            ellipsis: true,
        },
        {
            title: '完成造价',
            dataIndex: 'A',
            width: 120,
            ellipsis: true,
        },
        {
            title: '审减金额',
            dataIndex: 'b',
            width: 120,
            ellipsis: true,
        },
        {
            title: '原始产值V0',
            dataIndex: 'v0',
            width: 100,
            ellipsis: true,
        },
        {
            title: '项目产值V',
            dataIndex: 'v',
            width: 100,
            ellipsis: true,
        },
        {
            title: '员工产值V1',
            dataIndex: 'v1',
            width: 100,
            ellipsis: true,
        },
        {
            title: '员工产值V2',
            dataIndex: 'v2',
            width: 100,
            ellipsis: true,
        },
        {
            title: '附加产值V3',
            dataIndex: 'v3',
            width: 100,
            ellipsis: true,
        },
        {
            title: '员工产值V4',
            dataIndex: 'v4',
            width: 100,
            ellipsis: true,
        },
        {
            title: '质量扣除产值V5',
            dataIndex: 'v5',
            width: 120,
            ellipsis: true,
        },
        {
            title: '完成造价比',
            dataIndex: 'A_perc',
            width: 90,
            ellipsis: true,
            render: (text, record) => {
                return (record.A_perc * 100).toFixed(2) + '%'
            }
        },
        {
            title: '难易系数x1',
            dataIndex: 'x1',
            width: 90,
            ellipsis: true,
        },
        {
            title: '产值系数x2',
            dataIndex: 'x2',
            width: 90,
            ellipsis: true,
        },
        {
            title: '质量系数x5',
            dataIndex: 'x5',
            width: 90,
            ellipsis: true,
            render: (text, record) => {
                return (record.x5 * 100).toFixed(0) + '%'
            }
        },
        {
            title: '时长',
            dataIndex: 'hours',
        },
        {
            title: '备注',
            dataIndex: 'comment',
        },
    ]


    return (
        <div>

            <div style={{margin:'.5rem 0', display:'flex', justifyContent:'space-between'}}>
                <div style={{marginLeft: '.1rem'}}>
                    {/* <h3>{newDepartment.name}</h3> */}
                    <h3>部门产值</h3>

                </div>
                
                <div style={{display:'flex'}}>
                    <Button style={{marginRight:'1rem'}} type='ghost' onClick={reloadClick} >
                        <ReloadOutlined />
                    </Button>

                    <Button style={{marginRight:'1rem'}} onClick={downloadClick}>
                        下载
                    </Button>

                    <Button loading={saveLoading} type='primary' onClick={inEdit? saveClick : editClick}>
                        {inEdit? '保存': '编辑'} 
                    </Button>

                    {
                        inEdit && 
                        <Button style={{marginLeft:'1rem'}} onClick={cancelEditClick}>
                            取消
                        </Button>
                    }
                </div>
                

            </div>

            <Table id='department-info-table' style={{marginBottom: '.5rem'}} bordered
                loading={infoTableLoading}
                dataSource={[newDepartment]}
                columns={departmentColumn}
                size='small'
                pagination={false}
            />

            <Table id='department-project-values-table' size='small' bordered
                loading={detailTableLoading}
                dataSource={departmentSummary}
                columns={departmentProjectSummaryColumns}
                pagination={false}
                scroll={{
                    x: 1800,
                    // y: tableHeight
                }}
                expandable={{
                    expandedRowRender: (record) => {
                        return (
                            <Table id={'department-project-values-table' + record.key} pagination={false}
                                size='small' bordered
                                dataSource={record.data || []}
                                columns={departmentProjectValuesColumns}
                            />
                        )
                        
                    },
                }}
                summary={(pageData) => {

                    let [Asum, bsum, v0sum, vsum, v1sum, v2sum, v3sum, v4sum, v5sum] = [0, 0, 0, 0, 0, 0, 0, 0, 0]
                    departmentSummary.forEach(d => {
                        d.data.forEach(p => {
                            Asum += p.A
                            bsum += p.b
                            v0sum += p.v0
                            vsum += p.v
                            v1sum += p.v1
                            v2sum += p.v2
                            v3sum += p.v3
                            v4sum += p.v4
                            v5sum += p.v5
                        })
                    })

                    return (
                        <Table.Summary fixed>
                            <Table.Summary.Row>
                                <Table.Summary.Cell className="detail-summary-cell" colSpan={5} index={0}>
                                    <div style={{display:'flex', justifyContent:'space-between'}}>
                                        <div>
                                            小计
                                        </div>
                                        
                                        <div style={{marginRight:'1rem'}}>
                                            {(Asum/100000000).toFixed(4)}(亿)
                                        </div>
                                    </div>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell className="detail-summary-cell" colSpan={1} index={0}>
                                    <div>
                                        {(bsum/100000000).toFixed(4)}(亿)
                                    </div>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell className="detail-summary-cell" colSpan={1} index={0}>
                                    <div>
                                        {(v0sum/10000).toFixed(2)}(万)
                                    </div>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell className="detail-summary-cell" colSpan={1} index={0}>
                                    <div>
                                        {(vsum/10000).toFixed(2)}(万)
                                    </div>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell className="detail-summary-cell" colSpan={1} index={0}>
                                    <div>
                                        {(v1sum/10000).toFixed(2)}(万)
                                    </div>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell className="detail-summary-cell" colSpan={1} index={0}>
                                    <div>
                                        {(v2sum/10000).toFixed(2)}(万)
                                    </div>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell className="detail-summary-cell" colSpan={1} index={0}>
                                    <div>
                                        {(v3sum/10000).toFixed(2)}(万)
                                    </div>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell className="detail-summary-cell" colSpan={1} index={0}>
                                    <div>
                                        {(v4sum/10000).toFixed(2)}(万)
                                    </div>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell className="detail-summary-cell" colSpan={1} index={0}>
                                    <div>
                                        {(v5sum/10000).toFixed(2)}(万)
                                    </div>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell className="detail-summary-cell" colSpan={3} />
                            </Table.Summary.Row>
                        </Table.Summary>
                    )
                }}

             />

        </div>
    )
}

export default Department