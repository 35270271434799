import React, { useState, useContext } from 'react';
import { Button, Input, Space, Tooltip, Modal, Typography, message } from 'antd';
import {
    PlusOutlined, EditOutlined, DownloadOutlined, SaveOutlined,
    UploadOutlined, ReloadOutlined, QuestionCircleOutlined
} from '@ant-design/icons';
import LoginController from '../../utils/login';
import { withNotification } from '../../utils/notification';
import { UserContext } from '../../AppLayout';


const User = () => {

    const user = useContext(UserContext)

    const [confirmLoading, setConfirmLoading] = useState(false)
    const [oldPwd, setOldPwd] = useState('')
    const [newPwd, setNewPwd] = useState('')
    const [newPwd2, setNewPwd2] = useState('')
    const [errorOldPwd, setErrorOldPwd] = useState(false)
    const [errorNewPwd, setErrorNewPwd] = useState(false)
    const [errorNewPwd2, setErrorNewPwd2] = useState(false)

    const [oldUsername, setOldUsername] = useState('')
    const [newUsername, setNewUsername] = useState('')
    const [errorNewUsername, setErrorNewUsername] = useState(false)
    const [errorOldUsername, setErrorOldUsername] = useState(false)
    const [confirmUsernameLoading, setConfirmUsernameLoading] = useState(false)

    const clearPwd = () => {
        setOldPwd('')
        setNewPwd('')
        setNewPwd2('')
        setErrorOldPwd(false)
        setErrorNewPwd2(false)
        setErrorNewPwd(false)
    }

    const clearUsername = () => {
        setOldUsername('')
        setNewUsername('')
        setErrorOldUsername(false)
        setErrorNewUsername(false)
    }

    const updateUsernameClick = () => {
        if (!oldUsername) setErrorOldUsername(true)
        if (!newUsername) setErrorNewUsername(true)

        if (oldUsername !== user.username){
            message.error('当前用户名输入错误')
            setErrorOldUsername(true)
            return
        }

        if (!newUsername){
            message.error('新用户名禁止为空')
            setErrorNewUsername(true)
            return
        }

        setTimeout(async () => {
            setConfirmUsernameLoading(true)
            try{
                await withNotification(LoginController.updateUsername(user.code, newUsername), '用户名更新成功')
            }catch(e){
                message.error(e.message)
            }finally{
                setConfirmUsernameLoading(false)
                clearUsername()
            }

        })

    }


    const updatePasswordClick = () => {
        if (!oldPwd) setErrorOldPwd(true)
        if (!newPwd) setErrorNewPwd(true)
        if (!newPwd2) setErrorNewPwd2(true)
        
        if (newPwd !== newPwd2){
            message.error('两次密码输入不一致')
            setErrorNewPwd2(true)
            return
        }

        if (!oldPwd || !newPwd || !newPwd2){
            message.error('请检查密码输入')
            return
        }

        setTimeout(async () => {
            setConfirmLoading(true)
            try{
                await withNotification(LoginController.updatePassword(oldPwd, newPwd), '密码更新成功')
            }catch(e){
                message.error(e.message)
            }finally{
                setConfirmLoading(false)
                clearPwd()
            }
        })
    }


    return (
        <div style={{display:'flex'}}>

            <Space style={{margin:'0 auto'}} align='center' direction='vertical'>
                <h2>修改用户名</h2>
                <br />

                <div>
                    <p>请输入当前用户名：</p>
                    <Input allowClear status={errorOldUsername && 'error'} value={oldUsername} onChange={(e) => {setErrorOldUsername(false); setOldUsername(e.target.value)}}/>
                </div>
                
                <div>
                    <p>请输入新用户名：</p>
                    <Input allowClear status={errorNewUsername && 'error'} value={newUsername} onChange={(e) => {setErrorNewUsername(false); setNewUsername(e.target.value)}}/>
                </div>
                <br />

                <Button loading={confirmUsernameLoading} onClick={updateUsernameClick} type='primary'>
                    更新用户名
                </Button>
            
            </Space>


            <Space style={{margin:'0 auto'}} align='center' direction='vertical'>

            <h2>
                修改密码
            </h2>
            <br />

            <div>
            <p>请输入旧密码：</p>
            <Input.Password allowClear status={errorOldPwd && 'error'} placeholder="" value={oldPwd} onChange={(e) => {setErrorOldPwd(false); setOldPwd(e.target.value)}} />
            </div>
            
            <div>
            <p>请输入新密码：</p>
            <Input.Password allowClear status={errorNewPwd && 'error'} placeholder="" value={newPwd} onChange={(e) => {setErrorNewPwd(false); setNewPwd(e.target.value)}} />
            </div>

            <div>
            <p>请再次输入新密码：</p>
            <Input.Password allowClear status={errorNewPwd2 && 'error'} placeholder="" value={newPwd2} onChange={(e) => {setErrorNewPwd2(false); setNewPwd2(e.target.value)}}
             onKeyUp={(k) => { if (k.key === 'Enter') updatePasswordClick()}} />
            </div>

            <br />

            <Button loading={confirmLoading} onClick={updatePasswordClick} type='primary'>
                更新密码
            </Button>

            </Space>

            


        </div>
    )

}


export default User
