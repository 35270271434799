import React from 'react';
import { useEffect, useState, useRef, useContext } from 'react';
import { withNotification } from '../../utils/notification';
import { Dropdown, Input, Table, Menu, Modal, Space, message, Typography } from 'antd';
import { getTableScroll } from '../../utils/calculateHeight';
import {
    ControlOutlined, EditOutlined, DownOutlined
} from '@ant-design/icons';
import LoginController from '../../utils/login';
import { UserContext } from '../../AppLayout';
import AccessController from '../../utils/access';

const { Link } = Typography
const { Search } = Input;

const UsersAdminPage = () => {

    const user = useContext(UserContext)
    // console.log(user)

    const [tableHeight, setTableHeight] = useState('')

    const searchValue = useRef('')
    const [searchLoading, setSearchLoading] = useState(false)
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)

    const [confirmLoading, setConfirmLoading] = useState(false)
    const [newPwd, setNewPwd] = useState('')
    const [newPwd2, setNewPwd2] = useState('')
    const [errorNewPwd, setErrorNewPwd] = useState(false)
    const [errorNewPwd2, setErrorNewPwd2] = useState(false)

    const [changePwdVisible, setChangePwdVisible] = useState(false)
    const [chengAccessVisible, setChangeAccessVisible] = useState(false)
    const [selectedAccess, setSelectedAccess] = useState(0)
    const [confirmChangeAccessLoading, setConfirmChangeAccessLoading] = useState(false)

    const [accessList, setAccessList] = useState([])

    const [name, setName] = useState('')
    const code = useRef('')

    useEffect(() =>{ 
        loadAccess()
        loadData()
    }, [])

    const loadAccess = () => {
        setTimeout(async () => {

            try{
                const res = await withNotification(AccessController.getAllAccess(), '', false)
                const access = res.data.map(a => ({
                    key: a.code,
                    label: a.name
                }))
                setAccessList(access)
                // console.log(access)
            }catch(e){
                message.error(e.message)
            }

        });

    }

    const loadData = () => {
        setLoading(true)
        return new Promise((resolve) => {
            setTimeout(async () => {
                try{
                    const res = await withNotification(LoginController.getUsers(), '', false)
                    const users = res.data.filter(d => (d.username && (d.username + d.name).includes(searchValue.current))).map(d => ({
                        ...d,
                        key: d.code
                    }))
                    // console.log(users)
                    setData(users)
                    setTableHeight(getTableScroll({ extraHeight: 0, id: 'users-admin-table' }))
                }catch(e){
                    message.error(e.message)
                }finally{
                    resolve(1)
                    setLoading(false)
                }
            })
        })
    }

    const columns = [
        {
            title: '工号',
            dataIndex: 'code',
            width: 100,
        },
        {
            title: '姓名',
            dataIndex: 'name',
            width: 150,
            render: (text, record) => {
                if (record.username === 'admin'){
                    return '管理员'
                }
                return text
            }
        },
        {
            title: '用户名',
            dataIndex: 'username'
        },
        {
            title: '权限',
            dataIndex: 'access_name'
        },
        {
            title: '编辑',
            key: 'edit',
            // width: 90,
            fixed: 'right',
            render: (_, record, idx) => (
                <Space >
                    <Link onClick={() => editClick(record)}> <EditOutlined /> 改密码 </Link>
                    {
                        record.username !== 'admin' &&
                        <Link onClick={() => editAccessClick(record)}> <ControlOutlined /> 改权限 </Link>
                    }
                </Space>
            ),
        },
        
    ]

    const editClick = (record) =>{ 
        // console.log(record)
        clearPwd()
        code.current = record.code
        setName(record.name)
        setChangePwdVisible(true)
    }

    const editAccessClick = (record) =>{ 
        // console.log(record)
        clearPwd()
        code.current = record.code
        setName(record.name)
        setSelectedAccess(record.access_code)
        setChangeAccessVisible(true)
    }

    const searchClick = (v) => {
        searchValue.current = v
        setSearchLoading(true)
        setTimeout(async () => {
            await loadData()
            setSearchLoading(false)
        })
    }

    const clearPwd = () => {
        setNewPwd('')
        setNewPwd2('')
        setErrorNewPwd2(false)
        setErrorNewPwd(false)
    }

    const confirmClick = () => {
        if (!newPwd) setErrorNewPwd(true)
        if (!newPwd2) setErrorNewPwd2(true)

        if (newPwd !== newPwd2){
            message.error('两次密码输入不一致')
            setErrorNewPwd2(true)
            return
        }

        if (!newPwd || !newPwd2){
            message.error('请检查密码输入')
            return
        }

        setTimeout(async () => {
            setConfirmLoading(true)
            try{
                await withNotification(LoginController.updatePasswordByCode(code.current, newPwd), '更新成功')
            }catch(e){
                message.error(e.message)
            }finally{
                cancelClick()
            }
        })
    }

    const cancelClick = () => {
        setConfirmLoading(false)
        clearPwd()
        setChangePwdVisible(false)
    }

    const CancelChangeAccessClick = () => {
        setChangeAccessVisible(false)
        setSelectedAccess(0)
    }

    const confirmChangeAccessClick = () => {
        setConfirmChangeAccessLoading(true)

        setTimeout(async () => {
            try{
                await withNotification(AccessController.updateLoginAccessCode(code.current, selectedAccess), '权限更新成功')
                CancelChangeAccessClick()
                loadData()
            }catch(e){
                message.error(e.message)
            }finally{
                setConfirmChangeAccessLoading(false)
            }

        })

    }

    return (
        <div>
            <div style={{marginBottom:'1rem', display:'flex', justifyContent:'space-between'}} >
                
                <Search style={{width:'15rem'}} allowClear enterButton placeholder='搜索用户名' loading={searchLoading}
                        onSearch={searchClick} />

                {/* <Space >
                    <Button>
                        <DownloadOutlined /> 下载
                    </Button>
                </Space> */}
            </div>

            <Table id='users-admin-table' size='middle' loading={loading} bordered
                columns={columns}
                dataSource={data}
                pagination={{defaultPageSize:20}}
                scroll={{
                    y: tableHeight
                }}
             />

            <Modal visible={changePwdVisible} title={'修改密码 ' + `[${name}]`}
                    confirmLoading={confirmLoading}
                    onOk={confirmClick}
                    onCancel={cancelClick}
                 >

                <div style={{display:'flex'}}>
                <Space align='center' direction='vertical' style={{margin:'0 auto'}}>

                    <div>
                        <p>请输入新密码：</p>
                        <Input.Password allowClear status={errorNewPwd && 'error'} placeholder="" value={newPwd} onChange={(e) => {setErrorNewPwd(false); setNewPwd(e.target.value)}} />
                    </div>

                    <div>
                        <p>请再次输入新密码：</p>
                        <Input.Password allowClear status={errorNewPwd2 && 'error'} placeholder="" value={newPwd2} onChange={(e) => {setErrorNewPwd2(false); setNewPwd2(e.target.value)}}
                            onKeyUp={(k) => { if (k.key === 'Enter') confirmClick()}} />
                    </div>


                </Space>
                </div>
                
                
            </Modal>

            <Modal title={'修改权限' + `[${name}]`} 
                visible={chengAccessVisible}
                onCancel={CancelChangeAccessClick}
                confirmLoading={confirmChangeAccessLoading}
                onOk={confirmChangeAccessClick}
            >
                <div style={{display:'flex', justifyContent: 'center'}}>

                    <p>权限等级：</p>
                    <Dropdown overlay={(<Menu
                                        onSelect={(e) => {setSelectedAccess(parseInt(e.key))}}
                                        selectable
                                        selectedKeys={['' + selectedAccess]}
                                        items={accessList}
                                        />)}>
                        <Typography.Link>
                        <Space>
                            {accessList.filter(a => a.key === selectedAccess)[0] && accessList.filter(a => a.key === selectedAccess)[0].label}
                            <DownOutlined />
                        </Space>
                        </Typography.Link>
                    </Dropdown>

                </div>
                
            </Modal>

        </div>
    )


}


export default UsersAdminPage
