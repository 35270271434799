import axios from "axios";
import { read, utils, writeFile } from 'xlsx';
import { withNotification } from "./notification";

const getEmployees = (code) => {
    if (code){
        return axios.get('/api/employee/' + code)
    }
    return axios.get('/api/employee/')
}

const getEmployeeSkillRate = () => {
    return axios.get('/api/employee/employee-skill')
}

const getEmployeeProjectValuesArchivedByCode = (code) => {
    return axios.get('/api/employee/employee-detail-archived/' + code)
}

const updateEmployeeSkillRate = (code, name, skill, year) => {
    return axios.post('/api/employee/skill-rate', {
        code, name, skill, year
    })
}

// uid, position_code, name, graduation_date, entry_date, comment, status
const updateEmployee = (record) => {
    return axios.post('/api/employee/update', record)
}

const insertEmployee = (record) => {
    return axios.post('/api/employee/', record)
}

const removeEmployee = (code) => {
    return axios.delete('/api/employee/' + code)
}

const downloadEmployeeXlsx = () => {

    return new Promise((resolve, reject) => {
        setTimeout(async () =>{ 
            try{
                const res = await Promise.all([
                    withNotification(getEmployees(), '', false),
                    withNotification(getEmployeeSkillRate(), '', false)
                ])
                const data = res.map(r => r.data)
                // console.log(data)

                const data1 = data[0].map(d => {

                    d.graduation_date = d.graduation_date.split('T')[0]
                    d.entry_date = d.entry_date.split('T')[0]

                    const now = new Date()
                    const entryTime = new Date(d.entry_date)
                    d.workingAge = now.getFullYear() - entryTime.getFullYear()
                    const gradTime = new Date(d.graduation_date)
                    d.gradAge = now.getFullYear() - gradTime.getFullYear()

                    return {
                        code: d.code,
                        name: d.name,
                        position_code: d.position_code,
                        x3: d.x3,
                        workingAge: d.workingAge,
                        gradAge: d.gradAge,
                        entry_date: d.entry_date,
                        graduation_date: d.graduation_date,
                        comment: d.comment,
                        status: d.status === 'Y'? '在职':'离职'
                    }
                })

                const workbook = utils.book_new()
                const worksheet1 = utils.json_to_sheet(data1)

                utils.book_append_sheet(workbook, worksheet1, '员工信息')
                // fix header
                const fixedHeader = [
                    "工号", "姓名", "工位", "技能系数", "企业工龄", "工龄", "入职日期",
                    "毕业日期","备注","状态"
                ]
                utils.sheet_add_aoa(worksheet1, [fixedHeader], { origin: "A1" });
                /* calculate column width */
                worksheet1["!cols"] = [ { wch: 4}, { wch: 8},{ wch: 4},{ wch: 8},{ wch: 8},
                                        { wch: 4},{ wch: 10}, { wch: 10},{ wch: 13},{ wch: 4}];

                let skillYears = []
                const codes = []
                let skillRateData = []
                data[1].forEach((d, id) => {
                    if (!skillYears.includes(d.year)) skillYears.push(d.year)
                    if (!codes.includes(d.code)) {
                        codes.push(d.code)
                        const curEmployee = {
                            name: d.name,
                            key: d.code,
                            code: d.code,
                        }
                        curEmployee[d.year] = d.skill
                        skillRateData.push(curEmployee)
                        // console.log(id, d.year)
                    } else {
                        skillRateData[codes.indexOf(d.code)][d.year] = d.skill
                    }
                })

                const sortedSkillYears = skillYears.sort((a, b) => a - b)

                skillRateData = skillRateData.map(d => {
                    const row = [d.code, d.name]
                    sortedSkillYears.forEach(y => {
                        if (d[y]){
                            row.push(d[y])
                        }else{
                            row.push('无数据')
                        }
                    })
                    return(row)
                })

                
                const fixedHeader2 = ["工号", "姓名"].concat(sortedSkillYears)
                const worksheet2 = utils.aoa_to_sheet([fixedHeader2].concat(skillRateData))
                // console.log(skillRateData)
                utils.book_append_sheet(workbook, worksheet2, '往年技能系数')

                // return
                writeFile(workbook, "慧德员工信息.xlsx");

                resolve(1)
            }catch(e){
                console.log(e)
                resolve(0)
            }
        }, 0)
    })
}

const getEmployeeSkillRateFromName = (names) => {
    return axios.post('/api/employee/x3/', {names})
}

const uploadEmployeeAndSkillrateFromFile = (employees, employeeSkillRate) => {
    return axios.post('/api/employee/new', {
        employees, employeeSkillRate
    })
}

const downloadEmployeeProjectValuesAllXlsx = () => {
    return new Promise((resolve, reject) => {
        setTimeout(async () => {

            try{

                const res = await getEmployees()
                
                const employees = res.data.data.filter(e => e.status === 'Y')
                // console.log(employees)

                const workbook = utils.book_new()
                

                for (let employee of employees){
                    // console.log(employee)
                    const res = await getEmployeeProjectValuesArchivedByCode(employee.code)
                    const detailData = res.data.data  

                    const workSheet = getWorkSheetByNameAndDetail(employee.name, detailData)
                    utils.book_append_sheet(workbook, workSheet, employee.name)
                }

                writeFile(workbook, "产值结算汇总.xlsx");
                resolve(1)
            }catch(e){
                reject(e)
            }
        })
    })
}

const downloadEmployeeProjectValuesXlsx = (employee) => {

    return new Promise((resolve, reject) => {

        setTimeout(async () => {

            try{

                const res = await getEmployeeProjectValuesArchivedByCode(employee.code)
                const detailData = res.data.data
                // console.log(detailData)

                const workbook = utils.book_new()

                const workSheet = getWorkSheetByNameAndDetail(employee.name, detailData)

                utils.book_append_sheet(workbook, workSheet, employee.name)

                writeFile(workbook, employee.name + ".xlsx");

                resolve(1)
            }catch(e){
                reject(e)
            }
        })
    })
}

const getWorkSheetByNameAndDetail = (name, detailData) => {
    let rows = [
        ['', name],
        ['序号', '项目名称', '咨询类别', '专业', '分工', '工作内容', '完成造价', '项目产值', '员工产值V4', '附加产值V3', '质量扣除产值V5', '备注']
    ]

    let [sumA, sumV, sumV4, sumV3, sumV5] = [0, 0, 0, 0, 0]

    rows = rows.concat(detailData.map((d, index) => {
        sumA += parseInt(d.A)
        sumV += parseInt(d.v)
        sumV4 += parseInt(d.v4)
        sumV3 += parseInt(d.v3)
        sumV5 += parseInt(d.v5)

        return ([
            index, d.project_name, d.type, d.major, d.position, d.work, d.A, d.v, d.v4, d.v3, d.v5, d.comment
        ])
    }))

    rows = rows.concat([
        ['', '', '', '', '', '小计', sumA, sumV, sumV4, sumV3, sumV5],
        [],
        ['', '工资支出', '岗位工资', '技能工资', '技能产值', '技能奖', '技术培训费', '业务奖', '', '签字', '时间'],
        ['', '', '', '', sumV4]
    ])

    // console.log(rows)

    const workSheet = utils.aoa_to_sheet(rows)
    return workSheet
}


const EmployeeController = {
    getEmployees, getEmployeeSkillRate, updateEmployeeSkillRate, updateEmployee, insertEmployee,
    downloadEmployeeXlsx, getEmployeeSkillRateFromName, removeEmployee, uploadEmployeeAndSkillrateFromFile,
    getEmployeeProjectValuesArchivedByCode, downloadEmployeeProjectValuesXlsx, downloadEmployeeProjectValuesAllXlsx
}


export default EmployeeController
