import { InboxOutlined } from '@ant-design/icons';
import { message, Upload, Modal, Table } from 'antd';
import React, { useState, useRef } from 'react';
import { useContext } from 'react';
import { UserContext } from '../../../AppLayout';
import ProjectController from '../../../utils/project';
import { withNotification } from '../../../utils/notification'
const { Dragger } = Upload;

const ProjectUploadMultipleModal = (props) => {
    const user = useContext(UserContext)

    const { visible, setVisible, refresh } = props

    const [uploadFileList, setUploadFileList] = useState([])

    const [confirmLoading, setConfirmLoading] = useState(false)

    const [ loading, setLoading ] = useState(false)
    
    const [data, setData] = useState([])

    const projects = useRef([])


    const MAJOR = ['建筑', '安装', '装修', '土建']

    const onChange = (info) => {
        console.log(info.file.status)
        setLoading(true)
        const { status, response } = info.file;

        setUploadFileList(info.fileList)

        if (status !== 'uploading') {
            console.log(info.file, info.fileList);
        }

        if (status === 'done' && info.fileList.length > 0) {
            if (response.code === -1){
                message.error(`用户未登录`);
                window.location = '/login'
                return
            }
            setUploadFileList(info.fileList)
            // console.log(response.data.tempFilePath)
            message.success(`${info.file.name} 文件上传成功.`);
            parseRows(response.rows)
        } else if (status === 'error') {
            message.error(`${info.file.name} 文件上传失败.`);
            setUploadFileList([])
        }

        if (status === 'done' || status === 'error' || status === 'removed'){
            setTimeout(()=>{
                setLoading(false)
            }, 0)
        }
    }

    const parseRows = (rows) => {
        // console.log(rows)

        // newRec: project summary data

        const curProjects = []

        const getEmptyProject = () => {
            return {
                info:{
                    name: '',
                    type: '',
                    code: '',
                    fee: '',
                    fee_collected: '',
                    travel_fee: '',
                    fill_person: '',
                },
                records: []
            }
        }

        let newProject = getEmptyProject();

        let curMajor, curPosition
        let start = false

        for (let r of rows){
            // console.log(r)
            const majorRead = r[1]
            const posRead = r[2]
            if (r[0] === "项目名称：" || (typeof(r[0]) === 'string' && r[0].includes("项目名称"))){
                newProject.info.name = r[2] || ''
                newProject.info.type = r[5] || ''
                newProject.info.code = r[7] || ''
                newProject.info.start_date = r[9] || ''
                newProject.info.end_date = r[11] || ''
            }else if(majorRead === "项目负责人"){
                newProject.info.owner = r[4] || '' 
                newProject.records.push({
                    major: '',
                    position: r[1],
                    work: r[3],
                    name: r[4],
                    A: r[5],
                    b: r[6] || 0,
                    x1: r[8],
                    x2: r[9],
                    x5: r[10],
                    hours: r[11],
                    comment: r[12]
                })
            }else if (r[0] === '咨询费：'|| (typeof(r[0]) === 'string' && r[0].includes("咨询费"))){
                newProject.info.fee = r[3] || 0
                newProject.info.fee_collected = r[5] || 0
                newProject.info.travel_fee = r[7] || 0
            }else if (majorRead === '小计' || majorRead === '小计（万元）' || (typeof(majorRead) === 'string' && majorRead.includes("小计"))){
                start = false
                curProjects.push(newProject)
                newProject = getEmptyProject()
                continue
            }else if (r[0] === '序号' || (typeof(r[0]) === 'string' && r[0].includes("序号"))){
                start = true
                continue
            }else if (start){
                let curRec = {}
                if (typeof(majorRead) === 'string' && majorRead.includes('审定人')){
                    curRec.major = ''
                    curRec.position = majorRead
                }else{
                    if ((typeof(majorRead) === 'string') && !majorRead.includes('审定人') && MAJOR.filter(m => majorRead.includes(m)).length > 0){
                        curMajor = majorRead
                        curPosition = posRead
                    }
                    curRec.major = majorRead || curMajor
                    curRec.position = posRead || curPosition
                }
                curRec = {
                    ...curRec,
                    work: r[3],
                    name: r[4],
                    A: r[5],
                    b: r[6] || 0,
                    x1: r[8],
                    x2: r[9],
                    x5: r[10],
                    hours: r[11],
                    comment: r[12]
                }
                newProject.records.push(curRec)
            }
        }
        
        
        const data = curProjects.map((p,index)=> {return {...p.info, key: index, dataSize: p.records.length}})
        console.log(curProjects, data)
        setData(data)
        projects.current = curProjects
        // setNewRecord({...newRec})
        // records.current = newRecords
    }

    const onRemove = () => {

        setUploadFileList([])
        setData([])
    }

    const handleOk = () => {
        // setVisible(false)
        const projectsUpload = projects.current

        setConfirmLoading(true)
        console.log(projectsUpload)

        try{
            // validate project data
            let isValid = projectsUpload.length > 0
            const invalidProjectNames = []

            projectsUpload.forEach(p => {

                if (p.info.name.length === 0){
                    message.error("存在为空的项目名称，请检查！")
                    isValid = false
                    setConfirmLoading(false)
                    return
                }
                if (p.records.length < 0 || p.info.code.length === 0){
                    invalidProjectNames.push(p.info.name)
                    isValid = false
                }
            })

            if (!isValid){
                let errMessage = "存在有问题项目："
                invalidProjectNames.forEach(pName => {
                    errMessage += pName + ','
                })
                errMessage += " 可能缺少项目编号或具体项目数据!"
                message.error(errMessage)
                setConfirmLoading(false)
                return
            }


            // if project data is valid

            setTimeout(async () => {
                try{

                    let size = projectsUpload.length
                    let start = 0
                    const THRESHOLD_SIZE_TOTAL = 200
                    const packageSize = 10
                    while (start < size) {
                        let end
                        if (start + packageSize < size) {
                            end = start + packageSize
                        } else {
                            end = size
                        }
                        
                        let curProjectsUpload = projectsUpload.slice(start, end)

                        let totalPackageSize = 0;
                        curProjectsUpload.forEach(p => totalPackageSize += p.records.length)

                        if (totalPackageSize > THRESHOLD_SIZE_TOTAL){
                            end = start + 1
                            curProjectsUpload = projectsUpload.slice(start, end)
                            start += 1
                        }else{
                            start += packageSize
                        }

                        console.log(end, "length", curProjectsUpload.length)
                        const showNotification = end === size
                        await withNotification(ProjectController.addMultiNewProject(curProjectsUpload), "成功添加多个新项目", showNotification)
                        
                    }
                    
                    handleCancel()
                }catch(e){
                    message.error(e.message || '添加多个新项目出现问题，请检查')
                }finally{
                    refresh()
                }
                setConfirmLoading(false)
            })
        }catch(e){
            message.error(e.message)
            setConfirmLoading(false)
        }

    }

    const handleCancel = () => {
        setVisible(false)
        projects.current = []
        setUploadFileList([])
        setData([])
        setLoading(false)
        setConfirmLoading(false)
    }

    const columns = [
        {
            title: '项目编号',
            dataIndex: 'code',
        },
        {
            title: '项目名称',
            dataIndex: 'name',
            width:500
        },
        {
            title: '数据量',
            dataIndex: 'dataSize',
        },
        {
            title: '负责人',
            dataIndex: 'owner',
        },
        {
            title: '咨询类别',
            dataIndex: 'type',
        },
        {
            title: '咨询费',
            dataIndex: 'fee',
        },
        {
            title: '已收',
            dataIndex: 'fee_collected',
        },
        {
            title: '应收',
            dataIndex: 'rest_fee',
        },
        {
            title: '差旅费',
            dataIndex: 'travel_fee',
        }
    ]

    return (
        <Modal
                title={"一次性上传多个项目"}
                visible={visible}
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
                okText={"上传"}
                cancelText="取消"
                width={1500}
            >

            <Dragger 
                accept='.xlsx'
                name='project'
                multiple={false}
                action={'/api/project/upload/'}
                onDrop={(e)=>{
                    console.log('Dropped files', e.dataTransfer.files);
                }}
                onChange={onChange}
                onRemove={onRemove}
                maxCount={1}
                fileList={uploadFileList}
                >
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                    点击此处或将文件拖拽至此处
                    <br />
                    上传项目产值分配表
                </p>
                <p className="ant-upload-hint">
                    仅上传.xlsx文件
                </p>
            </Dragger>


            <Table 
                loading={loading}
                columns={columns}
                dataSource={data}
            />

            </Modal>
        
    )
};

export default ProjectUploadMultipleModal;