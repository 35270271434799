import './../pages.css';
import '../../App.css';
import { Table, Select, Input, Typography, message, Tooltip  } from 'antd';
import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'antd';
import { PlusOutlined, EditOutlined, DownloadOutlined, ReloadOutlined } from '@ant-design/icons';
import ProjectController from '../../utils/project'
import { getTableScroll } from '../../utils/calculateHeight';
import ProjectEditModal from './component/ProjectEditModal'
import ProjectUploadMultipleModal from './component/ProjectUploadMultipleModal';
import { withNotification } from '../../utils/notification';
import { UserContext } from '../../AppLayout';
import { useContext } from 'react';

const { Text, Link } = Typography;
const { Option } = Select;
const { Search } = Input;

const ellipsisStyle = {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: '200px'
}


const ProjectsPage = () => {

    const user = useContext(UserContext)

    const [visible, setVisible] = useState(false)
    const [showMultipleUpload, setShowMultipleUpload] = useState(false)

    const selectType = useRef('all')
    const searchValue = useRef('')
    const [searchLoading, setSearchLoading] = useState(false)

    const [tableHeight, setTableHeight] = useState('')
    const [loading, setLoading] = useState(true)
    const [projectsRender, setProjectsRender] = useState([])
    const [record, setRecord] = useState('')
    const [isNew, setIsNew] = useState(false)

    const [readOnly, setReadOnly] = useState(true)
    const [fullAccess, setFullAccess] = useState(false)

    const [columns, setColumns] = useState([])

    useEffect(() => {
        loadData()
    }, [])

    const loadData = () => {
        setLoading(true)
        return new Promise((resolve, reject) => {
            setTimeout(async () => {
                try{
                    const res = await withNotification(ProjectController.getProjects(), '', false)
                    const data = res.data.map(d => {d.key = d.uuid; return d})
                    console.log(data)
                    if (selectType.current === 'all'){
                        setProjectsRender(data.filter(p => (JSON.stringify(p)).includes(searchValue.current)))
                    }else{
                        setProjectsRender(data.filter(p => (p[selectType.current]).includes(searchValue.current)))
                    }
                    setTableHeight(getTableScroll({extraHeight:0, id:'projects-table'}))
                    setLoading(false)
                }catch(e){
                    console.log(e, e.message)
                    message.error(e.message)
                }finally{
                    resolve(1)
                }
            }, 0);
        })
    }

    const onSearch = (value) => {
        setSearchLoading(true)
        setLoading(true)
        console.log(selectType.current, value)
        searchValue.current = value
        setTimeout(async () => {
            await loadData()
            setSearchLoading(false)
        })
    };

    const columns1 = [
        {
            title: '项目编号',
            width: 50,
            ellipsis: true,
            dataIndex: 'code',
            fixed: 'left',
            sorter: (a, b) => a.code.localeCompare(b.code),
            // sortDirections: ['descend'],
            onCell: (record) => {
                const cell = {style:{backgroundColor: '#f8ffea'}}
                if (!record.archive_status){
                    cell.style.backgroundColor = '#fff6f8'
                }
                return cell
            }
        },
        {
            title: '项目名称',
            dataIndex: 'name',
            width: 100,
            fixed: 'left',
            ellipsis: {expandable: true},
            sorter: (a, b) => a.name.localeCompare(b.name),
            render: (text, record) => <Tooltip title={text}> <div style={ellipsisStyle}> <Link style={{color: record.archive_status? '#4eb31d' : '#ff4d4f'}} href={'/project/' + record.code} target="_blank"> {text} </Link> </div> </Tooltip>,
            onCell: (record) => {
                const cell = {style:{backgroundColor: '#f8ffea'}}
                if (!record.archive_status){
                    cell.style.backgroundColor = '#fff6f8'
                }
                return cell
            }
        },
        {
            title: '负责人',
            dataIndex: 'owner',
            width: 30,
            ellipsis: true,
        },
        {
            title: '咨询类别',
            dataIndex: 'type',
            width: 50,
            ellipsis: true,
        },
    ]

    const columnsFee = [
        {
            title: '咨询费',
            dataIndex: 'fee',
            width: 50,
            onCell: () => ({style:{backgroundColor: '#f4f8de'}})
        },
        {
            title: '已收',
            dataIndex: 'fee_collected',
            width: 50,
            onCell: () => ({style:{backgroundColor: '#f1f4f8'}})
        },
        {
            title: '应收',
            key: 'rest_fee',
            width: 50,
            render: (_, record, index) => parseFloat(record.fee) - parseFloat(record.fee_collected),
            onCell: () => ({style:{backgroundColor: '#f8f1f1'}})
        },
        {
            title: '成本比',
            dataIndex: 'cost_ratio',
            width: 30,
            render: (text, record) => text ? text + '%' : ''
        },
        {
            title: '产值系数(万分)比',
            dataIndex: 'project_value_co_ratio',
            width: 60,
            render: (text, record) => text ? text + '%' : ''
        },
        {
            title: '总完成造价(万)',
            dataIndex: 'total_A',
            width: 60,
            ellipsis: true,
            // render: (text, record) => record.total_A ? (record.total_A / 10000).toFixed(2) : ''
        },
        {
            title: '总审减额(万)',
            dataIndex: 'total_b',
            width: 50,
            ellipsis: true,
            render: (text, record) => record.total_A ? (record.total_b / 10000).toFixed(2) : ''
        },
        {
            title: '总员工产值V1',
            dataIndex: 'total_v1',
            width: 60,
            ellipsis: true,
        },
        {
            title: '总员工产值V2',
            dataIndex: 'total_v2',
            width: 60,
            ellipsis: true,
        },
        {
            title: '总员工产值V4',
            dataIndex: 'total_v4',
            width: 60,
            ellipsis: true,
        },
        {
            title: '总质量扣除产值V5',
            dataIndex: 'total_v5',
            width: 70,
            ellipsis: true,
        },
    ]

    const columns2 = [
        {
            title: '差旅费',
            dataIndex: 'travel_fee',
            width: 50,
            ellipsis: true,
        },
        {
            title: '修改人',
            dataIndex: 'fill_person',
            width: 40,
        },
        {
            title: '上传/修改时间',
            dataIndex: 'fill_time',
            width:70,
            sorter: (a, b) => a.fill_time.localeCompare(b.fill_time),
            defaultSortOrder: 'descend'
        },
        {
            title: '归档状态',
            dataIndex: 'archive_status',
            width: 50,
            render: (text) => text === 0? '未归档' : '已归档',
            filters: [
                {
                    text: '已归档',
                    value: 1,
                },
                {
                    text: '未归档',
                    value: 0,
                },
            ],
            onFilter: (value, record) => record.archive_status === value,
            // defaultFilteredValue: [0, 1],
            // filterResetToDefaultFilteredValue: true, // 点击重置按钮时，恢复默认值
            // filtered: true
        },
    ]

    const columnEdit = [
        {
            title: '编辑',
            key: 'projects-edit-btn',
            fixed:'right',
            width: 20,
            render: (text, record, index) => <Link disabled={(user.access.employee_page_edit_all === 1)? false : record.archive_status !== 0 || (record.owner !== user.name)} onClick={() => editClick(record)}> <EditOutlined /> </Link>
        }
    ]

    const editClick = (record) => {
        // console.log(record)
        setRecord(record)
        setVisible(true)
        setIsNew(false)
    }

    const onChange = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
    };

    const onSave = () => {
        setLoading(true)
        loadData()
        // setLoading(false)
    }


    useEffect(() => {

        let col = columns1
        let showFee = false
        let showEdit = false
        if (user.access) {
            if (user.access.project_page_edit_own === 1){
                setReadOnly(false)
                showEdit = true
            }

            if (user.access.project_page_edit_all === 1){
                showFee = true
                setFullAccess(true)
            }
        }

        if (showFee){
            col = col.concat(columnsFee)
        }
        col = col.concat(columns2)
        if (showEdit){
            col = col.concat(columnEdit)
        }
        // console.log(user.access.project_page_edit_own, col)
        setColumns(col)
    }, [])

    return(
        <>
        <div className="site-layout-content" style={{ width: "100%"}}>
            <div style={{display:'flex'}}>
                <Input.Group compact>
                    <Select defaultValue={selectType.current} onChange={(s) => selectType.current = s}>
                        <Option value="name">项目名称</Option>
                        <Option value="code">项目编号</Option>
                        <Option value="owner">负责人</Option>
                        <Option value="type">咨询类别</Option>
                        <Option value="all">全部类别</Option>
                    </Select>
                    <Search placeholder="搜索" allowClear onSearch={onSearch}  enterButton loading={searchLoading}
                        style={{ width: "45%", margin:'0 1rem 1rem 0' }} />
                    <Button onClick={loadData} type='ghost'>
                        <ReloadOutlined />
                    </Button>
                </Input.Group>

                <div style={{display:'flex', alignItems:'center', width:'20rem', paddingBottom:'0rem', marginLeft:'0rem'}}>

                    <div style={{width:'1.7rem', height:'1.7rem', background:'#f1ffd3', margin:'-1rem .5rem 0 0'}}>
                        
                    </div>
                    <p style={{width:'4rem', marginTop:'-5px'}}> 已归档 </p>

                    <div style={{width:'1.7rem', height:'1.7rem', background:'#ffcbd5', margin:'-1rem .5rem 0 0'}}>
                       
                    </div>
                    <p style={{width:'4rem', marginTop:'-5px'}}>未归档</p>

                    
                </div>

                {
                    fullAccess &&
                    <Button type="primary" style={{marginRight:'1rem'}}
                        onClick={() => {
                            // setIsNew(true)
                            setShowMultipleUpload(true)
                        }}>
                        <PlusOutlined/> 导入多个项目
                    </Button>
                }    

                {
                    !readOnly &&
                    <Button type="primary"
                        onClick={() => {
                            setIsNew(true)
                            setVisible(true)
                        }}>
                        <PlusOutlined/> 添加
                    </Button>
                }                

            </div>

            <Table bordered
                id='projects-table'
                size='middle'
                loading={loading}
                columns={columns}
                dataSource={projectsRender} 
                // onChange={onChange} 
                scroll={{
                    x: readOnly? 600 : fullAccess? 2500 : 1000,
                    y: tableHeight
                }}
                pagination={{defaultPageSize:20, showSizeChanger: true}}
            />
            <ProjectEditModal fullAccess={fullAccess} visible={visible} setVisible={setVisible} record={record} onSave={onSave} isNew={isNew}/>
            <ProjectUploadMultipleModal visible={showMultipleUpload} setVisible={setShowMultipleUpload} refresh={loadData} />
        </div>
        
        </>
      );
}

export default ProjectsPage;