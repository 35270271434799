import './pages.css'
import '../App.css';
import { Button, Input, Space, Spin, message } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LoginController from '../utils/login'

function LoginPage(props){

    const [spin, setSpin] = useState(false)

    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')

    const navigate = useNavigate()

    useEffect(() => {
        console.log('检查是否已经登录')
        setSpin(true)
        setTimeout(async() => {
            try{
                const res = await LoginController.checkLogin()
                // console.log(res.data)
                if (res.data.code === 1) {
                    message.success('登录成功')
                    setTimeout(() => {
                        const prevURL = window.sessionStorage.getItem('prevURL')
                        if (prevURL && prevURL !== '/login'){
                            navigate(prevURL)
                        }else{
                            navigate('/')
                        }
                        setSpin(false)
                    }, 1000)
                }else{
                    setSpin(false)
                }
            }catch(e) {
                console.log(e, e.message)
                message.error(e.message)
            }
        }, 200)
    }, [])

    const loginClick = async () => {
        setSpin(true)
        // console.log(username, password)

        // check user input first
        const usernameTrim = username.trim()
        const passwordTrim = password.trim()
        if ((!usernameTrim || !passwordTrim) || usernameTrim.length === 0 || passwordTrim.length === 0){
            message.error('用户名或密码输入不正确')
            return
        }

        try{
            const res = await LoginController.login(username, password)
            // console.log(res)
            if (res.data.code === 0){
                message.error('用户名或密码输入不正确')
                setSpin(false)
                return
            }
            message.success('登录成功')
            // 登录成功
            setTimeout(() => {
                const prevURL = window.sessionStorage.getItem('prevURL')
                if (prevURL){
                    navigate(prevURL)
                }else{
                    navigate('/')
                }
                setSpin(false)
            }, 1200)
        }catch(e){
            message.error(e.message)
        }
    }

    return (
        <div className="App">
            <header className="my-app-header" style={{}}>
                {/* <img src={logo} className="App-logo" alt="logo" /> */}
                <Space style={{background:'#f0f2f5', padding: '3rem', boxShadow: "10px 10px 100px #888888", width:'50%'}} direction="vertical">
                    <h2>慧德产值管理系统</h2>

                    {
                        spin?
                        <div className='Spin-wrapper'>
                            <Spin size='large' style={{marginTop:'0rem'}}/>
                        </div>
                        :
                        <div style={{width: '50%', margin: '0 auto'}}>
                        <Input placeholder="用户名" onChange={ e => setUsername(e.target.value)} onKeyDown={e=>{if (e.key === 'Enter') loginClick()}}/>
                        <Input.Password  style={{marginTop:'.5rem'}}
                            placeholder="密码"
                            iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                            onChange={ e => setPassword(e.target.value)}
                            onKeyDown={e=>{if (e.key === 'Enter') loginClick()}}
                        />
                        <Button style={{marginTop:'1rem'}}
                            onClick={loginClick}
                        >
                            登录
                        </Button>
                        </div>
                    }
                </Space>
               
            </header>
        </div>
    )
}

export default LoginPage;