import React, { useContext, useEffect, useRef, useState } from 'react'
import { Table, InputNumber, Input, Button, Tooltip, Typography, message, DatePicker   } from 'antd';
import { useNavigate } from 'react-router-dom'
import { DownOutlined, UpOutlined, UploadOutlined, DownloadOutlined, EditOutlined, LockOutlined,UnlockOutlined,
        ReloadOutlined, QuestionCircleOutlined, ClearOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import ProjectDetialTable from './component/ProjectDetailTable';
import ProjectController from '../../utils/project';
import { withNotification } from '../../utils/notification';
import { getTableScroll } from '../../utils/calculateHeight';
import { FullScreenContext } from '../../AppLayout';
import ProjectEditModal from './component/ProjectEditModal';
import MessageModal from '../component/MessageModal';
import EmployeeController from '../../utils/employee';
import { HanldeResize } from '../../utils/handleWindowResize';
import { UserContext } from '../../AppLayout';
import moment from "moment";

const { RangePicker } = DatePicker;
const { Text } = Typography;

const Project = (props) => {

    const user = useContext(UserContext)

    const size = HanldeResize()
    const isFullScreen = useContext(FullScreenContext)
    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)

    // Upload Modal:
    const [showUpload, setShowUpload] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [deleteMessage, setDeleteMessage] = useState('')

    const [archiveLoading, setArchiveLoading] = useState(false)

    const [archived, setArchived] = useState(false)

    const [b, setB] = useState('')

    const [isSaveFromFile, setIsSaveFromFile] = useState(false)

    const [expand, setExpand] = useState(false)
    const [showTable2, setShowTable2] = useState(false)
    const [x3, setX3] = useState([])
 
    const [infoLoading, setInfoLoading] = useState(true)
    const [detailLoading, setDetailLoading] = useState(true)

    const [tableHeight, setTableHeight] = useState('')

    const [inEdit, setInEdit] = useState(false)

    const [infoData, setInfoData] = useState('')
    const [newInfoData, setNewInfoData] = useState({key:1})

    const detailData = useRef()
    const [newDetailData, setNewDetailData] = useState('')

    const projectCode = useRef(window.location.pathname.split('/').reverse()[0])

    const [limitedEdit, setLimitedEdit] = useState(false)
    const [fullEdit, setFullEdit] = useState(false)
    
    useEffect(() => {

        if (user.access){
            if (user.access.project_page_edit_own === 1){
                setLimitedEdit(true)
            }
            if (user.access.project_page_edit_all === 1){
                setFullEdit(true)
            }
        }

        checkArchiveStatus();
        loadProjectInfo();
        loadProjectDetail();
    }, [])

    const checkArchiveStatus = () => {
        setLoading(true)
        setTimeout(async () =>{
            try{
                const res = await withNotification(ProjectController.checkArchive(projectCode.current), '', false)
                // console.log('是否归档？', res.data)
                setArchived(res.data)
            }catch(e){
                message.error(e.message)
            }
            setLoading(false)
        })
    }

    const loadProjectInfo = () => {
        setIsSaveFromFile(false)
        setInfoLoading(true)
        // console.log('viewing project:',projectCode.current)
        return new Promise((resolve, reject) => {
            setTimeout(async() => {
                try{

                    const res = await withNotification(ProjectController.getProjects(projectCode.current), '', false)
                    const data = res.data[0]
                    // console.log(data)
                    if (!data){
                        navigate('/404')
                    }else{
                        const sumData = {
                            ...data,
                            key: data.uuid,
                        }
                        setLimitedEdit(sumData.owner === user.name)
                        setInfoData(sumData)
                        setNewInfoData({...sumData})
                        setDeleteMessage(<>确定要删除项目: <strong style={{fontSize:'bold'}}> {sumData.name} </strong> [ {sumData.code} ] ?</>)
                    }
                    resolve(1)

                }catch(e){
                    message.error(e.message)
                }finally{
                    setInfoLoading(false)
                }
            }, 0)
        })
        
    }

    const loadProjectDetail = () => {
        setDetailLoading(true)
        return new Promise((resolve, reject) => {
            setTimeout(async () => {
                try{
                    const res = await withNotification(ProjectController.getProjectValue(projectCode.current), '', false)
                    const majors = []
                    const positions = []
                    const data = res.data.map(d => {
                        d.key = d.uid;
                        if (!majors.includes(d.major)){
                            majors.push(d.major)
                            d.majorMerge = false
                        }else{
                            d.majorMerge = true
                        }
                        if (!positions.includes(d.major + d.position)){
                            positions.push(d.major + d.position)
                            d.positionMerge = false
                        }else{
                            d.positionMerge = true
                        }
                        return d
                    })
                    
                    detailData.current = JSON.parse(JSON.stringify(data))
                    setNewDetailData(data)
                    calcTableHeight(isFullScreen, expand, inEdit, showTable2)
                    resolve(1)
                }catch(e){
                    message.error(e.message)
                }finally{
                    setDetailLoading(false)
                }
            })
        })
    }

    const updateX3 = () => {
        setTimeout(async () => {
            try{
                const names = [...new Set(newDetailData.map(d => d.name))]
                // console.log(names)
                const res = await withNotification(EmployeeController.getEmployeeSkillRateFromName(names), '', false)
                // console.log(res)
                setX3(res.data)
            }catch(e){
                message.error(e.message)
            }finally{
                setDetailLoading(false)
            }
        }, 0)
    }

    useEffect(() => {
        calcTableHeight(isFullScreen, expand, inEdit, showTable2)
    }, [expand, isFullScreen])

    const calcTableHeight = (isFullScreen, expand, inEdit, showTable2) => {
        let extraHeight = 0
        if (inEdit){
            if (isFullScreen && !expand){
                extraHeight = 230
            }else if (isFullScreen && expand){
                extraHeight = 140
            }else if (!isFullScreen && expand){
                extraHeight = 180
            }else{
                extraHeight = 270
            }
        }else{
            if (isFullScreen && !expand){
                extraHeight = 220
            }else if (isFullScreen && expand){
                extraHeight = 140
            }else if (!isFullScreen && expand){
                extraHeight = 180
            }else{
                extraHeight = 260
            }
        }

        if (showTable2){
            extraHeight += 22
        }

        setTableHeight(getTableScroll({extraHeight, id:'project-detail-table'}))

    }

    const infoColumns = [
        {
            title:'负责人',
            dataIndex: 'owner',
            width: 60,
            render: (text) => {
                if (inEdit){
                    return (
                        <Input 
                            value={newInfoData.owner}
                            onChange={(e) => setNewInfoData({...newInfoData, owner:e.target.value})}
                            />
                    )
                }
                return text
            }
        },
        {
            title: '咨询类别',
            dataIndex: 'type',
            width: 100,
            ellipsis: true,
            render: (text) => {
                if (inEdit){
                    return (
                        <Input 
                            value={newInfoData.type}
                            onChange={(e) => setNewInfoData({...newInfoData, type:e.target.value})}
                            />
                    )
                }
                return text
            }
        },
        {
            title: '项目编号',
            width: 100,
            dataIndex: 'code',
            render: (text) => {
                if (inEdit){
                    return (
                        <Input 
                            value={newInfoData.code}
                            onChange={(e) => setNewInfoData({...newInfoData, code:e.target.value})}
                            />
                    )
                }
                return text
            }
        },
        {
            title:'差旅费',
            dataIndex: 'travel_fee',
            width:80,
            render: (text) => {

                return (
                    <div style={{display:'flex', alignItems:'center'}}>
                        {inEdit?
                            <InputNumber style={{width: '6rem'}} min={0}
                                value={newInfoData.travel_fee}
                                onChange={(value) => setNewInfoData({...newInfoData, travel_fee:value})}
                                />
                                :
                                text || 0
                        }
                    </div>
                )
            }
        },
    ]


    const infoColumnsFullEdit = [
        {
            title:'咨询费',
            dataIndex: 'fee',
            key: 'fee',
            width: showTable2? 100: 70,
            render: (text) => {

                if (inEdit){
                    return (
                        <InputNumber min={0} value={newInfoData.fee} onChange={(v) => setNewInfoData({...newInfoData, fee:v})} />
                    )
                }

                return newInfoData.fee
            }
        },
        {
            title:'已收费用',
            dataIndex: 'fee_collected',
            width:80,
            render: (text) => {
                if (inEdit){
                    return (
                        <InputNumber style={{width: '6rem'}}
                            value={newInfoData.fee_collected}
                            onChange={(value) => setNewInfoData({...newInfoData, fee_collected:value})}
                            />
                    )
                }
                return text
            }
        },
        {
            title:'应收费用',
            key: 'pendingFee',
            width:80,
            render: (_, record) => record.fee - record.fee_collected
        },
    ]

    const infoColumnsEnd = [
        {
            title: '开始日期',
            dataIndex: 'start_date',
            width: inEdit? 100:80,
            onCell: (record, index) => {
                const cell = {colSpan:inEdit?2:1}
                return cell
            },
            render: (text, record, index) => {
                if (inEdit){
                    return (
                    <RangePicker value={[record.start_date?moment(record.start_date):'', record.end_date?moment(record.end_date):'']}
                        onChange={(values, formatStr) => {
                            newInfoData.start_date = formatStr[0]
                            newInfoData.end_date = formatStr[1]
                            setNewInfoData({...newInfoData})
                        }}
                    />)
                }
                return text
            }
        },
        {
            title: '结束日期',
            dataIndex: 'end_date',
            width: 80,
            onCell: (record, index) => {
                const cell = {colSpan:inEdit?0:1}
                return cell
            },
            render: (text) => text
        },
        {
            title:'编辑人',
            dataIndex: 'fill_person',
            key: 'fill_person',
            width:60,
            render: (text) => {
                return text
            }
        },
        {
            title:'编辑时间',
            dataIndex: 'fill_time',
            width: inEdit? 150: 120
        },
    ]

    const infoColumnsForTable2 = [
        {
            title:'总工办经费',
            dataIndex: 'headOfficeBudget',
            width:80,
            render: (text) => {
                return b
            }
        },
    ]

    const saveClick = () => {
        console.log('save click')

        if (isSaveFromFile){
            setInfoLoading(true)
            setDetailLoading(true)
            setTimeout(async () => {
                try{
                    const data = await withNotification(ProjectController.addNewProject(newInfoData, newDetailData))
                    if (data.code === 1){
                        if (JSON.stringify(infoData.code) !== JSON.stringify(newInfoData.code)) {
                            navigate('/project/' + newInfoData.code)
                            projectCode.current = newInfoData.code
                        }
                    }
                    setInEdit(false)
                    loadProjectInfo();
                    loadProjectDetail()
                }catch(e){
                    message.error(e.message)
                }finally{
                    setInfoLoading(false)
                    setDetailLoading(false)
                }
            }, 0)
            return
        }
        // check if summary & detail data has changed:
        let sumChanged = false
        Object.keys(infoData).forEach(k => {
            if (JSON.stringify(infoData[k]) !== JSON.stringify(newInfoData[k])){
                sumChanged = true
            }
        })

        let changedRec = []
        detailData.current.forEach((_, index) => {
            if (JSON.stringify(detailData.current[index]) !== JSON.stringify(newDetailData[index])){
                changedRec.push(newDetailData[index])
            }
        })

        console.log('sum changed?',sumChanged, 'detail changed?',changedRec)

        setTimeout(async () => {
            try{
                const infoDataSend = {...newInfoData, fill_person: user.name,
                    fill_time: (new Date()).Format("yyyy-MM-dd HH:mm:ss")}
                if (sumChanged || changedRec.length > 0){
                    setInfoLoading(true)
                    console.log(infoDataSend)
                    await withNotification(ProjectController.updateProject(infoDataSend), '更新成功')
                    if (changedRec.length > 0){
                        setDetailLoading(true)
                        await withNotification(ProjectController.projectValuesUpdate(changedRec), '更新成功', false) // only show notification when sum not updated
                    }
                }
                if (JSON.stringify(infoData.code) !== JSON.stringify(newInfoData.code)) {
                    navigate('/project/' + newInfoData.code)
                    projectCode.current = newInfoData.code
                }
                setInEdit(false)
                loadProjectInfo();
                loadProjectDetail()
            }catch(e){
                message.error(e.message)
            }
            
        }, 0)
    }

    const editClick = () => {
        console.log('edit click')
        calcTableHeight(isFullScreen, expand, !inEdit, showTable2)
        setInEdit(true)
    }

    const cancelClick = () => {
        console.log('cancel click')
        setNewDetailData(JSON.parse(JSON.stringify(detailData.current)))
        setNewInfoData({...infoData})
        // setNewDetailData({...detailData.current})
        calcTableHeight(isFullScreen, expand, !inEdit, showTable2)
        setInEdit(false)
        setIsSaveFromFile(false)
    }

    const onUploadModalSave = (newInfo) => {
        setInfoLoading(true)
        setDetailLoading(true)

        // console.log(JSON.stringify(infoData.code), newInfo,  JSON.stringify(newInfo.code))
        if (JSON.stringify(infoData.code) !== JSON.stringify(newInfo.code)){
            navigate('/project/' + newInfo.code)
            projectCode.current = newInfo.code
        }

        console.log('ddd')

        loadProjectInfo();
        loadProjectDetail()
    }

    const [deleteLoading, setDeleteLoading] = useState(false)
    const removeProjectClick = () => {
        setInfoLoading(true)
        setDetailLoading(true)
        setDeleteLoading(true)
        setDeleteMessage('正在删除项目：' + infoData.code)
        console.log('removing project: ', infoData.uuid)
        setTimeout(async () => {
            try{
                await withNotification(ProjectController.removeProject(infoData.uuid), '已经成功删除项目: ' + infoData.code)
                setDeleteMessage('该项目已经成功被删除')
                setTimeout(() => {
                    window.location.reload()
                }, 5000)
            }catch(e){
                message.error(e.message)
            }finally{
                setInfoLoading(false)
                setDetailLoading(false)
                setDeleteLoading(false)
            }
            
        }, 0)
    }

    const downloadClick = () => {
        console.log('download clicked')
        setTimeout(async () => {
            try{
                const res = await ProjectController.downloadProject(infoData.code)
            }catch(e){
                // console.log(e)
                message.error(e.message)
            }
        }, 0)
    }

    const updateRecordsFromFile = (info, newRecords) => {
        // console.log('DDDDD', info, newRecords, infoData, newDetailData)
        console.log(newRecords)
        setIsSaveFromFile(true)
        const majors = []
        const positions = []
        const data = newRecords.map((d, index) => {
            d.key = index;
            if (!d.major || !majors.includes(d.major)){
                majors.push(d.major)
                d.majorMerge = false
            }else{
                d.majorMerge = true
            }

            if (!positions.includes(d.major + d.position)){
                positions.push(d.major + d.position)
                d.positionMerge = false
            }else{
                d.positionMerge = true
            }

            return d
        })
        console.log(data)
        setNewInfoData(info)
        setNewDetailData(data)
        setInEdit(true)
        calcTableHeight(isFullScreen, expand, true, showTable2)
    }

    const showTable2Click = () => {
        calcTableHeight(isFullScreen, expand, inEdit, !showTable2)
        if (!showTable2){
            setDetailLoading(true)
            updateX3()
        }
        setShowTable2(!showTable2)
    }

    const reloadClick = () => {
        setInfoLoading(true)
        setDetailLoading(true)
        checkArchiveStatus()
        setTimeout(async () => {
            await Promise.all([loadProjectInfo(), loadProjectDetail()])
            if (showTable2){
                updateX3()
            }
        }, 0)
    }

    const archiveClick = () =>{
        
        setArchiveLoading(true)
        setTimeout(async () => {
            try{
                if (archived){
                    await withNotification(ProjectController.unArchiveProject(projectCode.current), '', false)
                    message.success('成功解除存档！')
                }else{
                    // 如果有办公室或管理员权限，可以直接绕过签字检查，直接归档
                    if(!user.access.project_page_edit_all){
                        // 检查是否全员都sign了, Note: local check might not be 100% correct
                        let allSigned = true
                        newDetailData.forEach(r => {
                            if (r.uid && r.signed !== 'Y'){
                                allSigned = false
                            }
                        })
                        if (!allSigned){
                            message.error('有员工未签字确认, 请检查并确认所有人都签字！')
                            setArchiveLoading(false)
                            return
                        }
                    }

                    await withNotification(ProjectController.archiveProject(projectCode.current), '', false)
                    message.success('成功归档！')
                }
                setArchived(!archived)
            }catch(e){
                message.error(e.message)
            }
            setArchiveLoading(false)
        })
        
    }
    

    return (
        <div>
            <div>
            <div style={{display:'flex', justifyContent:'space-between', marginLeft:'0rem', width: '100%'}}>

                <div style={{display:'flex', width: inEdit?'78%':'58%'}}>
                    {
                        fullEdit && 
                        <Button style={{margin:'0.3rem 1rem 0 0'}} onClick={showTable2Click} type='ghost' size='small'>
                            {showTable2? 
                                size.width > 1200? '产值分配表 2' : '表 2'
                                : 
                                size.width > 1200? '产值分配表 1' : '表 1'}
                        </Button>
                    }

                    <div style={{margin: 'auto 0rem', width:'100%', overflowX:'hidden'}}>
                    {
                        inEdit?

                        <Input
                            // bordered={false}
                            style={{width: '100%'}}
                            value={newInfoData.name}
                            onChange={(e) => setNewInfoData({...newInfoData, name:e.target.value})}
                            />
                        :
                        <Text
                            style={{ width: '100%'}}
                            ellipsis={{ tooltip: newInfoData.name}}
                            copyable
                        >
                            {newInfoData.name}
                        </Text>
                        
                        
                    }
                    </div>
                </div>

                    {
                        (limitedEdit || fullEdit ) ? 
                        <div style={{margin: '.3rem'}} >
                            <Button style={{margin:'auto'}} type='ghost' size='small'
                                    onClick={reloadClick}>
                                    <Tooltip placement="bottom" title={'刷新项目'}>
                                    <ReloadOutlined />
                                    </Tooltip>
                            </Button>
                            
                            <Button style={{margin: 'auto 1rem', background:'#d28cb2', borderColor:'#d28cb2'}} 
                                    type='primary' size='small'
                                    onClick={()=> setShowDeleteModal(true)} loading={deleteLoading}>
                                        <Tooltip placement="bottom" title={'删除'}>
                                            <ClearOutlined />
                                        </Tooltip>
                            </Button>

                            {
                                !inEdit &&
                                <>
                                <Button style={{margin:'auto'}} size='small' disabled={archived}
                                        onClick={() => {setShowUpload(true)}} type='default' >
                                    {
                                        size.width > 1200?
                                        <UploadOutlined />
                                        :
                                        <Tooltip placement="bottom" title={'上传'}>
                                            <UploadOutlined />
                                        </Tooltip>
                                    }
                                
                                    {
                                        size.width > 1200 && '上传'
                                    }
                                </Button>
                                <Button style={{margin:'auto 0.5rem'}} onClick={downloadClick} type='default' size='small'>
                                    {
                                        size.width > 1200?
                                        <DownloadOutlined />
                                        :
                                        <Tooltip placement="bottom" title={'下载'}>
                                            <DownloadOutlined />
                                        </Tooltip>
                                    }
                                    {
                                        size.width > 1200 && '下载'
                                    }
                                </Button>
                                </>
                            }
                            <Button style={{marginRight:'1rem'}} size='small'
                                onClick={() =>{
                                    if (!expand){
                                        setInfoLoading(true)
                                        setTimeout(() => {
                                            calcTableHeight(isFullScreen, !expand, inEdit, showTable2)
                                            setExpand(!expand)
                                            setInfoLoading(false)
                                        }, 100)
                                    }else{
                                        calcTableHeight(isFullScreen, !expand, inEdit, showTable2)
                                        setExpand(!expand)
                                    }
                                }} type='default'>
                                {expand? 
                                    <Tooltip placement="bottom" title={'展开'}>
                                            <DownOutlined />
                                    </Tooltip>
                                    :
                                    <Tooltip placement="bottom" title={'收起'}>
                                            <UpOutlined />
                                    </Tooltip>
                                    }
                            </Button>
                            {
                                !inEdit && 
                                <Button disabled={loading} loading={archiveLoading} style={{marginRight:'.5rem', background:'#d07070', color:'white'}} size='small' onClick={archiveClick}>
                                    { archived? 
                                        <>
                                        <UnlockOutlined /> 解除归档
                                        </>
                                        :
                                        <>
                                        <LockOutlined /> 归档
                                        </>
                                    } 
                                </Button>
                            }
                            
                            <Button disabled={archived || loading} onClick={()=>inEdit?saveClick():editClick()} type='primary' size='small'>
                                {inEdit? 
                                    '保存':
                                    <>
                                    <EditOutlined style={{marginRight:'0.5rem'}} />
                                    {
                                        size.width > 1200 && '编辑'
                                    }
                                    {
                                        size.width > 1200 && 
                                        <Tooltip placement="left" title={'编辑仅限于产值调整, 更多修改请下载文件后再次上传'}>
                                            <QuestionCircleOutlined  style={{marginLeft:'0.3rem'}} />
                                        </Tooltip>
                                    }
                                    
                                    </>}
                            </Button>
                            
                            {inEdit && 
                                <>
                                
                                <Button style={{marginLeft:'1rem'}} onClick={cancelClick} size='small'>
                                    取消
                                </Button>
                                
                                </>
                            }
                        </div>
                        :
                        <div style={{margin: '.3rem'}} >
                        <Button style={{margin:'auto'}} type='ghost' size='small'
                                onClick={reloadClick}>
                                <Tooltip placement="bottom" title={'刷新项目'}>
                                <ReloadOutlined />
                                </Tooltip>
                        </Button>
                        <Button style={{marginLeft:'1rem'}} size='small'
                            onClick={() =>{
                                if (!expand){
                                    setInfoLoading(true)
                                    setTimeout(() => {
                                        calcTableHeight(isFullScreen, !expand, inEdit, showTable2)
                                        setExpand(!expand)
                                        setInfoLoading(false)
                                    }, 100)
                                }else{
                                    calcTableHeight(isFullScreen, !expand, inEdit, showTable2)
                                    setExpand(!expand)
                                }
                            }} type='default'>
                            {expand? 
                                <Tooltip placement="bottom" title={'展开'}>
                                        <DownOutlined />
                                </Tooltip>
                                :
                                <Tooltip placement="bottom" title={'收起'}>
                                        <UpOutlined />
                                </Tooltip>
                                }
                        </Button>
                        </div>
                    }

                
            </div>
            <div>
                {/* the top expandable summary table */}
                {
                    !expand &&
                    <Table 
                        key={'project-summary-table'}
                        loading={infoLoading}
                        columns={((showTable2)? 
                                    infoColumns.concat(infoColumnsFullEdit).concat(infoColumnsForTable2)
                                    : 
                                    fullEdit || limitedEdit? 
                                        infoColumns.concat(infoColumnsFullEdit).concat(infoColumnsEnd) 
                                        :
                                        infoColumns.concat(infoColumnsEnd))}
                        dataSource={[newInfoData]}
                        bordered
                        size='small'
                        pagination={false}
                        scroll={{
                            x: showTable2? 1200 : fullEdit || limitedEdit? (inEdit? 1500 : 1200) : 500
                        }}
                    />
                }

            </div>
            <div>
                <ProjectDetialTable  {...{tableHeight, newInfoData, archived, newDetailData, detailData, setB,
                                            setNewDetailData, detailLoading, x3, inEdit, showTable2}} />
            </div>
        </div>

        <ProjectEditModal visible={showUpload} setVisible={setShowUpload} record={infoData} onSave={onUploadModalSave} isNew={false} setCurrentRecords={updateRecordsFromFile}/>
        <MessageModal okType='danger' visible={showDeleteModal} setVisible={setShowDeleteModal} title={'确认删除项目'} message={deleteMessage} handleConfirm={removeProjectClick} />

        </div>
    )
}

export default Project;
