import { InboxOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';
import React, { useState } from 'react';

const { Dragger } = Upload;

const EmployeeUpload = (props) => {

    const { uploadFileList, setUploadFileList, uploadLoading, setUploadLoading, setUploadData, columnYears } = props

    const onRemove = (file) => {
        setUploadFileList([])
    }

    const onChange = (info) => {
        // console.log(info.file.status)
        setUploadLoading(true)
        const { status, response } = info.file;

        setUploadFileList(info.fileList)

        if (status === 'done' && info.fileList.length > 0) {
            if (response.code === -1){
                message.error(`用户未登录`);
                window.location = '/login'
                return
            }
            setUploadFileList(info.fileList)
            // console.log(response.data)
            message.success(`${info.file.name} 文件上传成功.`);
            parseRows(response.rows)
        } else if (status === 'error') {
            message.error(`${info.file.name} 文件上传失败.`);
            setUploadFileList([])
        }
        setTimeout(()=>{
            setUploadLoading(false)
        }, 500)
    }

    const parseRows = (data) => {
        console.log(data)
        const sheet1 = []
        const sheet2 = []


        for (let i = 1; i < data[0].length; i++){
            const row = data[0][i]
            sheet1.push({
                key: 'employee-' + i,
                code: row[0],
                name: row[1],
                position_code: row[2],
                department: row[3],
                x3: row[4],
                workingAge: row[5],
                gradAge: row[6],
                entry_date: row[7],
                graduation_date: row[8],
                comment: row[9] || '',
                status: row[10] === '离职'? 'N': 'Y'
            })
        }

        let years = []
        if (data[1] && data[1][0]){
            years = data[1][0].slice(2, data[1][0].length)
        }

        for (let i = 1; i < data[1].length; i++){
            const row = data[1][i]
            const rowData = {
                key: 'x3-history-' + i,
                code: row[0],
                name: row[1],
            }
            years.forEach((y, index) => {
                rowData[y] = row[2 + index]
            })

            sheet2.push(rowData)
        }

        columnYears.current = years

        setUploadData({
            sheet1, sheet2
        })

    }

    return (
        <Dragger 
            accept='.xlsx'
            name='employee'
            multiple={false}
            action={'/api/employee/upload/'}
            onDrop={(e)=>{
                console.log('Dropped files', e.dataTransfer.files);
            }}
            onChange={onChange}
            onRemove={onRemove}
            maxCount={1}
            fileList={uploadFileList}
            disabled={uploadLoading}
            >
            <p className="ant-upload-drag-icon">
                <InboxOutlined />
            </p>
            <p className="ant-upload-text">
                点击此处或将文件拖拽至此处
                <br />
                上传员工信息表
            </p>
            <p className="ant-upload-hint">
                仅上传.xlsx文件
            </p>
        </Dragger>
    )
};

export default EmployeeUpload;