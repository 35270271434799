import axios from 'axios'
import { message } from 'antd'

const login = (username, password) => {
    return axios.post('/api/auth/login', {username, password})
}

const checkLogin = () => {
    return axios.get('/api/')
}

const toLoginPage = (navigate) => {
    console.log('No user token found, will redirect to login page')
    message.info('请登录')
    setTimeout(()=> {
        navigate('/login')
    }, 1500)
}

const logout = () => {
    return axios.get('/api/auth/logout')
}

const updateUsername = (code, username) => {
    return axios.post('/api/auth/username', {code, username})
}

const updatePassword = (oldPwd, newPwd) => {
    return axios.post('/api/auth/pwd', {oldPwd, newPwd})
}

const updatePasswordByCode = (code, newPwd) => {
    return axios.post('/api/auth/pwd-by-code', {code, newPwd})
}

const getUsers = () =>{
    return axios.get('/api/auth/users')
}

const LoginController = {
    login, checkLogin, toLoginPage, logout, getUsers,
    updatePassword, updatePasswordByCode, updateUsername
}

export default LoginController;