import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import { ConfigProvider } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import 'moment/locale/zh-cn';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

  // StrictMode here renders component twice (only in dev mode)
  // <React.StrictMode>
    <ConfigProvider locale={zhCN}>
    <BrowserRouter >
        <App />
    </BrowserRouter>
    </ConfigProvider>
  // </React.StrictMode>
);
