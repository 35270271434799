import React, { useEffect, useRef, useState } from "react";
import { InputNumber, Table, Input, Checkbox, message, Tooltip } from "antd";
import { QuestionCircleOutlined } from '@ant-design/icons';
import './projectDetailTable.css'
import MessageModal from "../../component/MessageModal";
import ProjectValuesCalculator from "../../../utils/calculateProjectValues";
// import { UserContext } from "../../../AppLayout";
// import { useContext } from 'react';
import ProjectController from "../../../utils/project";

const { TextArea } = Input;

const ProjectDetialTable = (props) => {

    // const user = useContext(UserContext)
    
    const { newInfoData, archived, tableHeight, newDetailData, setNewDetailData, detailData, setB,
        detailLoading, x3, inEdit, showTable2} = props

    const [showSummary2, setShowSummary2] = useState(false)

    // message modal
    const [visible, setVisible] = useState(false)
    const [confirmMessage, setConfirmMessage] = useState('')
    const [confirmLoading, setConfirmLoading] = useState(false)
    const [okText, setOkText] = useState('确定数据正确')
    const [okType, setOkType] = useState('primary')

    const checkIndex = useRef()
    const handleConfirm = () => {

        setConfirmLoading(true)

        setTimeout(async () => {
            const uid = newDetailData[checkIndex.current].uid
            const signed = newDetailData[checkIndex.current].signed === 'Y'? 'N' : 'Y'

            try{
                await ProjectController.signProjectDetail(uid, signed)
                newDetailData[checkIndex.current].signed = signed
                setNewDetailData([...newDetailData])
                const newData = detailData.current
                newData[checkIndex.current].signed = signed
                detailData.current = newData
                message.success(signed? '确认成功': '取消确认成功')
            }catch(e){
                // console.log(e)
                message.error(e.message)
            }finally{
                setConfirmLoading(false)
                setVisible(false)
            }
        })

    }

    const handleCancel = () => {
        setVisible(false)
        setConfirmLoading(false)
    }

    const [Asum, setAsum] = useState({})
    const [bsum, setBsum] = useState({})

    const [x1sum, setX1sum] = useState({})

    const specialPosition = [
        '项目负责人', '建筑专业审定人', '土建专业审定人', '安装专业审定人', '装修专业审定人', '项目成果审定人'
    ]

    const [v0sum, setV0sum] = useState({})

    const [summaryV, setSummaryV] = useState({})

    const isUpdatingComments = useRef(false)

    // 随着x3更新，计算 V1,V2, ... 小计
    useEffect(() => {
        if (showTable2 && x3.length > 0){
            const summaryV = {
                V1: 0,
                V2: 0,
                V4: 0,
                V5: 0,
            }
            // console.log('start', summaryV, x3)
            let b = 0
            newDetailData.forEach((d, idx) => {
                summaryV.V1 = summaryV.V1 + calcV1('', d, idx)
                summaryV.V2 = summaryV.V2 + calcV2('', d, idx)
                summaryV.V4 = summaryV.V4 + calcV4('', d, idx)
                summaryV.V5 = summaryV.V5 + calcV5('', d, idx)
                const v3 = calcV3('', d, idx)
                b += v3
                if (d.position === '项目成果审定人'){
                    b += v3
                }
            })
            setB((b/2).toFixed(0))
            setSummaryV({...summaryV})
            setShowSummary2(true)
            // console.log(summaryV)
        }
    }, [newDetailData, x3, newInfoData])

    // 计算表一小计, 并更新咨询费
    useEffect(() => {
        if (!showTable2){
            setShowSummary2(false)
        }

        if (!isUpdatingComments.current && newDetailData && newDetailData.length > 0){
            let [Asum, bsum, x1sum, v0sum] = [{}, {}, {}, {}]
            newDetailData.forEach(d => {
                if (d.position === '专业负责人'){
                    let sumA = 0
                    let sumb = 0
                    let sumX1 = 0
                    let countX1 = 0

                    // for table2 use
                    let sumV0 = 0
                    newDetailData.filter(r => r.major === d.major && r.position !== d.position).forEach(r => {
                        sumA += r.A
                        sumb += r.b
                        sumX1 += r.x1
                        countX1++
                        if (showTable2){
                            sumV0 += ProjectValuesCalculator.calculateV0(r.A, r.b, r.major)
                        }
                    })
                    Asum[d.major] = sumA
                    // console.log(Asum)
                    setAsum({...Asum})
                    bsum[d.major] = sumb
                    setBsum({...bsum})
                    x1sum[d.major] = {sum: sumX1, count: countX1}
                    setX1sum({...x1sum})
                    if (showTable2){
                        v0sum[d.major] = sumV0
                        setV0sum({...v0sum})
                    }
                }
            })

            // console.log(newDetailData)
        }

        if (!isUpdatingComments.current){
            isUpdatingComments.current = false
        }
    }, [newDetailData, showTable2, inEdit])

    const calcX1 = (text, record) => {
        if (!record) return 0
        let value = record.x1
        if (specialPosition.includes(record.position)){
            if (['项目负责人', '项目成果审定人'].includes(record.position)){
                let sum = 0
                let count = 0
                // 对所有编制人及专业负责人求平均
                Object.keys(x1sum).forEach(k => {
                    sum += x1sum[k].sum + (x1sum[k].sum / x1sum[k].count)
                    count += x1sum[k].count + 1
                })
                value = sum / count
            }else if(record.position.includes('专业审定人')){
                const major = record.position.split('专业审定人')[0]
                let sum = 0
                let count = 0
                Object.keys(x1sum).filter(k => k.includes(major)).forEach(m => {sum += x1sum[m].sum; count += x1sum[m].count})
                if (sum){
                    value =  sum / count
                }
            }
        }else if (record.position === '专业负责人'){
            if (x1sum[record.major]){
                value = x1sum[record.major].sum / x1sum[record.major].count
            }
        }
        let x1 = '';
        try{
            x1 = parseFloat(parseFloat(value).toFixed(2))
        }catch(e){
            console.log(e, value)
        }
        return x1
    }

    // v0 = 根据 A 和 b 计算
    const calcV0 = (text, record) => {
        if (!record){
            console.log(text)
            return 0
        }
        if (specialPosition.includes(record.position)){
            if (['项目负责人', '项目成果审定人'].includes(record.position)){
                let sum = 0
                Object.keys(v0sum).forEach(k => {
                    sum += v0sum[k]
                })
                return sum
            }else if(record.position.includes('专业审定人')){
                const major = record.position.split('专业审定人')[0]
                let sum = 0
                Object.keys(v0sum).filter(k => k.includes(major)).forEach(m => sum += v0sum[m])
                return sum
            }
            return ProjectValuesCalculator.calculateV0(record.A, record.b, record.major)
        }else if (record.position === '专业负责人'){
            return v0sum[record.major]
        }else{
            return ProjectValuesCalculator.calculateV0(record.A, record.b, record.major)
        }
    }

    // v = v0 * x1
    const calcV = (text, record) => {
        if (!record) return 0
        return parseInt((calcV0(text, record) * calcX1(text, record)).toFixed(0))
    }

    // v1 = v * x2
    const calcV1 = (text, record) => {
        if (!record) return 0
        return parseInt((calcV(text, record) * record.x2).toFixed(0))
    }

    // x3 from db
    const calcX3 = (text, record) => {
        if (!record) return 0
        const employee = x3.filter(x => x.name === record.name)[0]
        if (employee){
            return employee.x3.toFixed(2)
        }
        return 0
    }

    // x4 = 1 - x3
    const calcX4 = (text, record) => {
        if (!record) return 0
        return (1 - calcX3(text, record)).toFixed(2)
    }

    // v2 = v1 * x3, 专业负责人额外 + 负责编制人的v3之和, 审定人额外 + 负责人的v3之和
    const calcV2 = (text, record) => {
        if (!record) return 0
        let addon = 0
        if (record.position === '专业负责人'){
            newDetailData.forEach((d, idx) => {
                if (d.major === record.major && d.position !== record.position){
                    addon += parseFloat(calcV3('', d))
                }
            })
        }else if (record.position.includes('专业审定人')){
            const major = record.position.split('专业审定人')[0]
            newDetailData.filter(d => d.major.includes(major) && d.position === '专业负责人').forEach(r => {
                addon += parseFloat(calcV3('', r))
            })
        }else if (record.position === '项目成果审定人'){
            newDetailData.filter(d => d.position === '项目负责人' || d.position.includes('专业审定人')).forEach((d) => {
                addon += parseFloat(calcV3('', d))
            })
        }
        return parseInt(((calcV1(text, record) * calcX3(text, record)) + addon).toFixed(0)) //.toFixed(0))
    }

    // v3 = v1 * x4 * 0.5
    const calcV3 = (text, record) => {
        if (!record) return 0
        return parseInt((calcV1(text, record) * calcX4(text, record) * 0.5).toFixed(0))
    }

    // v4 = v2 * x5
    const calcV4 = (text, record) => {
        if (!record) return 0
        return parseInt((parseFloat(calcV2(text, record)) * record.x5).toFixed(0))
    }

    // v5 = v2 - v4
    const calcV5 = (text, record) => {
        if (!record) return 0
        return parseInt((calcV2(text, record) - calcV4(text, record)).toFixed(0))
    }

    const columnsBase = [
        {
            title: '#', // <div className="project-detail-header-cell"> 序号 </div>,
            key: 'index',
            width: 35,
            fixed: 'left',
            render: (text, rec, index) => index+1,
            onCell: (record, index) => {
                const cell = {}
                if (!specialPosition.includes(record.position)){
                    if (index % 2 === 0){
                        cell.style = {backgroundColor: 'rgb(245, 245, 245)'}
                    }                    
                }
                if (['专业负责人', '项目成果审定人'].includes(record.position)){
                    cell.style = {backgroundColor: 'rgb(230, 230, 230)'}
                }else if (['项目负责人'].includes(record.position)){
                    cell.style = {backgroundColor: '#c4febb'}
                }
                return cell
            },
            // onHeaderCell: (column) => {
            //     console.log(column)
            // }
        },
        {
            title: '专业',
            dataIndex: 'major',
            width: 45,
            fixed: 'left',
            onCell: (record, index) => {
                const cell = {}
                if (specialPosition.includes(record.position)){
                    cell.colSpan = 2
                }else{
                    cell.rowSpan = record.majorMerge ? 0 : newDetailData.filter(r=>r.major === record.major).length
                }

                if (['专业负责人', '项目成果审定人'].includes(record.position)){
                    cell.style ={ backgroundColor: 'rgb(230, 230, 230)' }
                }else if (['项目负责人'].includes(record.position)){
                    cell.style = {backgroundColor: '#c4febb'}
                }
                return cell
            },
            render: (text, record) => {
                if (specialPosition.includes(record.position)){
                    return <div style={{fontWeight:'bold'}}>{record.position}</div> 
                }else{
                    return text
                }
            },
        },
        {
            title: '分工',
            dataIndex: 'position',
            width: 90,
            fixed: 'left',
            render: (text) => {
                if (text === '专业负责人'){
                    return <div style={{fontWeight:'bold'}}>{text}</div> 
                }
                return text
            },
            onCell: (record, index) => {
                const cell = {}
                if (specialPosition.includes(record.position)){
                    cell.colSpan = 0
                }else{
                    cell.rowSpan = record.positionMerge? 0 : newDetailData.filter(r=>r.position === record.position && r.major === record.major).length
                }
                if (['专业负责人', '项目成果审定人'].includes(record.position)){
                    cell.style ={ backgroundColor: 'rgb(230, 230, 230)' }
                }else if (['项目负责人'].includes(record.position)){
                    cell.style = {backgroundColor: '#c4febb'}
                }
                return cell
            }
        },
        {
            title: '单位工程',
            dataIndex: 'work',
            width: 80,
            fixed: 'left',
            ellipsis: true,
            onCell: (record, index) => {
                const cell = {}
                if (!specialPosition.includes(record.position)){
                    if (index % 2 === 0){
                        cell.style = {backgroundColor: 'rgb(245, 245, 245)'}
                    }                    
                }
                if (['专业负责人', '项目成果审定人'].includes(record.position)){
                    cell.style = {backgroundColor: 'rgb(230, 230, 230)'}
                }else if (['项目负责人'].includes(record.position)){
                    cell.style = {backgroundColor: '#c4febb'}
                }
                return cell
            }
        },
        {
            title: '姓名',
            dataIndex: 'name',
            width: 80,
            fixed: 'left',
            onCell: (record, index) => {
                const cell = {}
                if (!specialPosition.includes(record.position)){
                    if (index % 2 === 0){
                        cell.style = {backgroundColor: 'rgb(245, 245, 245)'}
                    }                    
                }
                if (['专业负责人', '项目成果审定人'].includes(record.position)){
                    cell.style = {backgroundColor: 'rgb(230, 230, 230)'}
                }else if (['项目负责人'].includes(record.position)){
                    cell.style = {backgroundColor: '#c4febb'}
                }
                return cell
            }
        },
        {
            title: showTable2? '完成造价 A' : '完成造价',
            dataIndex: 'A',
            width: inEdit? 150: 100,
            fixed: 'left',
            ellipsis: true,
            render: (text, record, index) => {
                // console.log(text)
                if (specialPosition.includes(record.position)){
                    if (['项目负责人', '项目成果审定人'].includes(record.position)){
                        let sum = 0
                        Object.keys(Asum).forEach(k => {
                            sum += Asum[k]
                        })
                        return sum.toFixed(2)
                    }else if(record.position.includes('专业审定人')){
                        const major = record.position.split('专业审定人')[0]
                        let sum = 0
                        Object.keys(Asum).filter(k => k.includes(major)).forEach(m => sum += Asum[m])
                        return sum.toFixed(2)
                    }
                    return text
                }else if (record.position === '专业负责人'){
                    return parseFloat(Asum[record.major]).toFixed(2)
                }else{
                    if (inEdit){
                        return (
                            <InputNumber min={1} style={{width: '8rem'}} value={record.A} onChange={(v) => {
                                newDetailData[index].A = v
                                setNewDetailData([...newDetailData])
                            }} />
                        )
                    }
                    return parseFloat(parseFloat(text).toFixed(2))
                }
            },
            onCell: (record, index) => {
                const cell = {style:{backgroundColor: '#e8feeb'}}
                if (['专业负责人', '项目成果审定人'].includes(record.position)){
                    cell.style = {backgroundColor: 'rgb(230, 230, 230)'}
                }else if (['项目负责人'].includes(record.position)){
                    cell.style = {backgroundColor: '#c4febb'}
                }
                return cell
            }
        },
        {
            title: showTable2? '审减金额 b' : '审减金额',
            dataIndex: 'b',
            width: inEdit? 150: 120,
            ellipsis: true,
            render: (text, record, index) => {
                if (specialPosition.includes(record.position)){
                    if (['项目负责人', '项目成果审定人'].includes(record.position)){
                        let sum = 0
                        Object.keys(bsum).forEach(k => {
                            sum += bsum[k]
                        })
                        return sum
                    }else if(record.position.includes('专业审定人')){
                        const major = record.position.split('专业审定人')[0]
                        let sum = 0
                        Object.keys(bsum).filter(k => k.includes(major)).forEach(m => sum += bsum[m])
                        return sum
                    }
                    return text
                }else if (record.position === '专业负责人'){
                    return bsum[record.major]
                }else{
                    if (inEdit){
                        return (
                            <InputNumber min={0} style={{width: '8rem'}} value={record.b} onChange={(v) => {
                                newDetailData[index].b = v
                                setNewDetailData([...newDetailData])
                            }} />
                        )
                    }
                    return text
                }
                
            },
            onCell: (record, index) => {
                const cell = {}
                if (!specialPosition.includes(record.position)){
                    if (index % 2 === 0){
                        cell.style = {backgroundColor: 'rgb(245, 245, 245)'}
                    }                    
                }
                if (['专业负责人', '项目成果审定人'].includes(record.position)){
                    cell.style = {backgroundColor: 'rgb(230, 230, 230)'}
                }else if (['项目负责人'].includes(record.position)){
                    cell.style = {backgroundColor: '#c4febb'}
                }
                return cell
            }
        },
        {
            title: '完成造价比',
            dataIndex: 'finishPerc',
            width: 90,
            ellipsis: true,
            render: (_, record, index) => {
                if (specialPosition.includes(record.position)){
                    return ''
                }
                let A = record.A;
                if(record.position && record.position.includes('专业负责人')){
                    A = Asum[record.major]
                }

                if (Asum[record.major] === 0){
                    return 0 + '%'
                }
                return ((A / Asum[record.major]) * 100).toFixed(2) + '%'
            },
            onCell: (record, index) => {
                const cell = {}
                if (!specialPosition.includes(record.position)){
                    if (index % 2 === 0){
                        cell.style = {backgroundColor: 'rgb(245, 245, 245)'}
                    }                    
                }
                if (['专业负责人', '项目成果审定人'].includes(record.position)){
                    cell.style = {backgroundColor: 'rgb(230, 230, 230)'}
                }else if (['项目负责人'].includes(record.position)){
                    cell.style = {backgroundColor: '#c4febb'}
                }
                return cell
            }
        },
        {
            title: showTable2? '难易系数 x1' : '难易系数',
            dataIndex: 'x1',
            width: inEdit? 105:80,
            render: inEdit? (text, record, index) => {

                if (!record.major || record.position === '专业负责人'){
                    return calcX1(text, record, index)
                }

                return (
                    <InputNumber min={0} step={0.01} style={{width: '6rem'}} value={record.x1} onChange={(v) => {
                        if(v){
                            v = parseFloat(v.toFixed(2))
                        }
                        newDetailData[index].x1 = v
                        setNewDetailData([...newDetailData])
                    }} />
                )
            }: calcX1,
            onCell: (record, index) => {
                const cell = {}
                if (!specialPosition.includes(record.position)){
                    if (index % 2 === 0){
                        cell.style = {backgroundColor: 'rgb(245, 245, 245)'}
                    }                    
                }
                if (['专业负责人', '项目成果审定人'].includes(record.position)){
                    cell.style = {backgroundColor: 'rgb(230, 230, 230)'}
                }else if (['项目负责人'].includes(record.position)){
                    cell.style = {backgroundColor: '#c4febb'}
                }
                return cell
            }
        },
        {
            title: showTable2? '产值系数 x2' : '产值系数',
            dataIndex: 'x2',
            width: inEdit? 105:80,
            render: (text, record, index) => {
                if (inEdit) {
                    return (
                        <InputNumber max={1} min={0} step={0.001} value={record.x2} onChange={(v) => {
                            newDetailData[index].x2 = v
                            setNewDetailData([...newDetailData])
                        }} />
                    )
                }
                return parseFloat(text) * 100 + '%'
            },
            onCell: (record, index) => {
                const cell = {}
                if (!specialPosition.includes(record.position)){
                    if (index % 2 === 0){
                        cell.style = {backgroundColor: 'rgb(245, 245, 245)'}
                    }                    
                }
                if (['专业负责人', '项目成果审定人'].includes(record.position)){
                    cell.style = {backgroundColor: 'rgb(230, 230, 230)'}
                }else if (['项目负责人'].includes(record.position)){
                    cell.style = {backgroundColor: '#c4febb'}
                }
                return cell
            }
        },
        {
            title: showTable2? '质量系数 x5' : '质量系数',
            dataIndex: 'x5',
            width: inEdit? 105:80,
            render: (text, record, index) => {
                if (inEdit) {
                    return (
                        <InputNumber max={1} min={0} step={0.001} value={record.x5} onChange={(v) => {
                            newDetailData[index].x5 = v
                            setNewDetailData([...newDetailData])
                        }} />
                    )
                }
                return parseFloat(text) * 100 + '%'
            },
            onCell: (record, index) => {
                const cell = {}
                if (!specialPosition.includes(record.position)){
                    if (index % 2 === 0){
                        cell.style = {backgroundColor: 'rgb(245, 245, 245)'}
                    }                    
                }
                if (['专业负责人', '项目成果审定人'].includes(record.position)){
                    cell.style = {backgroundColor: 'rgb(230, 230, 230)'}
                }else if (['项目负责人'].includes(record.position)){
                    cell.style = {backgroundColor: '#c4febb'}
                }
                return cell
            }
        },
        
    ]

    const table1Columns = [
        {
            title: '时长（小时）',
            dataIndex: 'hours',
            width: 110,
            render: (text, record, index) => {
                if (inEdit){
                    return (
                        <InputNumber onChange={(value) => {newDetailData[index].hours = value;setNewDetailData([...newDetailData])}} />
                    )
                } 
                return text
            },
            onCell: (record, index) => {
                const cell = {}
                if (!specialPosition.includes(record.position)){
                    if (index % 2 === 0){
                        cell.style = {backgroundColor: 'rgb(245, 245, 245)'}
                    }                    
                }
                if (['专业负责人', '项目成果审定人'].includes(record.position)){
                    cell.style = {backgroundColor: 'rgb(230, 230, 230)'}
                }else if (['项目负责人'].includes(record.position)){
                    cell.style = {backgroundColor: '#c4febb'}
                }
                return cell
            },
        },
        {
            title: '备注',
            dataIndex: 'comment',
            width: 170,
            ellipsis: true,
            render: (text, record, index) => {
                if (inEdit) {
                    return (
                        <TextArea rows={1} value={record.comment} onChange={(e) => {
                            isUpdatingComments.current = true
                            newDetailData[index].comment = e.target.value
                            setNewDetailData([...newDetailData])
                        }} />
                    )
                }
                return text
            },
            onCell: (record, index) => {
                const cell = {}
                if (!specialPosition.includes(record.position)){
                    if (index % 2 === 0){
                        cell.style = {backgroundColor: 'rgb(245, 245, 245)'}
                    }                    
                }
                if (['专业负责人', '项目成果审定人'].includes(record.position)){
                    cell.style = {backgroundColor: 'rgb(230, 230, 230)'}
                }else if (['项目负责人'].includes(record.position)){
                    cell.style = {backgroundColor: '#c4febb'}
                }
                return cell
            }
        },
        
    ]

    const signColumn = [
        {
            title: '签字',
            dataIndex: 'signed',
            width: 45,
            align: 'center',
            fixed: 'right',
            render: (text, record, index) => {
                const columns = [
                    {
                        title: '专业',
                        dataIndex: 'major'
                    },
                    {
                        title: '分工',
                        dataIndex: 'position'
                    },
                    {
                        title: '单位工程',
                        dataIndex: 'work'
                    },
                    {
                        title: '姓名',
                        dataIndex: 'name'
                    },
                    {
                        title: '完成造价',
                        dataIndex: 'A'
                    },
                    {
                        title: '审减金额',
                        dataIndex: 'b'
                    },
                    {
                        title: '完成造价比',
                        dataIndex: 'perc',
                        render: (_, r) => {
                            if (specialPosition.includes(r.position)){
                                return ''
                            }
                            let A = r.A;
                            if(r.position.includes('专业负责人')){
                                A = Asum[r.major]
                            }
                            return ((A / Asum[r.major]) * 100).toFixed(2) + '%'
                        },
                    },
                    {
                        title: '难易系数',
                        dataIndex: 'x1'
                    },
                    {
                        title: '产值系数',
                        dataIndex: 'x2'
                    },
                    {
                        title: '质量系数',
                        dataIndex: 'x5'
                    },
                ]
                const ConfirmTable = <>
                    <Table 
                        key={'project-detail-confirm-table'}
                        columns={columns} 
                        dataSource={[record]}
                        bordered
                        size='small'
                        pagination={false}
                    />
                </>

                return (
                    <Checkbox checked={record.signed === 'Y'}  onChange={() => { 
                        // console.log(record)
                        if (record.uid){
                            checkIndex.current = index
                            // console.log(checkIndex.current)
                            setConfirmMessage(ConfirmTable)
                            setOkText(record.signed === 'Y'? '取消确认': '确定数据正确')
                            setOkType(record.signed === 'Y'? 'danger': 'primary')
                            setVisible(true)
                        }
                    }}
                    disabled={archived}
                    // disabled={archived || record.name !== user.name}
                    />
                )
            }
        }
    ]

    const table2Columns = [
        {
            title:"原始产值 V0",
            dataIndex: 'v0',
            width: 75,
            render: calcV0,
            onCell: (record, index) => {
                const cell = {}
                if (!specialPosition.includes(record.position)){
                    if (index % 2 === 0){
                        cell.style = {backgroundColor: 'rgb(245, 245, 245)'}
                    }                    
                }
                if (['专业负责人', '项目成果审定人'].includes(record.position)){
                    cell.style = {backgroundColor: 'rgb(230, 230, 230)'}
                }else if (['项目负责人'].includes(record.position)){
                    cell.style = {backgroundColor: '#c4febb'}
                }
                return cell
            }
        },
        {
            title:"项目产值 V",
            dataIndex: 'v', // v = v0 * x1
            width: 75,
            render: calcV,
            onCell: (record, index) => {
                const cell = {}
                if (!specialPosition.includes(record.position)){
                    if (index % 2 === 0){
                        cell.style = {backgroundColor: 'rgb(245, 245, 245)'}
                    }                    
                }
                if (['专业负责人', '项目成果审定人'].includes(record.position)){
                    cell.style = {backgroundColor: 'rgb(230, 230, 230)'}
                }else if (['项目负责人'].includes(record.position)){
                    cell.style = {backgroundColor: '#c4febb'}
                }
                return cell
            }
        },
        {
            title:"员工产值 V1",
            dataIndex: 'v1',
            width: 75,
            render: calcV1,
            onCell: (record, index) => {
                const cell = {style:{backgroundColor: '#fff0f0'}}
                if (!specialPosition.includes(record.position)){
                    if (index % 2 === 0){
                        cell.style = {backgroundColor: 'rgb(245, 245, 245)'}
                    }                    
                }
                if (['专业负责人', '项目成果审定人'].includes(record.position)){
                    cell.style = {backgroundColor: 'rgb(230, 230, 230)'}
                }else if (['项目负责人'].includes(record.position)){
                    cell.style = {backgroundColor: '#bcf3b3'}
                }
                return cell
            }
        },
        {
            title:"技能系数 X3",
            dataIndex: 'x3',
            width: 75,
            render: calcX3,
            onCell: (record, index) => {
                const cell = {}
                if (!specialPosition.includes(record.position)){
                    if (index % 2 === 0){
                        cell.style = {backgroundColor: 'rgb(245, 245, 245)'}
                    }                    
                }
                if (['专业负责人', '项目成果审定人'].includes(record.position)){
                    cell.style = {backgroundColor: 'rgb(230, 230, 230)'}
                }else if (['项目负责人'].includes(record.position)){
                    cell.style = {backgroundColor: '#c4febb'}
                }
                return cell
            }
        },
        {
            title:"提升系数 X4",
            dataIndex: 'x4',
            width: 75,
            render: calcX4,
            onCell: (record, index) => {
                const cell = {}
                if (!specialPosition.includes(record.position)){
                    if (index % 2 === 0){
                        cell.style = {backgroundColor: 'rgb(245, 245, 245)'}
                    }                    
                }
                if (['专业负责人', '项目成果审定人'].includes(record.position)){
                    cell.style = {backgroundColor: 'rgb(230, 230, 230)'}
                }else if (['项目负责人'].includes(record.position)){
                    cell.style = {backgroundColor: '#c4febb'}
                }
                return cell
            }
        },
        {
            title:"员工产值 V2",
            dataIndex: 'v2',
            width: 75,
            render: calcV2,
            onCell: (record, index) => {
                const cell = {}
                if (!specialPosition.includes(record.position)){
                    if (index % 2 === 0){
                        cell.style = {backgroundColor: 'rgb(245, 245, 245)'}
                    }                    
                }
                if (['项目成果审定人'].includes(record.position)){
                    cell.style = {backgroundColor: 'rgb(230, 230, 230)'}
                }else if (['项目负责人'].includes(record.position)){
                    cell.style = {backgroundColor: '#c4febb'}
                }else if (['专业负责人'].includes(record.position)){
                    cell.style = {backgroundColor: '#f7f794'}
                }
                return cell
            }
        },
        {
            title:"附加产值 V3",
            dataIndex: 'v3',
            width: 75,
            render: calcV3,
            onCell: (record, index) => {
                const cell = {}
                if (!specialPosition.includes(record.position)){
                    if (index % 2 === 0){
                        cell.style = {backgroundColor: 'rgb(245, 245, 245)'}
                    }                    
                }
                if (['专业负责人', '项目成果审定人'].includes(record.position)){
                    cell.style = {backgroundColor: 'rgb(230, 230, 230)'}
                }else if (['项目负责人'].includes(record.position)){
                    cell.style = {backgroundColor: '#c4febb'}
                }
                return cell
            }
        },
        {
            title:"员工产值 V4",
            dataIndex: 'v4',
            width: 75,
            render: calcV4,
            onCell: (record, index) => {
                const cell = {style:{backgroundColor: '#e8feeb'}}
                if (['专业负责人', '项目成果审定人'].includes(record.position)){
                    cell.style = {backgroundColor: 'rgb(230, 230, 230)'}
                }else if (['项目负责人'].includes(record.position)){
                    cell.style = {backgroundColor: '#c4febb'}
                }
                return cell
            }
        },
        {
            title:"质量扣除产值 V5",
            dataIndex: 'v5',
            width: 120,
            render: calcV5,
            onCell: (record, index) => {
                const cell = {}
                if (!specialPosition.includes(record.position)){
                    if (index % 2 === 0){
                        cell.style = {backgroundColor: 'rgb(245, 245, 245)'}
                    }                    
                }
                if (['专业负责人', '项目成果审定人'].includes(record.position)){
                    cell.style = {backgroundColor: 'rgb(230, 230, 230)'}
                }else if (['项目负责人'].includes(record.position)){
                    cell.style = {backgroundColor: '#c4febb'}
                }
                return cell
            }
        },
        
    ]


    return (
        <div>
            <Table 
                    key={'project-detail-table'}
                    loading={detailLoading}
                    columns={showTable2? columnsBase.concat(table2Columns): ( columnsBase.concat(table1Columns).concat(signColumn))}
                    dataSource={newDetailData}
                    bordered
                    size='small'
                    pagination={false}
                    scroll={{
                        x: showTable2? 1500: 1200,
                        y: tableHeight // hide this if no limit to the table height
                    }}
                    summary={(pageData) => {
                        let [sumA, sumb] = [0, 0]
                        Object.keys(Asum).forEach(k => {
                            sumA += Asum[k]
                            sumb += bsum[k]
                        })

                        return (
                            <Table.Summary fixed>
                              <Table.Summary.Row>
                                <Table.Summary.Cell className="detail-summary-cell" fixed={'left'} colSpan={6} index={0}>
                                    <div style={{display:'flex', justifyContent:'space-between'}}>
                                        <div>
                                            小计
                                        </div>
                                        
                                        <div style={{marginRight:'1rem'}}>
                                            {(sumA/10000).toFixed(4)} (万)
                                        </div>
                                    </div>   
                                </Table.Summary.Cell>
                                <Table.Summary.Cell className="detail-summary-cell-rest" colSpan={1} index={2}>
                                    <div style={{display:'flex', justifyContent:'space-between', zIndex:'-1100'}}>
                                        <div>
                                            {(sumb/10000).toFixed(4)} (万)
                                        </div>
                                    </div>
                                </Table.Summary.Cell>
                                {
                                    showSummary2 ?
                                    <>
                                    <Table.Summary.Cell className="detail-summary-cell-rest2" colSpan={2} index={3} >
                                        <div style={{display:'flex', alignItems:'center', zIndex:'-1100'}}>
                                            <div>
                                                成本比：{newInfoData.fee > 0 ? (((summaryV.V1) / newInfoData.fee) * 100).toFixed(2) + '%' : '未知'}
                                            </div>
                                        </div>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell className="detail-summary-cell-rest2" colSpan={4} index={3} >
                                        <div style={{display:'flex', alignItems:'center', zIndex:'-1100'}}>
                                            项目产值系数比（万分比）：
                                            <div style={{textDecoration: 'underline', marginRight:'.3rem'}}>{((summaryV.V1) / (sumA/10000)).toFixed(2)}</div>
                                            <Tooltip style={{margin:'auto 0'}} placement="bottom"
                                                     title={`${summaryV.V1} / ${sumA/10000} `}>
                                                <QuestionCircleOutlined />
                                            </Tooltip>
                                        </div>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell className="detail-summary-cell-rest" index={4}>
                                        <div style={{display:'flex', alignItems:'center', zIndex:'-1100'}}>
                                            <div style={{marginRight:'0.2rem'}}>
                                                {summaryV.V1}
                                            </div>
                                        </div>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell className="detail-summary-cell-rest" colSpan={2} index={5} />
                                    <Table.Summary.Cell className="detail-summary-cell-rest" index={6}>
                                    <div style={{display:'flex', alignItems:'center', zIndex:'-1100'}}>
                                            <div style={{marginRight:'0.2rem'}}>
                                                {summaryV.V2}
                                            </div>
                                        </div>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell className="detail-summary-cell-rest" colSpan={1} index={7} />
                                    <Table.Summary.Cell className="detail-summary-cell-rest" colSpan={1} index={8}>
                                    <div style={{display:'flex', alignItems:'center', zIndex:'-1100'}}>
                                            <div style={{marginRight:'0.2rem'}}>
                                                {summaryV.V4}
                                            </div>
                                        </div>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell className="detail-summary-cell-rest" colSpan={2}  index={9}>
                                        <div style={{display:'flex', alignItems:'center', zIndex:'-1100'}}>
                                            <div style={{marginRight:'0.2rem'}}>
                                                {summaryV.V5}
                                            </div>
                                        </div>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell className="detail-summary-cell-rest" index={10} />
                                    </>:
                                    <Table.Summary.Cell className="detail-summary-cell-rest" colSpan={14} index={99}></Table.Summary.Cell>
                                }
                                
                              </Table.Summary.Row>
                            </Table.Summary>
                          )
                    }}
                />
            <MessageModal {...{ visible, setVisible, title:'确认签名', message:confirmMessage, handleConfirm, handleCancel, confirmLoading, width:1200, okText, okType }}/>
        </div>
    )
}

export default ProjectDetialTable;