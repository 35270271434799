import axios from "axios";
import { read, utils, writeFile } from 'xlsx';
import { withNotification } from "./notification";

// if no uuid provide, fetch all projects
const getProjects = (projectCode) => {
    if (projectCode){
        return axios.get(`/api/project/${projectCode}`)
    }
    return axios.get('/api/project')
}

const getProjectValue = (projectCode) => {
    return axios.get(`/api/project/project-values/${projectCode}`)
} 

const updateProject = (record) => {
    record.fill_time = (new Date()).Format("yyyy-MM-dd HH:mm:ss")
    return axios.post('/api/project/update', record)
}

const projectValuesUpdate = (records) => {
    return axios.post('/api/project/project-values/update', {records})
}

const addNewProject = (info, records) => {
    if (!info.fill_time){
        info.fill_time = (new Date()).Format("yyyy-MM-dd HH:mm:ss")
    }
    return axios.post('/api/project/new/', {info, records})
}

const addMultiNewProject = (projects) => {
    const projs = projects.map(p => {
        if (!p.info.fill_time){
            p.info.fill_time = (new Date()).Format("yyyy-MM-dd HH:mm:ss")
        }
        return p
    })
    return axios.post('/api/project/new-multi/', {projects: projs})
}

const removeProject = (uuid) => {
    return axios.delete(`/api/project/${uuid}`)
}

const archiveProject = (code) => {
    return axios.post('/api/project/archive', {code})
}

const unArchiveProject = (code) => {
    return axios.post('/api/project/unarchive', {code})
}

const checkArchive = (code) => {
    return axios.get('/api/project/check-archive/' + code)
}

// 产值签字
const signProjectDetail = (uid, signed) => {
    return axios.post('/api/project/sign', {uid, signed})
}


const downloadProject = (code) => {
    return new Promise((resolve, reject) => {

        setTimeout(async () => {
            try{
                const workbook = utils.book_new()
                let [info, detail] = await Promise.all([withNotification(getProjects(code), '', false),
                                                        withNotification(getProjectValue(code), '', false)])

                info = info.data[0]
                const major_to_a_sum = {}
                detail.data.map(d => {
                    if (d.major && d.position !== '专业负责人'){
                        if (!Object.keys(major_to_a_sum).includes(d.major)){
                            major_to_a_sum[d.major] = parseInt(d.A)
                        }else{
                            major_to_a_sum[d.major] += parseInt(d.A)
                        }
                    }
                })
                let curMajor = ''
                detail = detail.data.map((d, idx) => {
                    let showMajor = false
                    if (d.major && d.major !== curMajor){
                        curMajor = d.major
                        showMajor = true
                    }
                    return([
                        idx,
                        d.major? (showMajor? d.major : '') : d.position,
                        d.major? d.position : '',
                        d.work,
                        d.name,
                        d.position === '专业负责人' ? 
                        
                        major_to_a_sum[d.major] : d.A,
                        d.b,
                        d.major? d.position === '专业负责人' ? '100%' : (d.A / major_to_a_sum[d.major] * 100).toFixed(2) + '%' : '',
                        d.x1, 
                        d.x2,
                        d.x5,
                        d.hours || 0,
                        d.comment || ''
                    ])
                })

                console.log(info, detail)

                let rows = [['AD-HR-17'], 
                              ['项目产值分配表'],
                              ['项目名称：', '', info.name, '', '咨询类别：', info.type, '项目编号：', info.code, '开始日期：', info.start_date,' 结束日期：', info.end_date],
                              ['咨询费：', info.fee, '已收费用：', info.fee_collected, "应收费用", info.fee - info.fee_collected, "差旅费：", info.travel_fee, "填表人：", info.fill_person, "填表时间：", info.fill_time],
                              ['序号', '专业', '分工', '单位工程名称', '姓名', '完成造价（A）', '审减金额（b）',
                                '完成造价比（%）', '难易系数（x1）', '产值系数（x2）', '质量系数（x5）', '时长（小时）', '备注']
                            ]
                rows = rows.concat(detail)

                console.log(rows)
                
                const worksheet1 = utils.aoa_to_sheet(rows)

                utils.book_append_sheet(workbook, worksheet1, '产值分配表1')

                writeFile(workbook, info.name + ".xlsx");

                resolve(1)

            }catch(e){
                reject(e)
            }
        })
        

    })
}

const ProjectController = {
    getProjects, getProjectValue, updateProject, projectValuesUpdate, addNewProject,
    removeProject, downloadProject, archiveProject, checkArchive, unArchiveProject,
    signProjectDetail, addMultiNewProject
}



export default ProjectController

