import React from 'react';

const Home = () => {

    return (
    <div style={{textAlign:'center', fontSize:'50px'}}>
        <h1 style={{marginTop:'3rem'}}>
            慧以致远
            <br />
            厚德为本
        </h1>
        
    </div>)

}

export default Home;