import { InboxOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';
import React, { useState } from 'react';
import { useContext } from 'react';
import { UserContext } from '../../../AppLayout';

const { Dragger } = Upload;

const ProjectUpload = (props) => {
    const user = useContext(UserContext)

    const { uploadFileList, setUploadFileList, isNew, record, setLoading, newRecord,setNewRecord, records, setCurrentRecords } = props

    const MAJOR = ['建筑', '安装', '装修', '土建']

    const onRemove = (file) => {
        // setLoading(true)
        // console.log(file)

        records.current = []

        if (!isNew){
            setNewRecord({...record,
                key: record.uuid,
            })
        }else{
            setNewRecord({})
        }
        // setLoading(false)
    }

    const onChange = (info) => {
        console.log(info.file.status)
        setLoading(true)
        const { status, response } = info.file;

        setUploadFileList(info.fileList)

        if (status !== 'uploading') {
            console.log(info.file, info.fileList);
        }

        if (status === 'done' && info.fileList.length > 0) {
            if (response.code === -1){
                message.error(`用户未登录`);
                window.location = '/login'
                return
            }
            setUploadFileList(info.fileList)
            // console.log(response.data.tempFilePath)
            message.success(`${info.file.name} 文件上传成功.`);
            parseRows(response.rows)
        } else if (status === 'error') {
            message.error(`${info.file.name} 文件上传失败.`);
            setUploadFileList([])
        }
        setTimeout(()=>{
            setLoading(false)
        }, 500)
    }

    const parseRows = (rows) => {
        // console.log(rows)

        // newRec: project summary data
        const newRec = {
            ...newRecord,
            name: newRecord.name || '',
            type: newRecord.type || '',
            code: newRecord.code || '',
            // department_x2 : newRecord.department_x2 || 0.02, // default 0.02
            fee: newRecord.fee || 0,
            fee_collected: newRecord.fee_collected || 0,
            fill_person: user.name
        }

        const newRecords = []
        let curMajor, curPosition
        let start = false

        for (let r of rows){
            // console.log(r)
            const majorRead = r[1]
            const posRead = r[2]
            if (r[0] === "项目名称："){
                newRec.name = r[2] || ''
                newRec.type = r[5] || ''
                newRec.code = r[7] || ''
                newRec.start_date = r[9] || ''
                newRec.end_date = r[11] || ''
            }else if(majorRead === "项目负责人"){
                newRec.owner = r[4] || '' 
                newRecords.push({
                    major: '',
                    position: r[1],
                    work: r[3],
                    name: r[4],
                    A: r[5],
                    b: r[6] || 0,
                    x1: r[8],
                    x2: r[9],
                    x5: r[10],
                    hours: r[11],
                    comment: r[12]
                })
            }else if (r[0] === '咨询费：'){
                newRec.fee = r[3] || 0
                newRec.fee_collected = r[5] || 0
                newRec.travel_fee = r[7] || 0
            }else if (majorRead === '小计' || majorRead === '小计（万元）'){
                start = false
                break
            }else if (r[0] === '序号'){
                start = true
                continue
            }else if (start){
                let curRec = {}
                if (typeof(majorRead) === 'string' && majorRead.includes('审定人')){
                    curRec.major = ''
                    curRec.position = majorRead
                }else{
                    if ((typeof(majorRead) === 'string') && !majorRead.includes('审定人') && MAJOR.filter(m => majorRead.includes(m)).length > 0){
                        curMajor = majorRead
                        curPosition = posRead
                    }
                    curRec.major = majorRead || curMajor
                    curRec.position = posRead || curPosition
                }
                curRec = {
                    ...curRec,
                    work: r[3],
                    name: r[4],
                    A: r[5],
                    b: r[6] || 0,
                    x1: r[8],
                    x2: r[9],
                    x5: r[10],
                    hours: r[11],
                    comment: r[12]
                }
                newRecords.push(curRec)
            }
        }
        
        // console.log(newRec, newRecords)
        setNewRecord({...newRec})
        records.current = newRecords
        if (setCurrentRecords){
            setCurrentRecords(newRec, newRecords)
        }
    }

    return (
        <Dragger 
            accept='.xlsx'
            name='project'
            multiple={false}
            action={'/api/project/upload/'}
            onDrop={(e)=>{
                console.log('Dropped files', e.dataTransfer.files);
            }}
            onChange={onChange}
            onRemove={onRemove}
            maxCount={1}
            fileList={uploadFileList}
            >
            <p className="ant-upload-drag-icon">
                <InboxOutlined />
            </p>
            <p className="ant-upload-text">
                点击此处或将文件拖拽至此处
                <br />
                上传项目产值分配表
            </p>
            <p className="ant-upload-hint">
                仅上传.xlsx文件
            </p>
        </Dragger>
    )
};

export default ProjectUpload;