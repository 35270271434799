import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import React, { useState } from 'react';

const MessageModal = (props) => {
    const { visible, setVisible, title, message,
            handleConfirm, confirmLoading, handleCancel,
            width, okText, okType } = props;

    const [okLoading, setOkloading] = useState(false)

    const onOk = () => {
        setOkloading(true)
        setTimeout(async () => {
            await handleConfirm()
            setOkloading(false)
            setVisible(false)
        }, 0)
    };

    const onCancel = () => {
        console.log('Clicked cancel button');
        setVisible(false);
        if (handleCancel) handleCancel()
        setOkloading(false)
    };

    return (
        <>
            <Modal
                title={<> <ExclamationCircleOutlined style={{color: 'red'}}/> {title}</>}
                visible={visible}
                onOk={onOk}
                confirmLoading={confirmLoading === undefined? okLoading: confirmLoading}
                onCancel={onCancel}
                width={width || 500}
                okText={okText || '确定'}
                okType={okType || 'primary'} // danger, dashed, default, ghost, link, primary, text
            >
                <div>{message}</div>
            </Modal>
        </>
    );
};

export default MessageModal;