import React, { useEffect, useState, useRef } from 'react';
import { Button, Input, Modal, Space } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

const EditDepartmentModal = (props) =>{

    const { department, setDepartment, isNew, visible, confirmLoading, okClick, cancelClick,
            deleteLoading, deleteClick } = props
    

    return (
        <Modal key='edit-department-modal'
            title={isNew? '添加新部门' : <>编辑部门 <Button style={{marginLeft:'1rem', color:'red', borderColor:'red'}} onClick={deleteClick} loading={deleteLoading} >删除部门</Button></>}
            visible={visible}
            okText={isNew? '添加':'更新'}
            onOk={okClick}
            confirmLoading={confirmLoading}
            onCancel={cancelClick}

            destroyOnClose
        >

            {/* 部门编号、部门名称、备注 */}
            <div style={{display:'flex', justifyContent:'center',}}>

            
            <Space direction='vertical' >
                <div style={{display:'flex', alignItems:'center'}}>
                    <p style={{width:'6rem', marginRight:'.5rem', margin:'0 auto'}}>部门名称：</p>
                    <Input value={department.name} onChange={(e) => {setDepartment({...department, name: e.target.value})}} />
                </div>
                <div style={{display:'flex', alignItems:'center',}}>
                    <p style={{width:'6rem', marginRight:'.5rem', margin:'0 auto'}}>部门编号：</p>
                    <Input value={department.code} onChange={(e) => {setDepartment({...department, code: e.target.value})}} />
                </div>
                <div style={{display:'flex', alignItems:'center'}}>
                    <p style={{width:'6rem', marginRight:'.5rem', margin:'0 auto'}}>备注：</p>
                    <TextArea rows={1}  value={department.comment} onChange={(e) => {setDepartment({...department, comment: e.target.value})}} />
                </div>

            </Space>
            </div>

        </Modal>
    )


}

export default EditDepartmentModal
