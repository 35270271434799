// core functions for this app
function get_res(i, r){
    return i*r*0.001
}

// 计算土建产值:
function calcu_tj(a, b){

    let result

    if (a >= 0 && a <= 100000){
        result = get_res(a, 1.2)
    }else if(a > 100000 && a <= 200000){
        result = get_res(a-100000, 1.1)
        result += 120
    }else if (a > 200000 && a <= 300000){
        result = get_res(a-200000, 1)
        result += 230
    }else if (a > 300000 && a <= 500000){
        result = get_res(a-300000, 0.85)
        result += 330
    }else if (a > 500000 && a <= 1000000){
        result = get_res(a-500000, 0.7)
        result += 500
    }else if (a > 1000000 && a <= 2000000){
        result = get_res(a-1000000, 0.6)
        result += 850
    }else if (a > 2000000 && a <= 3000000){
        result = get_res(a-2000000, 0.5)
        result += 1450
    }else if (a > 3000000 && a <= 5000000){
        result = get_res(a-3000000, 0.4)
        result += 1950
    }else if (a > 5000000 && a <= 10000000){
        result = get_res(a-5000000, 0.3)
        result += 2750
    }else if (a > 10000000 && a <= 20000000){
        result = get_res(a-10000000, 0.25)
        result += 4250
    }else if (a > 20000000 && a <= 50000000){
        result = get_res(a-20000000, 0.15)
        result += 6750
    }else if (a > 50000000 && a <= 100000000){
        result = get_res(a-50000000, 0.075)
        result += 11250
    }else if (a > 100000000 && a <= 150000000){
        result = get_res(a-100000000, 0.06)
        result += 15000
    }else if (a > 150000000){
        result = get_res(a-150000000, 0.05)
        result += 18000
    }

    if (b >= 0 && b <= 100000){
        result += b * 0.004
    }else if (b > 100000 && b <= 200000){
        result += b * 0.0035
    }else if (b > 200000 && b <= 300000){
        result += b * 0.0033
    }else if (b > 300000 && b <= 500000){
        result += b * 0.003
    }else if (b > 500000 && b <= 1000000){
        result += b * 0.0028
    }else if (b > 1000000 && b <= 2000000){
        result += b * 0.0026
    }else if (b > 2000000 && b <= 3000000){
        result += b * 0.0022
    }else if (b > 3000000 && b <= 5000000){
        result += b * 0.0018
    }else if (b > 5000000 && b <= 10000000){
        result += b * 0.0015
    }else if (b > 10000000 && b <= 20000000){
        result += b * 0.001
    }else if (b > 20000000 && b <= 50000000){
        result += b * 0.0008
    }else if (b > 50000000 && b <= 100000000){
        result += b * 0.0007
    }else if (b > 100000000 && b <= 150000000){
        result += b * 0.0006
    }else if (b > 150000000){
        result += b * 0.0005
    }
    result += 0.5

    return Math.floor(result)
};

// 计算安装产值
function calcu_az(a, b){
    let result = 0
    if (a >= 0 && a <= 100000){
        result = get_res(a, 1.3)
    }else if (a > 100000 && a <= 200000){
        result = get_res(a-100000, 1.2)
        result += 130
    }else if (a > 200000 && a <= 300000){
        result = get_res(a-200000, 1.16)
        result += 250
    }else if (a > 300000 && a <= 500000){
        result = get_res(a-300000, 1.12)
        result += 366
    }else if (a > 500000 && a <= 1000000){
        result = get_res(a-500000, 1.1)
        result += 590
    }else if (a > 1000000 && a <= 2000000){
        result = get_res(a-1000000, 0.86)
        result += 1140
    }else if (a > 2000000 && a <= 3000000){
        result = get_res(a-2000000, 0.7)
        result += 2000
    }else if (a > 3000000 && a <= 5000000){
        result = get_res(a-3000000, 0.6)
        result += 2700
    }else if (a > 5000000 && a <= 10000000){
        result = get_res(a-5000000, 0.5)
        result += 3900
    }else if (a > 10000000 && a <= 20000000){
        result = get_res(a-10000000, 0.32)
        result += 6400
    }else if (a > 20000000 && a <= 30000000){
        result = get_res(a-20000000, 0.24)
        result += 9600
    }else if (a > 30000000 && a <= 50000000){
        result = get_res(a-30000000, 0.2)
        result += 12000
    }else if (a > 50000000 && a <= 100000000){
        result = get_res(a-50000000, 0.18)
        result += 16000
    }else if (a > 100000000){
        result = get_res(a-100000000, 0.1)
        result += 25000
    }

    if (b >= 0 && b <= 100000){
        result += b * 0.005
    }else if (b > 100000 && b <= 200000){
        result += b * 0.0046
    }else if (b > 200000 && b <= 300000){
        result += b * 0.004
    }else if (b > 300000 && b <= 500000){
        result += b * 0.0036
    }else if (b > 500000 && b <= 1000000){
        result += b * 0.0035
    }else if (b > 1000000 && b <= 2000000){
        result += b * 0.003
    }else if (b > 2000000 && b <= 3000000){
        result += b * 0.0025
    }else if (b > 3000000 && b <= 5000000){
        result += b * 0.0018
    }else if (b > 5000000 && b <= 10000000){
        result += b * 0.0012
    }else if (b > 10000000 && b <= 20000000){
        result += b * 0.001
    }else if (b > 20000000 && b <= 30000000){
        result += b * 0.0009
    }else if (b > 30000000 && b <= 50000000){
        result += b * 0.0008
    }else if (b > 50000000 && b <= 100000000){
        result += b * 0.0007
    }else if (b > 100000000){
        result += b * 0.0006
    }
    return Math.floor(result + 0.5)
};

// 计算装修产值
function calcu_zx(a, b){
    let result = 0;

    if (a >= 0 && a <= 200000){
        result = get_res(a, 1.2)
    }else if (a > 200000 && a <= 500000){
        result = get_res(a-200000, 1.1)
        result += 240
    }else if (a > 500000 && a <= 1000000){
        result = get_res(a-500000, 0.9)
        result += 570
    }else if (a > 1000000 && a <= 3000000){
        result = get_res(a-1000000, 0.66)
        result += 1020
    }else if (a > 3000000 && a <= 5000000){
        result = get_res(a-3000000, 0.6)
        result += 2340
    }else if (a > 5000000 && a <= 10000000){
        result = get_res(a-5000000, 0.48)
        result += 3540
    }else if (a > 10000000 && a <= 30000000){
        result = get_res(a-10000000, 0.29)
        result += 5940
    }else if (a > 30000000 && a <= 50000000){
        result = get_res(a-30000000, 0.145)
        result += 11740
    }else if (a > 50000000){
        result = get_res(a-50000000, 0.12)
        result += 14640
    }

    if (b >= 0 && b <= 200000){
        result += b * 0.005
    }else if (b > 200000 && b <= 500000){
        result += b * 0.0046
    }else if (b > 500000 && b <= 1000000){
        result += b * 0.004
    }else if (b > 1000000 && b <= 3000000){
        result += b * 0.0036
    }else if (b > 3000000 && b <= 5000000){
        result += b * 0.0035
    }else if (b > 5000000 && b <= 10000000){
        result += b * 0.003
    }else if (b > 10000000 && b <= 30000000){
        result += b * 0.0025
    }else if (b > 30000000 && b <= 50000000){
        result += b * 0.002
    }else{
        result += b * 0.0015
    }
    
    result += 0.5
    return Math.floor(result)
};


const calculateV0 = (A, b, major) => {

    if (['建筑', '土建'].filter(m => major.includes(m)).length > 0) return calcu_tj(A,b)
    if (['安装'].filter(m => major.includes(m)).length > 0) return calcu_az(A,b)
    if (['装修'].filter(m => major.includes(m)).length > 0) return calcu_zx(A,b)
}

const ProjectValuesCalculator = {
    calculateV0
}

export default ProjectValuesCalculator