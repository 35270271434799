import React, { useEffect, useState, useRef } from 'react';
import { message, Table, Input, Button, Typography, Tooltip } from 'antd';
import {
    PlusOutlined, EditOutlined, DownloadOutlined, SaveOutlined,
    UploadOutlined, ReloadOutlined, QuestionCircleOutlined
} from '@ant-design/icons';
import { withNotification } from '../../utils/notification';
import DepartmentController from '../../utils/department';
import EditDepartmentModal from './component/EditDepartmentModal';
import UploadDepartmentModal from './component/UploadDepartmentModal';
import { getTableScroll } from '../../utils/calculateHeight';

const { Search } = Input;
const { Link } = Typography;

const DepartmentsPage = (props) => {

    const [data, setData] = useState([])
    const [tableHeight, setTableHeight] = useState('')
    const [tableLoading, setTableLoading] = useState(false)
    const [searchLoading, setSearchLoading] = useState(false)
    const [isAddingNew, setIsAddingNew] = useState(false)
    const [downloadLoading, setDownloadLoading] = useState(false)
    const [uploadLoading, setUploadLoading] = useState(false)
    const [modalOkLoading, setModalOkLoading] = useState(false)
    const [modalDeleteLoading, setModalDeleteLoading] = useState(false)
    const [saveLoading, setSaveLoading] = useState(false)

    const search = useRef('')

    const [isUpload, setIsUpload] = useState(false)
    const [uploadFileList, setUploadFileList] = useState([])
    const [showEditModal, setShowEditModal] = useState(false)
    const [departmentInEdit, setDepartmentInEdit] = useState({})
    
    const [showUploadModal, setShowUploadModal] = useState(false)
    const dataUploaded = useRef([])

    useEffect(() =>{ 
        loadData()
    }, [])

    const loadData = () =>{
        setTableLoading(true)
        if (isUpload){
            setData(dataUploaded.current.filter(d => d.name.includes(search.current)))
            setTableLoading(false)
            return
        }
        dataUploaded.current = []
        return new Promise((resolve, reject) => {
            setTimeout(async () =>{ 
                try{
                    const res = await withNotification(DepartmentController.getDepartments(), '', false)
                    console.log(res)
                    const data = res.data.filter(d => d.name.includes(search.current)).map((d,idx) => ({...d, key:idx+1}))
                    setData(data)
                    const tableHeight = getTableScroll({ extraHeight: 80, id: 'department-table' })
                    setTableHeight(tableHeight)
                    resolve(1)
                }catch(e){
                    message.error(e.message)
                    resolve(1)
                }
                setTableLoading(false)
            })
        })
        
    }


    const columns = [
        {
            title: '序号',
            dataIndex: 'key',
            width:70,
        },
        {
            title: '部门名称',
            dataIndex: 'name',
            width:200,
            render: (text, record) => <Link href={'/department/' + record.code} target="_blank"> {text} </Link>
        },
        {
            title: '部门编号',
            dataIndex: 'code',
            width:200,
        },
        {
            title: '备注',
            dataIndex: 'comment'
        },
        {
            title: '编辑',
            key: 'edit',
            width: 60,
            fixed:'right',
            render: (text, record) => <Link onClick={() => editClick(record)}> <EditOutlined /> </Link>
        },
    ]


    const searchClick = (e) =>{ 
        // console.log(e)
        search.current = e
        setSearchLoading(true)
        setTimeout(async () => {
            await loadData()
            setSearchLoading(false)
        })
    }

    const editClick = (department) =>{
        // console.log(department)
        if (!department){
            setDepartmentInEdit({
                code: '',
                name: '',
                comment: ''
            })
            setIsAddingNew(true)
            console.log(isAddingNew)
        }else{
            setDepartmentInEdit(department)
        }
        setShowEditModal(true)
    }

    const modalCancelClick = () => {
        // console.log('dd')
        setShowEditModal(false)
        setIsAddingNew(false)
    }

    const modalOkClick = () =>{
        console.log(departmentInEdit)
        setModalOkLoading(true)

        setTimeout(async () =>{ 
            try{
                if (isAddingNew){
                    const {code, name, fee, comment} = departmentInEdit
                    await withNotification(DepartmentController.addDepartment(code, name, fee, comment), '添加成功')
                }else{
                    const {uuid, code, name, fee, comment} = departmentInEdit
                    await withNotification(DepartmentController.updateDepartment(uuid, code, name, fee, comment), '更新成功')
                }
    
                loadData()
                modalCancelClick()
            }catch(e){
                message.error(e.message)
            }
            setModalOkLoading(false)
        })
    }

    const deleteDepartmentClick = () => {
        console.log(departmentInEdit)
        setModalDeleteLoading(true)

        const confirm = window.confirm('确定要删除吗？')
        if (!confirm) return

        setTimeout(async () => {

            try{
                const {code} = departmentInEdit
                await withNotification(DepartmentController.deleteDepartment(code), '删除成功')
                loadData()
                modalCancelClick()
            }catch(e){
                message.error(e.message)
            }
            setModalDeleteLoading(false)
        }, 1000)


    }

    const cancelUploadClick = () =>{
        setUploadFileList([])
        setIsUpload(false)
        loadData()
    }

    const downloadClick = () => {
        setDownloadLoading(true)

        setTimeout(async () =>{ 
            try{
                await DepartmentController.downloadDepartments()
            }catch(e){
                message.error(e.message)
            }
            setDownloadLoading(false)
        })

    }

    const uploadClick = () => {
        setUploadLoading(true)

        setTimeout(() =>{ 
            
            setShowUploadModal(true)
            setUploadLoading(false)
        })

    }

    const saveUploadClick = () => {
        setSaveLoading(true)
        const uploadData = dataUploaded.current
        console.log(uploadData)

        setTimeout(async () => {
            try{
                await withNotification(DepartmentController.uploadSaveDepartments(data), '覆盖部门信息成功')       
                setIsUpload(false)
                loadData()
            }catch(e){
                message.error(e.message)
            }
            setSaveLoading(false)
        })

    }

    return (
        <div>

            <div style={{marginBottom:'1rem', display:'flex', justifyContent:'space-between'}} >
                
                <Search style={{width:'15rem'}} allowClear enterButton placeholder='搜索部门名称' loading={searchLoading}
                        onSearch={searchClick} />

                <div style={{display: 'flex'}}>

                    <Button onClick={loadData} >
                        <ReloadOutlined />
                    </Button>

                    <Button style={{marginRight: '0.5rem', marginLeft:'.5rem'}} loading={uploadLoading} onClick={uploadClick}>
                        <UploadOutlined />
                        上传
                        <Tooltip title='建议下载后修改再重新上传'>
                            <QuestionCircleOutlined />
                        </Tooltip>
                    </Button>  

                    <Button style={{marginRight: '0.5rem'}} loading={downloadLoading} onClick={downloadClick}>
                        <DownloadOutlined />
                        下载
                    </Button>

                    {
                        isUpload?
                        <>
                        
                        <Button style={{marginRight: '0.5rem'}} onClick={cancelUploadClick}>
                            取消
                        </Button>

                        <Button  type='primary' loading={saveLoading} onClick={saveUploadClick}>
                            <SaveOutlined />
                            保存
                        </Button>
                        </>
                        :
                        <Button type='primary' onClick={() => editClick()} loading={isAddingNew}>
                            <PlusOutlined />
                            添加新部门
                        </Button>
                    }
                    

                </div>
                    
            </div>

            <Table id='department-table' bordered
                loading={tableLoading}
                columns={columns}
                dataSource={data}
                scroll={{
                    y: tableHeight
                }}
            />

            <EditDepartmentModal department={departmentInEdit} setDepartment={setDepartmentInEdit} isNew={isAddingNew} visible={showEditModal} setVisible={setShowEditModal} 
                        okClick={modalOkClick} cancelClick={modalCancelClick} confirmLoading={modalOkLoading} deleteLoading={modalDeleteLoading} deleteClick={deleteDepartmentClick} />
            <UploadDepartmentModal setData={setData} visible={showUploadModal} setVisible={setShowUploadModal} setIsUpload={setIsUpload}
                        uploadFileList={uploadFileList} setUploadFileList={setUploadFileList} dataUploaded={dataUploaded} />

        </div>
    )

}

export default DepartmentsPage
