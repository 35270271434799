import React, { useState } from 'react';
import { Button, message, Modal, Table, Typography, Space, Input, Switch, InputNumber  } from 'antd';
import { useEffect } from 'react';
import { withNotification } from '../../utils/notification';
import AccessController from '../../utils/access';
import {
    PlusOutlined, EditOutlined, DownloadOutlined, SaveOutlined,
    UploadOutlined, ReloadOutlined, QuestionCircleOutlined
} from '@ant-design/icons';

const { TextArea } = Input;

const { Link } = Typography;

const pages = [
    {
        key:'employee_page',
        page: '员工信息',
        access: [
            {
                name: '查看自己基本产值',
                key: 'employee_page_view_own'
            },
            {
                name: '查看所有人基本产值',
                key: 'employee_page_view_all'
            },
            {
                name: '编辑所有人信息（查看详细产值）',
                key: 'employee_page_edit_all'
            },
        ]
    },
    {
        key:'project_page',
        page: '项目管理',
        access: [
            {
                name: '查看个人所属项目',
                key: 'project_page_view_own'
            },
            {
                name: '编辑个人所属项目',
                key: 'project_page_edit_own'
            },
            {
                name: '查看所有人项目',
                key: 'project_page_view_all'
            },
            
            {
                name: '编辑所有人项目（查看详细项目产值）',
                key: 'project_page_edit_all'
            },
        ]
    },
    {
        key:'department_page',
        page: '部门管理',
        access: [
            {
                name: '查看/编辑部门信息',
                key: 'department_page_edit'
            }
        ]
    },
    {
        key:'user_page',
        page: '用户管理',
        access: [
            {
                name: '查看/编辑用户管理',
                key: 'user_page_edit'
            }
        ]
    },
    {
        key:'access_page',
        page: '权限管理',
        access: [
            {
                name: '查看/编辑权限管理',
                key: 'access_page_edit'
            }
        ]
    },
]

const AccessPage = (props) =>{

    const [data, setData] = useState([])
    const [accessTableLoading, setAccessTableLoading] = useState(false)

    const [newRecord, setNewRecord] = useState({})
    const [showEditModal, setShowEditModal] = useState(false)
    const [isAddingNew, setIsAddingNew] = useState(false)
    const [confrimLoading, setConfrimLoading] = useState(false)

    const [error, setError] = useState({})
    const [deleteLoading, setDeleteLoading] = useState(false)
    

    useEffect(() => {
        loadAccess()
    }, [])

    const loadAccess = () => {
        setAccessTableLoading(true)
        setTimeout(async () => {

            try{
                const res = await withNotification(AccessController.getAllAccess(), '', false)
                const access = res.data.map(a => ({
                    ...a,
                    key: a.code,
                }))
                // console.log(access)
                setData(access)
                
            }catch(e){
                message.error(e.message)
            }finally{
                setAccessTableLoading(false)
            }

        });

    }

    const editClick = (record) => {
        // console.log(record)
        
        if (!record){
            setIsAddingNew(true)
            setNewRecord({})
        }else{
            setNewRecord({...record})
        }
        setShowEditModal(true)
    }

    const confirmClick = () => {

        // console.log(newRecord.uid)

        if(deleteLoading){
            setConfrimLoading(true)
            setTimeout(async () => {

                try{
                    await withNotification(AccessController.deleteAccess(newRecord.uid))
                    setShowEditModal(false)
                    setDeleteLoading(false)
                    loadAccess()
                }catch(e){
                    message.error(e.message)
                }finally{
                    setConfrimLoading(false)
                }
            })

            return
        }
        
        // console.log(newRecord, isAddingNew)

        if (!newRecord.name) {
            message.error('权限名称未填写，请检查')
            setError({error, name: true})
            return
        }

        if (!newRecord.code && newRecord.code !== 0) {
            message.error('权限码未填写，请检查')
            setError({error, code: true})
            return
        }

        setConfrimLoading(true)
        setTimeout(async () => {
            try{
            
                await withNotification(isAddingNew ? AccessController.addNewAccess(newRecord) 
                                                    : AccessController.updateAccessInfo(newRecord),
                                        isAddingNew ?  '添加新权限成功' : '更新权限成功')
                cancelEditClick()
                loadAccess()
            }catch(e){
                message.error(e.message)
            }finally{
                setConfrimLoading(false)
            }
        }, 1000)
    }

    const columns = [
        {
            title: '权限名称',
            dataIndex: 'name',
            width: 200
        },
        {
            title: '权限码',
            dataIndex: 'code'
        },
        {
            title: '备注',
            dataIndex: 'comment'
        },
        {
            title: '编辑',
            key: 'edit-access-key',
            fixed: 'right',
            width: 45,
            render: (_, record, idx) => <Link onClick={() => editClick(record)}> <EditOutlined /> </Link>,
        },
    ]

    
const editDetialColumns = [
        {
            title: '页面',
            dataIndex: 'page',
            width: 100,
        },
        {
            title: '权限',
            dataIndex: 'access',
            width: 200,
            render: (text, record) => {
                // console.log(record)
                return (
                    <div>
                        {
                            record.access.map(acc => {
                                return (
                                    <div key={record.key + acc.key} style={{display:'flex', justifyContent:'space-between'}}>
                                        {acc.name}
                                        <Switch checked={newRecord[acc.key]} onChange={(v) => {
                                            const rec = newRecord
                                            rec[acc.key] = v? 1: 0
                                            setNewRecord({...rec})
                                        }}  size="small" />
                                    </div>
                                )
                            })
                        }
                    </div>
                )
            }
        },
    ]


    const cancelEditClick = () => {
        if (deleteLoading){
            setDeleteLoading(false)
        }else{
            setShowEditModal(false)
            setIsAddingNew(false)
            setNewRecord({})
            setConfrimLoading(false)
        }
    }

    const deleteClick = () => {
        // console.log(newRecord)
        setDeleteLoading(true)
    }

    return (
        <div>

        <div style={{display: 'flex', justifyContent:'space-between', marginBottom:'1rem'}}>

            
            <div> </div>

            <div>
                <Button type='primary' onClick={()=>editClick()}>
                    <PlusOutlined />  添加
                </Button>
            </div>

        </div>

        <Table size='middle' loading={accessTableLoading} bordered
             columns={columns}
             dataSource={data}
         />


        <Modal visible={showEditModal} style={{marginTop:'-4rem'}}
            title={isAddingNew? '添加新权限': <div> 编辑权限 <Button loading={deleteLoading} style={{marginLeft:'1rem', backgroundColor:'white', color:'red'}} type='danger' onClick={deleteClick} > 删除 </Button> </div> }
            onCancel={cancelEditClick}
            onOk={confirmClick}
            confirmLoading={confrimLoading}
            width={618}
            destroyOnClose
            okType={deleteLoading? 'danger' : 'primary'}
            okText={deleteLoading? '确定' : ( isAddingNew? '添加': '保存')}
        >
            <div style={{display:'flex', alignItems:'baseline', marginTop:'-1rem'}} >
                <p>权限名称：</p>
                <Input status={error.name && 'error'} style={{width:'7rem', marginRight:'1rem'}} bordered={false} value={newRecord.name} onChange={(e) => setNewRecord({...newRecord, name: e.target.value})} />

                <p>权限码：</p>
                <InputNumber status={error.code && 'error'} min={0} style={{width:'4rem'}} bordered={false} value={newRecord.code} onChange={(v) => setNewRecord({...newRecord, code: v})} />

                <p>备注：</p>
                <TextArea bordered={false} style={{width:'13rem'}} rows={1} value={newRecord.comment} onChange={(e) => setNewRecord({...newRecord, comment: e.target.value})} />

            </div>

            {
                deleteLoading? 
                <div style={{display:'flex', justifyContent:'center', margin:'3rem'}}>
                    <h3>确认要删除该权限吗？</h3>
                </div>
                :
                <Table size='small'
                columns={editDetialColumns}
                dataSource={pages}
                pagination={false}
            />
            }
            


        </Modal>

        </div>
    )
}

export default AccessPage
