import React from 'react';
import './App.css';
import { Routes, Route } from "react-router-dom";
import Project from './pages/project/Project'
import ProjectsPage from './pages/project/Projects'
import Login from './pages/Login'
import Home from './pages/Home'
import AppLayout from './AppLayout';
import EmployeesPage from './pages/employee/Employees';
import Employee from './pages/employee/Employee';
import User from './pages/user/User'
import UsersAdminPage from './pages/user/Users'
import AccessPage from './pages/access/Access';
import DepartmentsPage from './pages/department/Departments';
import Department from './pages/department/Department';


function App() {

    return (
        <>

            <Routes>
                <Route path='/login' element={<Login/>} />
                <Route path='/' element={<AppLayout/>} >
                    <Route index element={<Home/>} />
                    <Route path="/projects" element={<ProjectsPage />} />
                    <Route path="/project/:id" element={<Project />} />
                    <Route path="/employees" element={<EmployeesPage />} />
                    <Route path="/employee/:code" element={<Employee />} />
                    <Route path="/users_admin" element={<UsersAdminPage />} />
                    <Route path="/user" element={<User />} />
                    <Route path="/access_admin" element={<AccessPage />} />
                    <Route path="/department_admin" element={<DepartmentsPage />} />
                    <Route path="/department/:code" element={<Department />} />
                </Route>
                <Route path="*" 
                    element={<>
                            <div style={{display:'flex', width: '100%', height: '4rem'}}>
                                <div style={{width:'100%', display:'flex', 
                                            alignItems:'center', background:'#001529',
                                            color:'white'}}>
                                    <div style={{marginLeft:'1rem', cursor: 'pointer', fontSize:'large'}}
                                        onClick={()=>window.location = '/'}>
                                            HDCPM 慧德绩效管理系统
                                    </div>
                                </div>

                            </div>

                            <div style={{backgroundColor: '#f0f2f5', height: 'calc(100vh - 4rem)',
                                        display:'flex', alignItems:'center', justifyContent:'center'}}>
                                <div style={{marginTop:'-9rem'}}>
                                    <div style={{display:'flex', alignItems:'center', justifyContent:'center', fontSize:'6rem'}}>
                                        <h2>
                                            404
                                        </h2>
                                    </div>
                                    <h2 style={{marginTop:'-4rem'}}>
                                        抱歉，您想访问的页面不存在
                                    </h2>
                                </div>
                            </div>
                               
                            </>}
                />
            </Routes>
        </>
    );
}

export default App;


