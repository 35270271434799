import React, { useEffect, useState, useContext } from 'react';
import { message, Table, Button, Typography, Tooltip, InputNumber } from 'antd'
import EmployeeController from '../../utils/employee';
import { withNotification } from '../../utils/notification';
import {
    DownloadOutlined, ReloadOutlined, QuestionCircleOutlined 
} from '@ant-design/icons';
import { UserContext } from '../../AppLayout';

const { Link } = Typography;

const Employee = (props) => {

    const user = useContext(UserContext)
    
    const currentEmployeeCode = window.location.pathname.split('/').reverse()[0]

    const [inEdit, setInEdit] = useState(false)

    const [infoTableLoading, setInfoTableLoading] = useState(false)
    const [detailTableLoading, setDetailTableLoading] = useState(false)
    const [infoData, setInfoData] = useState({key:currentEmployeeCode})
    const [newInfoData, setNewInfoData] = useState({key:currentEmployeeCode})
    const [detailData, setDetailData] = useState([])
    const [saveLoading, setSaveLoading] = useState(false)

    const initSum = {
        A: 0,
        b: 0,
        V0: 0,
        V: 0,
        V1: 0,
        V2: 0,
        V3: 0,
        V4: 0,
        V5: 0,
    }
    const [summary, setSummary] = useState(initSum)

    const [showComplete, setShowComplete] = useState(false)

    const [readOnly, setReadOnly] = useState(true)

    const [downloadLoading, setDownLoadLoading] = useState(false)

    useEffect(() => {

        if (user.access) {
            setReadOnly(user.access.employee_page_edit_all === 0)
            if (user.access.employee_page_edit_all === 1){
                setShowComplete(true)
            }
        }

        reloadClick()
    }, [])


    // 更新summary
    useEffect(() => {
        if (detailData.length > 0){
            // console.log(detailData)
            const sum = initSum
            detailData.forEach(d => {
                sum.A += d.A
                sum.b += d.b
                sum.V += d.v
                sum.V0 += d.v0
                sum.V1 += d.v1
                sum.V2 += d.v2
                sum.V3 += d.v3
                sum.V4 += d.v4
                sum.V5 += d.v5
            })
            setSummary(sum)
            console.log(sum)
        }
    }, [detailData])
    

    const loadInfoData = () => {
        setInfoTableLoading(true)

        setTimeout(async () => {
            try{
                const res = await withNotification(EmployeeController.getEmployees(currentEmployeeCode), '', false)
                // console.log(res.data)
                if (res.data && res.data.length === 1){
                    const data = res.data[0]
                    data.key = currentEmployeeCode
                    setInfoData({...data})
                    setNewInfoData(data)
                }else{
                    message.error('找不到当前员工信息')
                }
            }catch(e){
                message.error(e.message)
            }
            
            setInfoTableLoading(false)
        })

    }

    const loadDetailData = () => {
        setDetailTableLoading(true)

        setTimeout(async () => {
            try{
                const res = await withNotification(EmployeeController.getEmployeeProjectValuesArchivedByCode(currentEmployeeCode), '', false)
                // console.log(res.data)
                let curProjectCode = ''
                const major = []
                const position = []
                const data = res.data.map(d => {
                    const tmp_major = d.project_code + d.major
                    const tmp_pos = d.project_code + d.major + d.position
                    if (curProjectCode !== d.project_code){
                        d.mergeProjectName = false
                        d.majorMerge = false
                        d.positionMerge = false
                        curProjectCode = d.project_code
                        major.push(tmp_major)
                        position.push(tmp_pos)
                    }else{
                        d.mergeProjectName = true
                        if (!major.includes(tmp_major)){
                            major.push(tmp_major)
                            d.majorMerge = false
                        }else{
                            d.majorMerge = true
                        }
                        if (!position.includes(tmp_pos)){
                            position.push(tmp_pos)
                            d.positionMerge = false
                        }else{
                            d.positionMerge = true
                        }
                    }
                    d.key = d.uid
                    return d
                })

                console.log(data)

                setDetailData(data)
            }catch(e){
                message.error(e.message)
            }
            
            setDetailTableLoading(false)
        }, 1000)
    }

    const reloadClick = () => {
        loadInfoData()
        loadDetailData()
    }


    const infoColumns = [
        {
            title: '工号',
            dataIndex: 'code',
            width: 80,
            fixed: 'left'
        },
        {
            title: '姓名',
            dataIndex: 'name',
            fixed: 'left'
        },
        {
            title: '工位',
            dataIndex: 'position_code'
        },
        {
            title: '技能系数',
            dataIndex: 'x3'
        },
        {
            title: '入职日期',
            dataIndex: 'entry_date'
        },
        {
            title: '毕业日期',
            dataIndex: 'graduation_date'
        },
        {
            title: '备注',
            dataIndex: 'comment',
            ellipsis: true,
            width: showComplete? 120: 400
        },
    ]

    const infoColumns2 = [
        {
            title: <>全年工资 <Tooltip title='实际已发工资总额'> <QuestionCircleOutlined /> </Tooltip></>,
            dataIndex: 'full_salary',
            width: inEdit? 105: 100,
            render: (text) =>{
                if (inEdit){
                    return (
                        <InputNumber min={0} value={text} onChange={(v) => setNewInfoData({...newInfoData, full_salary:v})} />
                    )
                }
                return text
            }
        },
        {
            title: '技能工资',
            dataIndex: 'skill_salary',
            width: inEdit? 105: 100,
            render: (text) =>{
                if (inEdit){
                    return (
                        <InputNumber min={0} value={text} onChange={(v) => setNewInfoData({...newInfoData, skill_salary:v})} />
                    )
                }
                return text
            }
        },
        {
            title: '技能产值',
            key: 'v4',
            render: () => summary.V4 || 0
        },
        {
            title: '年终奖',
            key: 'final_prize',
            render: (text, record) => summary.V4 - record.skill_salary
        },
        {
            title: '全年总工资',
            key: 'total_salary',
            render: (text, record) => record.full_salary + summary.V4 - record.skill_salary
        },
        {
            title: '月平均工资',
            key: 'avg_salary',
            render: (text, record) => ((record.full_salary + summary.V4 - record.skill_salary) / 12).toFixed(2)
        },
    ]

    const columns = [
        {
            title: '项目名称',
            dataIndex: 'project_name',
            width:200,
            fixed:'left',
            render: (text, record) => <Link href={'/project/' + record.project_code} target="_blank"> {text} </Link>,
            onCell: (record, index) => {
                const cell = {}
                cell.rowSpan = record.mergeProjectName ? 0 : detailData.filter(r=>r.project_code === record.project_code).length
                return(cell)
            }

        },
        {
            title: '专业',
            dataIndex: 'major',
            width: 45,
            fixed:'left',
            ellipsis:true,
            render: (text, record) => {
                if (!record.major){
                    return record.position
                }
                return text
            },
            onCell: (record, index) => {
                const cell = {}
                if (record.major){
                    cell.rowSpan = record.majorMerge ? 0 : detailData.filter(r=>r.project_code + r.major ===  record.project_code + record.major).length
                }else{
                    cell.colSpan = 2
                }
                return(cell)
            }
        },
        {
            title: '分工',
            dataIndex: 'position',
            width: 90,
            fixed:'left',
            ellipsis:true,
            onCell: (record, index) => {
                const cell = {}
                if (record.major){
                    cell.rowSpan = record.positionMerge ? 0 : detailData.filter(r=>r.project_code === record.project_code && r.major === record.major && r.position === record.position).length
                }else{
                    cell.colSpan = 0
                }
                return(cell)
            }
        },
        {
            title: '单位工程',
            dataIndex: 'work',
            width: 80,
            fixed:'left',
            ellipsis:true,
        },
        {
            title: '完成造价',
            dataIndex: 'A',
            width: 100,
            fixed:'left',
            ellipsis:true,
        },
        {
            title: '审减金额',
            dataIndex: 'b',
            ellipsis:true,
            width: 120,
        },
        {
            title: '完成造价比',
            dataIndex: 'A_perc',
            width: 90,
            ellipsis:true,
            render: (text, record) =>{
                if (record.major){
                    return (text * 100).toFixed(2) + '%'
                } return ''
            }
        },
        {
            title: '难易系数',
            dataIndex: 'x1',
            width: 90,
        },
        {
            title: '产值系数',
            dataIndex: 'x2',
            width: 90,
        },
        {
            title: '质量系数',
            dataIndex: 'x5',
            width: 90,
        },
        
    ]

    const column2 = [
        {
            title: '原始产值 V0',
            dataIndex: 'v0',
            width:100,
            ellipsis:true,
        },
        {
            title: '项目产值 V',
            dataIndex: 'v',
            width:100,
            ellipsis:true,
        },
        {
            title: '员工产值 V1',
            dataIndex: 'v1',
            width:100,
            ellipsis:true,
        },
        // {
        //     title: '技能系数 X3',
        //     dataIndex: 'x3',
        //     width:100,
        // },
        // {
        //     title: '提升系数 x4',
        //     dataIndex: 'x4',
        //     width:100,
        // },
        {
            title: '员工产值 v2',
            dataIndex: 'v2',
            width:100,
            ellipsis:true,
        },
        {
            title: '附加产值 V3',
            dataIndex: 'v3',
            width:100,
            ellipsis:true,
        },
        {
            title: '员工产值 V4',
            dataIndex: 'v4',
            width:100,
            ellipsis:true,
        },
        {
            title: '质量扣除产值 V5',
            dataIndex: 'v5',
            width:130,
            ellipsis:true,
        },
    ]


    const columnsEnd = [
        {
            title: '时长（小时）',
            dataIndex: 'hours',
            width: 120,
        },
        {
            title: '备注',
            dataIndex: 'comment',
            width: 200,
            ellipsis: true,
        },
    ]

    const editClick = () => {
        setInEdit(true)
    }

    const saveClick = () => {
        setSaveLoading(true)
        console.log(newInfoData)

        setTimeout(async () => {
            try{

                await withNotification(EmployeeController.updateEmployee(newInfoData), '更新成功') 

                setInEdit(false)
            }catch(e){
                message.error(e.message)
            }
            setSaveLoading(false)
        })
    }

    const cancelClick = () => {
        setNewInfoData({...infoData})
        setInEdit(false)
    }

    const downloadClick = () => {
        console.log(infoData.code)
        // return
        setDownLoadLoading(true)
        
        setTimeout(async () => {
            try{
                await EmployeeController.downloadEmployeeProjectValuesXlsx(infoData)
            }catch(e){
                message.error(e.message)
            }
            setDownLoadLoading(false)

        })

    }

    return (
        <div>
            <div style={{display:'flex', margin:'.5rem 0', justifyContent:'space-between'}}>
                <div style={{marginLeft: '.1rem'}}>
                    <h3>员工产值表</h3>
                </div>
                <div style={{display:'flex'}}>
                    <Button type='ghost' onClick={reloadClick}>
                        <ReloadOutlined />
                    </Button>
                    {
                        !readOnly && 
                        <>
                        <Button style={{margin: '0 1rem'}}  loading={downloadLoading} onClick={downloadClick}>
                            <DownloadOutlined />下载
                        </Button>
                        {
                            showComplete && 
                            <>
                            <Button loading={saveLoading} type='primary' onClick={inEdit? saveClick : editClick}>
                                {inEdit? '保存': '编辑'} 
                            </Button>
                            {
                                inEdit && 
                                <Button style={{marginLeft:'1rem'}} onClick={cancelClick}>
                                    取消
                                </Button>
                            }
                            </>
                        }
                        </>
                    }

                </div>
            </div>
            <div style={{marginTop:'0rem', paddingBottom:'1rem'}}>
                <Table id='employee-info-table' bordered
                    size='small'
                    loading={infoTableLoading}
                    columns={showComplete? infoColumns.concat(infoColumns2) : infoColumns}
                    dataSource={[newInfoData]}
                    pagination={false}
                    scroll={{
                        x: showComplete? 1500: 1000,
                        // y: 100
                    }}
                />
                <Table id='employee-detail-table'
                    size='small'
                    bordered
                    loading={detailTableLoading}
                    columns={showComplete? columns.concat(column2).concat(columnsEnd): columns.concat(columnsEnd)}
                    dataSource={detailData}
                    pagination={false}
                    scroll={{
                        x: showComplete? 2300: 1500,
                        // y: tableHeight
                    }}

                    summary={(pageData) => {

                        return (
                            <Table.Summary fixed>
                              <Table.Summary.Row>
                                <Table.Summary.Cell className="detail-summary-cell" fixed={'left'} colSpan={5} index={0}>
                                    <div style={{display:'flex', justifyContent:'space-between'}}>
                                        <div>
                                            小计
                                        </div>
                                        
                                        <div style={{marginRight:'1rem'}}>
                                            {summary.A/10000} (万)
                                        </div>
                                    </div>   
                                </Table.Summary.Cell>
                                <Table.Summary.Cell className="detail-summary-cell-rest" colSpan={1} index={2}>
                                    <div style={{display:'flex', justifyContent:'space-between', zIndex:'-1100'}}>
                                        <div>
                                            {summary.b/10000} (万)
                                        </div>
                                    </div>
                                </Table.Summary.Cell>
                                {
                                    showComplete ?
                                    <>
                                    <Table.Summary.Cell className="detail-summary-cell-rest" colSpan={4} />
                                    <Table.Summary.Cell className="detail-summary-cell-rest" colSpan={1} index={2}>
                                        <div style={{display:'flex', justifyContent:'space-between', zIndex:'-1100'}}>
                                            <div>
                                                {summary.V0/10000} (万)
                                            </div>
                                        </div>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell className="detail-summary-cell-rest" colSpan={1} index={2}>
                                        <div style={{display:'flex', justifyContent:'space-between', zIndex:'-1100'}}>
                                            <div>
                                                {summary.V/10000} (万)
                                            </div>
                                        </div>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell className="detail-summary-cell-rest" colSpan={1} index={2}>
                                        <div style={{display:'flex', justifyContent:'space-between', zIndex:'-1100'}}>
                                            <div>
                                                {summary.V1/10000} (万)
                                            </div>
                                        </div>
                                    </Table.Summary.Cell>
                                    {/* <Table.Summary.Cell className="detail-summary-cell-rest" colSpan={2} /> */}
                                    <Table.Summary.Cell className="detail-summary-cell-rest" colSpan={1} index={2}>
                                        <div style={{display:'flex', justifyContent:'space-between', zIndex:'-1100'}}>
                                            <div>
                                                {summary.V2/10000} (万)
                                            </div>
                                        </div>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell className="detail-summary-cell-rest" colSpan={1} index={2}>
                                        <div style={{display:'flex', justifyContent:'space-between', zIndex:'-1100'}}>
                                            <div>
                                                {summary.V3/10000} (万)
                                            </div>
                                        </div>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell className="detail-summary-cell-rest" colSpan={1} index={2}>
                                        <div style={{display:'flex', justifyContent:'space-between', zIndex:'-1100'}}>
                                            <div>
                                                {summary.V4/10000} (万)
                                            </div>
                                        </div>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell className="detail-summary-cell-rest" colSpan={1} index={2}>
                                        <div style={{display:'flex', justifyContent:'space-between', zIndex:'-1100'}}>
                                            <div>
                                                {summary.V5/10000} (万)
                                            </div>
                                        </div>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell className="detail-summary-cell-rest" colSpan={2} />
                                    </>
                                    :
                                    <Table.Summary.Cell className="detail-summary-cell-rest" colSpan={6} />
                                }
                                
                              </Table.Summary.Row>
                            </Table.Summary>
                          )
                    }}
                />
            </div>
        </div>
    )
}


export default Employee
