import React from 'react'
import { useState, useEffect, useNavigate } from 'react'
import { Modal, Input, InputNumber, Button, message } from 'antd'
import ProjectController from '../../../utils/project'
import { Skeleton } from 'antd';
import { withNotification } from '../../../utils/notification'
import ProjectUpload from './ProjectUpload'
import '../../../utils/utils'
import { useRef } from 'react';
import { UserContext } from '../../../AppLayout';
import { useContext } from 'react';

const ProjectEditModal = (props) => {

    const user = useContext(UserContext)

    const [ loading, setLoading ] = useState(true)

    const { fullAccess, visible, setVisible, record, onSave, isNew, setCurrentRecords=false} = props

    const [modalTitle, setModalTitle] = useState('')

    const [confirmLoading, setConfirmLoading] = useState(false)

    const [uploadFileList, setUploadFileList] = useState([])

    const [newRecord, setNewRecord] = useState({})

    const records = useRef()

    useEffect(() => {
        console.log(fullAccess)
        setLoading(true)
        setUploadFileList([])
        if (visible && !isNew){
            if (setCurrentRecords){
                setModalTitle('上传项目信息')
            }else{
                setModalTitle('编辑项目信息')
            }
            setNewRecord({...record, key: record.uuid})
            setTimeout(() => {
                setLoading(false)
            }, 200)
        }else if (visible && isNew){
            setModalTitle('上传项目信息')
            setNewRecord({})
            setLoading(false)
        }

        // console.log('modal visibility changed', record)
    }, [visible])

    const handleOk = () => {

        if (setCurrentRecords){
            setVisible(false);
            return
        }

        // setModalText('The modal will be closed after two seconds');
        setConfirmLoading(true);

        if(isNew){
            setTimeout(async () => {
                const info = {...newRecord,
                    fill_time: record.fill_time || (new Date()).Format("yyyy-MM-dd HH:mm:ss"),
                    fill_person: user.name}
                console.log(info, records.current)
                try{
                    await withNotification(ProjectController.addNewProject(info, records.current))
                    setVisible(false);
                }catch(e){
                    message.error(e.message || '添加新项目出现问题，请检查')
                }
                onSave()
                setConfirmLoading(false);
            }, 0)
        }else{
            setTimeout(async () => {
                const info = {...newRecord, fill_person: user.name,
                    fill_time: (new Date()).Format("yyyy-MM-dd HH:mm:ss")}
                try{
                    if (uploadFileList.length > 0){
                        // console.log(newRecord, records.current)
                        await withNotification(ProjectController.addNewProject(info, records.current))
                    }else{
                        await withNotification(ProjectController.updateProject(info))
                    }
                }catch(e){
                    message.error(e.message)
                }
                onSave(info)
                setVisible(false);
                setConfirmLoading(false);

            }, 0);
        }
        
    };

    const handleCancel = () => {
        // console.log('Clicked cancel button');
        setUploadFileList([])
        setConfirmLoading(false);
        setVisible(false);
    };


    return (
        <>
            <Modal
                title={modalTitle}
                visible={visible}
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
                okText={setCurrentRecords? "确定":"保存"}
                cancelText="取消"
                width={setCurrentRecords?500: 1000}
            >

                {
                    !setCurrentRecords?
                <div style={{display:'flex'}}>
                    
                    <div style={{width: '70%'}}>
                    {
                        loading?
                        <Skeleton style={{paddingRight:'1rem'}} active 
                            paragraph={{
                                rows: 6,
                            }}
                        />
                        :
                        <div>
                            <div style={{display:'flex', width:'100%', margin:'0 auto', alignItems:'center'}}>
                                <div>项目名称：</div> <Input value={newRecord.name} style={{width:'80%'}} onChange={(e) => setNewRecord({...newRecord, name:e.target.value})}></Input>    
                            </div>
                            <div style={{display:'flex', width:'100%', margin:'1rem auto', alignItems:'center'}}>
                                <div>项目编号：</div> <Input value={newRecord.code} style={{width:'30%', marginRight:'1rem'}} onChange={(e) => setNewRecord({...newRecord, code:e.target.value})}></Input>    
                                <div>负责人：</div> <Input value={newRecord.owner} style={{width:'5rem', marginRight:'1rem'}} onChange={(e) => setNewRecord({...newRecord, owner:e.target.value})}></Input>  
                            </div>
                            <div style={{display:'flex', width:'100%', margin:'1rem auto', alignItems:'center'}}>
                                <div>咨询类别：</div> <Input value={newRecord.type} style={{width:'80%'}} onChange={(e) => setNewRecord({...newRecord, type:e.target.value})}></Input>
                            </div>

                            {
                                fullAccess && 
                                <div style={{display:'flex', width:'100%', margin:'1rem auto', alignItems:'center'}}>
                                    <div>咨询费：</div> <InputNumber value={newRecord.fee} style={{width:'20%', marginRight:'1rem'}} onChange={(e) => setNewRecord({...newRecord, fee:e})}></InputNumber>  
                                    <div>已收咨询费：</div> <InputNumber value={newRecord.fee_collected} style={{width:'20%', marginRight:'1rem'}} onChange={(e) => setNewRecord({...newRecord, fee_collected:e})}></InputNumber>  
                                    <div>应收咨询费：</div> <div> {(newRecord.fee && newRecord.fee_collected) ? parseFloat(newRecord.fee) - parseFloat(newRecord.fee_collected) : 0} </div>
                                </div>
                            }
                            
                            <div style={{display:'flex', width:'100%', margin:'1rem auto', alignItems:'center'}}>
                                <div style={{marginRight:'1rem'}}>修改人：{newRecord.fill_person} </div> 
                                <div>填表/修改时间：</div> <div> {newRecord.fill_time} </div> 
                            </div>
                        </div>
                    }
                    </div>
                    <div style={{width:'30%'}}>
                        <ProjectUpload {...{uploadFileList, setUploadFileList, isNew, record, setLoading, newRecord, setNewRecord, records, setCurrentRecords }}/>
                    </div>
                </div>
                :
                <ProjectUpload {...{uploadFileList, setUploadFileList, isNew, record, setLoading, newRecord, setNewRecord, records, setCurrentRecords }}/>
                }

            </Modal>
        </>
    );
};

export default ProjectEditModal;