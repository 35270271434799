import { notification, message } from 'antd';

// handle error notification, will show successMessage only if it is provided.
export const withNotification = (request, successMessage, showNotification=true) => {
    return new Promise((resolve, reject) =>{
        request.then(res => {
            if (res.data.code === -1){
                message.info('用户令牌失效, 请刷新页面重新登录')
                setTimeout(() => {
                    window.sessionStorage.setItem('prevURL', window.location.pathname)
                    window.location = '/login'
                }, 1300)
                // resolve(-1)
            }else if (res.data.code === 1){
                if (showNotification){
                    message.success(successMessage || '成功')
                }
                resolve(res.data)
            }else{
                // notification['error']({
                //     message: res.data.msg || res.data.message,
                //     description: res.data.detail? res.data.detail:'如有更多问题，请联系管理员',
                //     placement:'top',
                //     duration: 2.5
                // });
                const error = res.data
                error.message = res.data.msg || res.data.message
                reject(error)
            }
        }).catch(e => {
            console.log(e, e.response)
            reject(e)
        })
    })
}

