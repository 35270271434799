import React from 'react';
import { useEffect, useState, useRef } from 'react';
import EmployeeController from '../../utils/employee';
import { withNotification } from '../../utils/notification';
import { Button, Input, Table, Tooltip, Modal, Typography, message, Spin } from 'antd';
import EmployeeEditModal from './component/EmployeeEditModal';
import { getTableScroll } from '../../utils/calculateHeight';
import moment from 'moment';
import {
    PlusOutlined, EditOutlined, DownloadOutlined, SaveOutlined,
    UploadOutlined, ReloadOutlined, QuestionCircleOutlined, LoadingOutlined
} from '@ant-design/icons';
import EmployeeUpload from './component/EmployeeUpload';
import MessageModal from '../component/MessageModal';
import { UserContext } from '../../AppLayout';
import { useContext } from 'react';

const { Link } = Typography;
const { Search } = Input;


const EmployeesPage = () => {
    const user = useContext(UserContext)
    const [isNew, setIsNew] = useState(false)
    const [uploadData, setUploadData] = useState({sheet1:[], sheet2:[]})
    const [showEmployeeUpload, setShowEmployeeUpload] = useState(false)
    const [uploadFileList, setUploadFileList] = useState([])
    const [uploadLoading, setUploadLoading] = useState(false)
    const [saveNewLoading, setSaveNewLoading] = useState(false)
    const [saveConfirmVisible, setSaveConfirmVisible] = useState(false)

    const [loading, setLoading] = useState(true)
    const [table2Loading, setTable2Loading] = useState(false)
    const [tableHeight, setTableHeight] = useState('')

    const [showTable2, setShowTable2] = useState(false)
    const [employees, setEmployees] = useState('')
    const [employeeSkillRate, setEmployeeSkillRate] = useState([])
    const [newRecord, setNewRecord] = useState({})
    const [visible, setVisible] = useState(false)
    const [isAddingRecord, setIsAddingRecord] = useState(false)

    const [downloadLoading, setDownloadLoading] = useState(false)

    const [column2, setColumn2] = useState([])
    

    const searchValue = useRef('')
    const [searchLoading, setSearchLoading] = useState(false)
    const columnYears = useRef([])
    const maxPositionCode = useRef(-1)

    const [readOnly, setReadOnly] = useState(true)

    const [downloadAllLoading, setDownloadAllLoading] = useState(false)

    useEffect(() => {

        if (user.access){
            if (user.access.employee_page_edit_all === 1) {
                setReadOnly(false)
            }
        }

        setTimeout(async () => {
            reloadClick()
            
        }, 0)
    }, [])

    const loadEmployeeInfo = () => {

        setLoading(true)

        setTimeout(async () => {
            try {
                let maxPos = 0
                const res = await withNotification(EmployeeController.getEmployees(), '', false);

                const data = res.data.filter((d) => {
                    if (d.position_code > maxPos) maxPos = d.position_code
                    return (d.name && d.name.includes(searchValue.current))
                }).map((d) => {
                    return {
                        ...d,
                        key: d.uid,
                        graduation_date: d.graduation_date.split('T')[0],
                        entry_date: d.entry_date.split('T')[0]
                    }
                })
                maxPositionCode.current = maxPos
                // console.log(data)
                setEmployees(data)
            } catch (e) {
                message.error(e.message)
                console.log(e)
            }finally{
                setLoading(false)
                const tableHeight = getTableScroll({ extraHeight: 60, id: 'employee-table' })
                console.log(tableHeight)
                setTableHeight(tableHeight)
            }
        }, 0)
    }

    const loadEmployeeSkillRate = () => {

        setTable2Loading(true)

        setTimeout(async () => {
            try {
                const res = await withNotification(EmployeeController.getEmployeeSkillRate(), '', false);
                // console.log(res)
                console.log(res.data)
                let skillYears = []
                const codes = []
                const skillRateData = []
                res.data.filter((d) => d.name && d.name.includes(searchValue.current)).forEach((d, id) => {
                    if (!skillYears.includes(d.year)) skillYears.push(d.year)
                    if (!codes.includes(d.code)) {
                        codes.push(d.code)
                        const curEmployee = {
                            code: d.code,
                            name: d.name,
                            key: d.code
                        }
                        curEmployee[d.year] = d.skill
                        skillRateData.push(curEmployee)
                        // console.log(id, d.year)
                    } else {
                        skillRateData[codes.indexOf(d.code)][d.year] = d.skill
                    }
                })

                columnYears.current = skillYears.sort((a, b) => a - b)

                const col2 = calculateTable2Columns()
                setEmployeeSkillRate(skillRateData.map((d,index) => {
                    return {
                        ...d,
                        index: index+1
                    }
                }))
                setColumn2(col2)
                // console.log([col2, skillRateData])
            } catch (e) {
                message.error(e.message)
                console.log(e)
            }finally{
                setTable2Loading(false)
            }
        }, 0)
    }

    const calculateTable2Columns = () => {
        const column2Base = [
            {
                title: '序号',
                dataIndex: 'index',
                width: 50,
                fixed: 'left',
                sorter: (a, b) => a.code - b.code,
                // sortDirections: ['descend'],
            },
            {
                title: '姓名',
                dataIndex: 'name',
                width: 100,
                fixed: 'left',
            },
        ]

        columnYears.current.forEach(y => {
            column2Base.push({
                title: y,
                dataIndex: y,
                width: 120,
                render: (text) => text? text: '无数据'
            })
        })

        setColumn2(column2Base)
        return column2Base
    }

    const showTable2Click = () => {
        
        setShowTable2(true)

        if (!isNew){
            loadEmployeeSkillRate()
        }

    }

    const searchClick = (value) => {
        console.log(value)
        setSearchLoading(true)
        setLoading(true)
        searchValue.current = value
        setTimeout(async () => {
            await loadEmployeeInfo()
            setLoading(false)
            setSearchLoading(false)
        }, 0)
    }

    const column1 = [
        {
            title: '工号',
            dataIndex: 'code',
            width: 80,
            fixed: 'left',
            sorter: (a, b) => a.code - b.code,
            // sortDirections: ['descend'],
        },
        {
            title: '姓名',
            width: 100,
            dataIndex: 'name',
            fixed: 'left',
            render: (text, record) => <Link href={'/employee/' + record.code} target="_blank"> {text} </Link>
        },
        {
            title: '工位',
            width: 80,
            dataIndex: 'position_code',
            sorter: (a, b) => a.position_code - b.position_code,
            // sortDirections: ['descend'],
        },
        {
            title: '部门名称',
            width: 120,
            dataIndex: 'department',
            ellipsis: true,
        },
        {
            title: '技能系数',
            width: 110,
            dataIndex: 'x3',
            sorter: (a, b) => a.x3 - b.x3,
            // sortDirections: ['descend'],
            render: (text) => text * 100 + '%'
        },
        {
            title: '企业工龄',
            key: 'working_age',
            width: 110,
            sorter: (a, b) => a.workingAge - b.workingAge,
            // sortDirections: ['descend'],
            render: (text, record, index) => {

                const now = new Date()
                const entryTime = new Date(record.entry_date)
                const workingAge = now.getFullYear() - entryTime.getFullYear()

                return (
                    <>{workingAge}</>
                )
            }
        },
        {
            title: '工龄',
            key: 'grad_age',
            width: 80,
            sorter: (a, b) => a.gradAge - b.gradAge,
            // sortDirections: ['descend'],
            render: (text, record, index) => {

                const now = new Date()
                const gradTime = new Date(record.graduation_date)
                const gradAge = now.getFullYear() - gradTime.getFullYear()
                return (
                    <>{gradAge || '无'}</>
                )
            }
        },
        {
            title: '入职日期',
            dataIndex: 'entry_date',
            width: 120,
        },
        {
            title: '毕业日期',
            dataIndex: 'graduation_date',
            width: 120,
        },
        {
            title: '备注',
            dataIndex: 'comment',
            width: 130,
        },
        {
            title: '状态',
            dataIndex: 'status',
            width:70,
            filters: [
                {
                    text: '在职',
                    value: 'Y',
                },
                {
                    text: '离职',
                    value: 'N',
                },
            ],
            render: (text) => <>{text === 'Y' ? '在职' : '离职'}</>,
            onFilter: (value, record) => record.status === value,
            defaultFilteredValue: ['N'],
            filterResetToDefaultFilteredValue: true, // 点击重置按钮时，恢复默认值
            filtered: true,
            defaultFilteredValue: ['Y']
        },
    ]

    const downloadProjectValue = (record) =>{
        setTimeout(async () => {
            try{
                await EmployeeController.downloadEmployeeProjectValuesXlsx(record)
            }catch(e){
                message.error(e.message)
            }
        })
    }

    const downloadProjectValueAll = () => {
        setDownloadAllLoading(true)
        setTimeout(async () => {
            try{
                await EmployeeController.downloadEmployeeProjectValuesAllXlsx()
            }catch(e){
                message.error(e.message)
            }
            setDownloadAllLoading(false)
        })
    }

    const column1Edit = [
        {
            title: (<div style={{display:'flex'}}>
                        编辑 
                        {downloadAllLoading? 
                            <Spin style={{marginLeft:'.5rem'}} indicator={<LoadingOutlined />} />
                            :
                            <Link style={{marginLeft:'.5rem'}} onClick={downloadProjectValueAll}> 
                                <Tooltip title='打包下载在职员工产值汇总'> <DownloadOutlined /> </Tooltip>
                            </Link>}
                    </div>),
            key: 'edit-info-key',
            fixed: 'right',
            width: 75,
            render: (_, record, idx) => (
                <div style={{display:'flex'}}>
                    <Link onClick={() => editClick(record)}> <EditOutlined /> </Link>
                    <Link style={{marginLeft:'1.3rem'}} onClick={() => downloadProjectValue(record)}> 
                            <Tooltip title={`下载${record.name}的产值汇总`}> <DownloadOutlined /> </Tooltip>
                    </Link>
                </div>
            ),
        },
    ]

    const editClick = (record) => {
        if (record) {
            setIsAddingRecord(false)
            setNewRecord({ ...record })
        } else {
            setIsAddingRecord(true)
            const today = moment(new Date()).format().split('T')[0]

            setNewRecord({
                uid: '',
                position_code: maxPositionCode.current+1,
                name: '',
                graduation_date: today,
                entry_date: today,
                comment: '',
                status: 'Y',
                workingAge: 0,
                gradAge: 0,
                x3: 0,
            })
        }

        setVisible(true)
    }

    const reloadClick = () => {
        loadEmployeeInfo()
    }

    const handleSave = () => {
        reloadClick()  
    }

    const downloadClick = () => {
        setDownloadLoading(true)
        setTimeout(async () => {
            await EmployeeController.downloadEmployeeXlsx()
            setDownloadLoading(false)
        }, 0)
    }

    const handleEmployeeUploadOk = () => {
        setIsNew(true)
        console.log(uploadData)
        const col2 = calculateTable2Columns()
        setEmployees(uploadData.sheet1)
        const skillRate = uploadData.sheet2 || []
        setEmployeeSkillRate(skillRate.map((d,index) => {
            return {
                ...d,
                index: index+1
            }
        }))
        setColumn2(col2)

        setShowEmployeeUpload(false)
    }

    const handleEmployeeUploadCancel = () => {
        setUploadFileList([])
        setShowEmployeeUpload(false)
        setIsNew(false)
        setUploadData({sheet1:[], sheet2:[]})
    }

    const saveNewEmployeesClick = () => {
        setSaveNewLoading(true)

        // console.log(employeeSkillRate, columnYears.current)

        // 处理一下employeeSkillRate
        // [{code: xx, name:xx, 2019:xx, 2020:xx}, ...]
        // ====> [{code:xx, name:xx, skill:xx, year:xx}, ...]
        const newEmployeeSkillRate = []
        employeeSkillRate.forEach(e => {
            columnYears.current.forEach(y => {
                try{
                    const x3 = parseFloat(e[y])
                    if (x3 >= 0 && x3 <= 1){
                        newEmployeeSkillRate.push({
                            code: e.code,
                            name: e.name,
                            skill: x3,
                            year: y
                        })
                    }
                }catch(e){
                    console.log(e, e)
                }
            })
        })

        console.log(newEmployeeSkillRate)
        

        setTimeout(async () => {
            try{
                const res = await withNotification(EmployeeController.uploadEmployeeAndSkillrateFromFile(employees, newEmployeeSkillRate), '', false)
                console.log(res)
                message.success('更新成功!')
            }catch(e){
                message.error(e.message)
            }
            setUploadFileList([])
            setSaveNewLoading(false)
            setIsNew(false)
            reloadClick()
        }, 3000)

    }

    const cancelSaveNewEmployeesClick = () => {
        setUploadFileList([])
        setIsNew(false)
        reloadClick()
        setUploadData({sheet1:[], sheet2:[]})
    }

    return (
        <div>
            {
                !readOnly &&
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '1rem' }}>

                    <div style={{ display: 'flex', alignItems: 'center' }}>

                        <Button style={{ marginRight: '1rem' }} onClick={showTable2Click}>
                            技能系数
                        </Button>

                        <Search allowClear placeholder='搜索姓名' style={{ marginRight: '1rem' }} enterButton loading={searchLoading}
                                onSearch={searchClick} />

                        <Button style={{ marginRight: '1rem' }} type='ghost' onClick={reloadClick}>
                            <ReloadOutlined />
                        </Button>

                    </div>
                    <div>
                        <Button style={{ marginRight: '1rem' }} onClick={() => setShowEmployeeUpload(true)}>
                            <UploadOutlined /> 上传
                            <Tooltip title='建议下载后修改再重新上传'>
                                <QuestionCircleOutlined />
                            </Tooltip>
                        </Button>
                        <Button style={{ marginRight: '1rem' }} onClick={downloadClick} loading={downloadLoading}>
                            <DownloadOutlined />下载
                        </Button>
                        {
                            !isNew &&
                            <>
                                {/* note: onclick must NOT change to onClick={editClick}, which will give editClick an input value */}
                                <Button style={{ marginRight: '0' }} type="primary" onClick={() => editClick()}> 
                                    <PlusOutlined /> 添加
                                </Button>
                            </>
                        }
                        {
                            isNew && 
                            <>
                                <Button style={{ marginRight: '1rem' }} type="primary" loading={saveNewLoading}
                                        onClick={() => setSaveConfirmVisible(true)}>
                                    <SaveOutlined /> 保存
                                </Button>
                                <Button style={{ marginRight: '0' }} onClick={cancelSaveNewEmployeesClick}>
                                    取消
                                </Button>
                            </>
                        }
                    </div>

                </div>
            }

            <Table id='employee-table' bordered
                size='middle'
                loading={loading}
                columns={readOnly ? column1 : column1.concat(column1Edit)}
                dataSource={employees}
                pagination={{ defaultPageSize: 20, hideOnSinglePage: true, showSizeChanger: true }}
                scroll={{
                    x: 1000,
                    y: tableHeight
                }}
                onChange={(pagination, filters, sorter, extra) => {
                    console.log('params', pagination, filters, sorter, extra);
                }}
            />

            <EmployeeEditModal {...{ visible, setVisible, newRecord, setNewRecord, handleSave, isAddingRecord }} />

            <Modal
                title={'上传员工信息'}
                visible={showEmployeeUpload}
                onOk={handleEmployeeUploadOk}
                onCancel={handleEmployeeUploadCancel}
                okText="确定"
                cancelText="取消"
                width={600}
            >
                <div>
                    <EmployeeUpload {...{uploadFileList, setUploadFileList, uploadLoading, setUploadLoading, setUploadData, columnYears}} />
                </div>
            </Modal>

            <Modal visible={showTable2}
                title={'在职员工往年技能系数'}
                width={700}
                onCancel={() => setShowTable2(false)}
                onOk={() => setShowTable2(false)}
                style={{marginTop:'-2em'}}
            >
                <div>
                    <Table id='employee-skill-rate-history-table' bordered
                        loading={table2Loading}
                        style={{marginBottom:'0rem'}}
                        size='small'
                        columns={column2}
                        dataSource={employeeSkillRate}
                        pagination={false}
                        scroll={{
                            y: 300
                        }}
                    />
                </div>

            </Modal>

            <MessageModal visible={saveConfirmVisible} setVisible={setSaveConfirmVisible} okText='确定覆盖' handleConfirm={saveNewEmployeesClick}
                    title='更新员工信息' message={<><strong>确定要覆盖已有员工信息吗 (包括历史产值系数)?</strong> <br /> 建议下载备份后在上传新信息！</>}
            />

        </div>
    )


}


export default EmployeesPage
