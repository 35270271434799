import { DownOutlined, MenuOutlined, MoreOutlined, UserOutlined, FullscreenOutlined, FullscreenExitOutlined } from '@ant-design/icons';
import { Space, Layout, Menu, Dropdown, Spin, message } from 'antd';
import React, { createContext } from 'react';
import { Outlet } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import LoginController from './utils/login'
import { useEffect, useState } from 'react'
import { withNotification } from './utils/notification';
import './App.css'

export const FullScreenContext = createContext()
export const UserContext = createContext()

const { Content, Sider } = Layout;

const AppLayout = () => {

    const navigate = useNavigate()

    const [spin, setSpin] = useState(true)
    const [showMenu, setShowMenu] = useState(true)

    const [collapsed, setCollapsed] = useState(false);
    const [fullScreen, setFullScreen] = useState(false);
    const [user, setUser] = useState({})

    const [hideAll, setHideAll] = useState(true)

    const [selectedKey, setSelectedKey] = useState('')

    const menuItems = [
        {
            key: '/employees',
            label: '员工产值'
        },
        {
            key: '/projects',
            label: '项目管理'
        },
        {
            key: '/user',
            label: '个人中心'
        },
    ]

    const departmentPage = {
        key: '/department_admin',
        label: '部门管理'
    }

    const userPage = {
        key: '/users_admin',
        label: '用户管理'
    }

    const accessPage = {
        key: '/access_admin',
        label: '权限管理'
    }

    const [menuList, setMenuList] = useState(menuItems)

    useEffect(() => {

        // console.log('check login')
        console.log(window.location.pathname)
        const path = window.location.pathname
        const key = path.split('/')[1]
        setSelectedKey('/' + key)
        if (['project', 'employee', 'department'].includes(key)) {
            setFullScreen(true)
            // console.log('hide Menu')
            setShowMenu(false)
        }

        setTimeout(async () => {
            try {
                // check login status, if already logged in, redirect to main page,
                // otherwise, redirect to login page
                const res = await LoginController.checkLogin()
                // console.log(res.data)
                window.sessionStorage.setItem('prevURL', path)
                if (res.data.code === 1) {
                    window.localStorage.setItem('employeeCode', res.data.employee_code)
                    const {access} = res.data.data
                    setUser({ 
                        // employee_code, name, username, access_code, access
                        ...res.data.data
                    })
                    const newMenuList = menuList
                    console.log(access)
                    if (access.department_page_edit) newMenuList.push(departmentPage)
                    if (access.user_page_edit) newMenuList.push(userPage)
                    if (access.access_page_edit) newMenuList.push(accessPage)
                    setMenuList(newMenuList)
                    setSpin(false)
                    setHideAll(false)
                } else if (res.data.code === -1) {
                    LoginController.toLoginPage(navigate)
                    return
                } else {
                    message.error(res.message || res.msg)
                }
            } catch (e) {
                console.log(e, e.message)
                message.error(e.message)
            }
        })
    }, [])

    const menuClick = (e) => {
        // console.log(e)
        window.sessionStorage.setItem('prevURL', e.key)
        navigate(e.key)
        setSelectedKey(e.key)
    }

    const logoutClick = () => {
        const confirm = window.confirm('即将退出登录')
        console.log(confirm)
        if (!confirm){
            return
        }
        setHideAll(true)

        setTimeout(async () => {
            try{
                await withNotification(LoginController.logout(), '', false)
                message.success('退出登录成功！')
            }catch(e) {
                console.log(e)
                message.error(e.message)
            }finally{
                window.location.reload()
            }
        })
    }

    const menu = (
        <Menu
            items={[
                {
                    key: '1',
                    label: (
                        <div onClick={logoutClick}>
                            退出登录
                        </div>
                    ),
                },
            ]}
        />
    );

    const OverAllLoading = (
        <div className="App">
            <header className="my-app-header" style={{}}>
                {/* <img src={logo} className="App-logo" alt="logo" /> */}
                <Space direction="vertical">
                    <h1>慧德产值管理系统</h1>

                    <div className='Spin-wrapper'>
                        <Spin size='large' style={{ marginTop: '0rem' }} />
                    </div>
                </Space>
            </header>
        </div>
    )

    if (hideAll) {
        return (OverAllLoading)
    }

    return (
        <Layout style={{ height: '100%', overflowY: 'auto' }}>
            <div style={(fullScreen && !showMenu) ?
                { height: '1.2rem' }
                :
                { display: 'flex', width: '100%', height: '4rem' }}>
                {showMenu &&
                    <div id='collapsed-btn'
                        onClick={() => setCollapsed(!collapsed)}>
                        {
                            collapsed ? <MoreOutlined /> : <MenuOutlined />
                        }
                    </div>}
                <div style={{
                    width: '100%', display: 'flex', justifyContent: 'space-between',
                    alignItems: 'center', background: '#001529', color: 'white'
                }}>

                    <div style={{ marginLeft: '1rem', cursor: 'pointer', fontSize: (fullScreen && !showMenu) ? 'small' : 'large' }}
                        onClick={() => window.location = '/'}>
                        HDCPM 慧德绩效管理系统
                    </div>

                    <div style={{ display: 'flex' }}>
                        <div style={{ margin: 'auto 1rem', fontSize: (fullScreen && !showMenu) ? 'small' : 'large' }}>
                            <Dropdown overlay={menu} placement="bottomRight" >
                                <Space>
                                    <UserOutlined style={{marginRight:'-0.5rem'}} /> {user.name}
                                    <DownOutlined />
                                </Space>
                            </Dropdown>
                        </div>

                        {!showMenu &&
                            <div style={{ marginRight: '1rem', fontSize: fullScreen ? '.8rem' : '1.5rem', cursor: 'pointer' }}
                                onClick={() => setFullScreen(!fullScreen)}>
                                {fullScreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
                            </div>
                        }
                    </div>


                    {/* <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['0']} items={[]} /> */}
                </div>

            </div>

            {spin ?
                <div className='Spin-wrapper'>
                    <Spin size='large' style={{ marginTop: '-5rem' }} />
                </div>
                :
                <Layout style={{ height: '100%' }}>
                    {showMenu &&
                        <Sider width={110}
                            className="site-layout-background"
                            style={{ height: '100%' }}
                            // breakpoint="md"
                            collapsedWidth="0"
                            zeroWidthTriggerStyle={{ visibility: 'hidden' }}
                            collapsed={collapsed}
                            collapsible
                        >
                            <Menu
                                selectedKeys={[selectedKey]}
                                mode="inline"
                                style={{
                                    height: '100%',
                                    borderRight: 0,
                                }}
                                items={menuList}
                                onClick={menuClick}
                            />
                        </Sider>
                    }
                    <Layout style={showMenu ? { padding: '0 24px 24px', } : { padding: '0 24px' }}>

                        <Content
                            className="site-layout-background"
                            style={showMenu ? {
                                padding: 24,
                                margin: 0,
                                minHeight: 280,
                            } : {}}
                        >
                            {/* 内容放置 */}
                            <UserContext.Provider value={user}>
                                <FullScreenContext.Provider value={fullScreen}>
                                    <Outlet />
                                </FullScreenContext.Provider>
                            </UserContext.Provider>
                            
                        </Content>
                    </Layout>
                </Layout>
            }
        </Layout>
    )
};

export default AppLayout;