import axios from 'axios'
import { read, utils, writeFile } from 'xlsx';

const getDepartments = () => {
    return axios.get('/api/department/')
}

const getDeparmentDetailsByCode = (code) => {
    return axios.get('/api/department/' + code)
}

const addDepartment = (code, name, fee, comment) => {
    return axios.post('/api/department/new', {code, name, fee, comment})
}

const updateDepartment = (uuid, code, name, fee, comment) =>{ 
    return axios.post('/api/department/update', {uuid, code, name, fee, comment})
}

const deleteDepartment = (code) => {
    return axios.delete('/api/department/' + code)
}

const downloadDepartments = () => {

    return new Promise((resolve, reject) => {

        setTimeout(async () => {

            try{

                const res = await getDepartments()
                const detailData = res.data.data

                const workbook = utils.book_new()
                
                const rows = [['序号', '部门名称', '部门编号', '备注']]
                detailData.forEach((d, idx) => {
                    rows.push([idx + 1, d.name, d.code, d.comment])
                })

                const workSheet = utils.aoa_to_sheet(rows)

                utils.book_append_sheet(workbook, workSheet)

                writeFile(workbook,"慧德部门基础信息.xlsx");

                resolve(1)
            }catch(e){
                reject(e)
            }
        })
    })

}

const uploadSaveDepartments = (departments) =>{ 
    return axios.post('/api/department/upload-save', {departments})
}


const DepartmentController = {
    getDepartments, getDeparmentDetailsByCode, addDepartment, updateDepartment, deleteDepartment,
    downloadDepartments, uploadSaveDepartments
}

export default DepartmentController;